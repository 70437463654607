<script>
  import { createEventDispatcher, onMount } from "svelte";
  import ToggleSwitch from "../input/ToggleSwitch.svelte";
  import { translate } from "../../i18n/i18next.js";
  import DialogBase from "../dialog/DialogBase.svelte";
  import DialogTitle from "../dialog/DialogTitle.svelte";
  import DialogContent from "../dialog/DialogContent.svelte";
  import DialogBottomControls from "../dialog/DialogBottomControls.svelte";
  import DialogBottomControlButton from "../dialog/DialogBottomControlButton.svelte";
  import DialogSplitter from "../dialog/DialogSplitter.svelte";

  export let dialog
  export let GanttStore
  export let ganttChartType;

  const dispatcher = createEventDispatcher();

  let extraColumnProperties = [];

  if (
    ganttChartType == GanttStore.GanttChartTypes.ResGantt ||
    ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt ||
    ganttChartType === GanttStore.GanttChartTypes.CustomizedResGantt
  ) {
    extraColumnProperties = [
      { name: "資源グループ", nameTranslationKey: "$t(asprova.terms.resourceGroup)", code: "ResourceGroup" },
      { name: "資源名", nameTranslationKey: "$t(asprova.terms.resourceName)", code: "ResourceName" }
    ];
  } else {
    extraColumnProperties = [
      { name: "製番", nameTranslationKey: "製番", code: "Order_Seiban" },
      { name: "品目コード", nameTranslationKey: "$t(asprova.terms.itemCode)", code: "Order_ItemCode" },
      { name: "品目名", nameTranslationKey: "$t(asprova.terms.itemName)", code: "Order_ItemName" },
      { name: "納期", nameTranslationKey: "$t(asprova.terms.due)", code: "Order_LET" },
      { name: "納期遅れ", nameTranslationKey: "$t(asprova.terms.lateness)", code: "Order_Lateness" },
    ];
  }

  let uses = new Array(extraColumnProperties).fill(false);

  let extraColumns;

  function closeWindow() {
    extraColumns = new Array();
    extraColumnProperties.forEach((prop, idx) => {
      if (uses[idx]) {
        extraColumns.push(prop);
      }
    });
    console.log(extraColumns);
    GanttStore.ExtraColumns.set(extraColumns);
    dispatcher("close");
  }

  onMount(async () => {
    GanttStore.ExtraColumns.subscribe((ec) => {
      extraColumns = ec;
      extraColumns.forEach(
        (val) =>
          (uses[
            extraColumnProperties.findIndex((prop) => prop.name === val.name)
          ] = true)
      );
    });
  });
</script>

<DialogBase bind:dialog={dialog} width="">
  <DialogTitle title={translate("frontend:ganttChart.columnSettings.label")} />
  <DialogSplitter />
  <DialogContent>
    {#each extraColumnProperties as prop, idx}
      <div class="settings-item">
        <span class="label-text">{translate(prop.nameTranslationKey)}</span>
        <ToggleSwitch bind:on={uses[idx]} />
      </div>
    {/each}
  </DialogContent>
  <DialogSplitter />
  <DialogBottomControls>
    <DialogBottomControlButton onClick={closeWindow}>
      OK
    </DialogBottomControlButton>
  </DialogBottomControls>
</DialogBase>

<style>
  .settings-item {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
</style>