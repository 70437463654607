<script>
  import { onMount } from "svelte";
  import LoadGraphMPSChartContentRowLoadBar from "./LoadGraphMPSChartContentRowLoadBar.svelte";
  import { derived, writable } from "svelte/store";

  export let store
  export let props

  $: ({
    Row,
    RowHeightInPx,
    OriginYInPx,
  } = props)
  let TextHeightInPx = 40
  $: TextHeightInPx = $DisplayLoadNumbers ? 40 : 10

  const BottomPaddingInPx = 4
  $: loadBarMaxHeightInPx = RowHeightInPx - TextHeightInPx - BottomPaddingInPx

  const {
    CurrentDisplayedDates,
    DisplayLoadNumbers,
    GetItemColor,
    RowDataFetcher,
  } = store

  const RowData = writable(null)
  async function fetchRowData() {
    if ($RowData == null) {
      RowData.set(await $RowDataFetcher.apply(null, [Row]))
    }
  }

  const CalculateLoadBarHeightInPx = (capacity, load, loadBarMaxHeightInPx, maxLoad) => {
    if (capacity > maxLoad) return Math.floor(loadBarMaxHeightInPx * (load / capacity))
    if (maxLoad <= 0) return 0
    return Math.floor(loadBarMaxHeightInPx * (load / maxLoad))
  }
  const CalculateCapacityLineHeightInPx = (capacity, loadBarMaxHeightInPx, maxLoad) => {
    if (capacity === -1) return 0
    if (capacity > maxLoad) return loadBarMaxHeightInPx
    return loadBarMaxHeightInPx * (capacity / maxLoad)
  }

  const CurrentDisplayedLoads = derived([CurrentDisplayedDates, RowData], ([CurrentDisplayedDates, RowData]) => {
    if (!RowData) return []
    return CurrentDisplayedDates.map(date => {
      const load = RowData.byDates?.[date.dateString]?.Load ?? [-1, -1]
      const items = RowData.byDates?.[date.dateString]?.ByItem ?? {}
      return {
        ...date,
        load,
        items,
      }
    })
  })
  const MaxLoad = derived([CurrentDisplayedLoads], ([CurrentDisplayedLoads]) => {
    return Math.max(...CurrentDisplayedLoads.map(load => load.load[1]))
  })
  $: Loads = derived([CurrentDisplayedLoads], ([CurrentDisplayedLoads]) => {
    const heights = CurrentDisplayedLoads.map(load => CalculateLoadBarHeightInPx(load.load[0], load.load[1], loadBarMaxHeightInPx, $MaxLoad) + TextHeightInPx)
    return CurrentDisplayedLoads.map((load, index) => {
      const leftNeighborIndex = index === 0
        ? null
        : CurrentDisplayedLoads[index-1].dateNumber === load.dateNumber - 1
          ? index - 1
          : null
      const rightNeighborIndex = index === CurrentDisplayedLoads.length - 1
        ? null
        : CurrentDisplayedLoads[index+1].dateNumber === load.dateNumber + 1
          ? index + 1
          : null
      return {
        ...load,
        height: heights[index],
        leftNeighborHeight: leftNeighborIndex != null ? heights[leftNeighborIndex] : 0,
        rightNeighborHeight: rightNeighborIndex != null ? heights[rightNeighborIndex] : 0,
      }
    })
  })

  onMount(async () => {
    await fetchRowData()
  })
</script>

{#if Row != null}
  {#each $Loads as load, index}
    {#if load.load[0] !== -1}
      {#if $DisplayLoadNumbers}
        <text
          x="{(load.startXInPercentage + load.endXInPercentage) / 2}%"
          y="{OriginYInPx+15}px"
          font-size="12px"
          text-anchor="middle"
          class:overload={load.load[1] > load.load[0]}
        >
          {Math.ceil(load.load[1] / load.load[0] * 100)}%
        </text>
        <text
          x="{(load.startXInPercentage + load.endXInPercentage) / 2}%"
          y="{OriginYInPx+30}px"
          font-size="12px"
          text-anchor="middle"
          class:overload={load.load[1] > load.load[0]}
        >
          {Math.round(load.load[1] / 360) / 10}
        </text>
      {/if}
        <LoadGraphMPSChartContentRowLoadBar
          X={load.startXInPercentage}
          Y={OriginYInPx + loadBarMaxHeightInPx - CalculateLoadBarHeightInPx(load.load[0], load.load[1], loadBarMaxHeightInPx, $MaxLoad) + TextHeightInPx}
          BottomY={OriginYInPx + RowHeightInPx - BottomPaddingInPx}
          Height={load.height}
          LeftNeighborHeight={load.leftNeighborHeight}
          RightNeighborHeight={load.rightNeighborHeight}
          GetColorByItem={$GetItemColor}
          GetHeightByLoad={given => CalculateLoadBarHeightInPx(load.load[0], given, loadBarMaxHeightInPx, $MaxLoad)}
          Width={Math.max(load.endXInPercentage - load.startXInPercentage, 0)}
          Items={load.items}
          ShouldDisplayByItems={true}
        />
    {/if}
    <!-- Drawing the line of capacity -->
    <line
      x1="{load.startXInPercentage}%"
      x2="{load.endXInPercentage}%"
      y1="{OriginYInPx + TextHeightInPx + loadBarMaxHeightInPx - CalculateCapacityLineHeightInPx(load.load[0], loadBarMaxHeightInPx, $MaxLoad)}px"
      y2="{OriginYInPx + TextHeightInPx + loadBarMaxHeightInPx - CalculateCapacityLineHeightInPx(load.load[0], loadBarMaxHeightInPx, $MaxLoad)}px"
      stroke="#FF7070"
      stroke-width="2"
    />
    {#if index > 0}
      <line
        x1="{load.startXInPercentage}%"
        x2="{load.startXInPercentage}%"
        y1="{OriginYInPx + TextHeightInPx + loadBarMaxHeightInPx - CalculateCapacityLineHeightInPx($CurrentDisplayedLoads[index-1].load[0], loadBarMaxHeightInPx, $MaxLoad)}px"
        y2="{OriginYInPx + TextHeightInPx + loadBarMaxHeightInPx - CalculateCapacityLineHeightInPx($CurrentDisplayedLoads[index].load[0], loadBarMaxHeightInPx, $MaxLoad)}px"
        stroke="#FF7070"
        stroke-width="2"
      />
    {/if}
  {/each}
  <!-- Line at the bottom of the load bar (load = 0%) -->
  <line
    x1="0"
    x2="100%"
    y1="{OriginYInPx + RowHeightInPx - BottomPaddingInPx}px"
    y2="{OriginYInPx + RowHeightInPx - BottomPaddingInPx}px"
    stroke="#cccccc"
    stroke-dasharray="2 2"
    stroke-width="2"
  />
  <!-- Line at the bottom of the row -->
  <line
    x1="0"
    x2="100%"
    y1="{OriginYInPx + RowHeightInPx}px"
    y2="{OriginYInPx + RowHeightInPx}px"
    stroke="#888888"
    stroke-width="2"
  />
{/if}

<style>
  .overload {
    fill: red;
  }
</style>