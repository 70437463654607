<script>
  import { getContext, onMount } from "svelte";
  import dayjs from "dayjs";
  import {
    addReliefColumn,
    FetchError,
    fetchResource,
    fetchStyles,
    getOperationCode,
    getStartDate,
    getStatus
  } from "../../utils/getData";
  import { mergeResourceDetail_and_TransactionData } from "../../utils/mergeData";
  import Checkbox from "../../components/input/checkbox.svelte";
  import { postStartEndData } from "../../utils/putData";
  import { I18N_GetString } from "../../utils/i18n";
  import {
    hasOperationCompleted,
    hasOperationStarted,
    parseDispatchingViewResourceDetail,
  } from "../../utils/project/views/dispatchingView.js";
  import { determinePageToDisplay } from "../../utils/project/views/profile1DispatchingView.js";
  import {
    getDispatchingViewProfile1Configuration,
    saveDispatchingViewProfile1RelievedOperationsConfiguration
  } from "../../utils/configurationStorage/views/DispatchingViewProfile1ConfigurationStorage.js";
  import Profile1DispatchingViewControls from "./Profile1DispatchingViewControls.svelte";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import Loading from "../../components/pages/Loading.svelte";
  import { translate } from "../../i18n/i18next.js";

  // props
  export let styles = [];
  export let resourceDetail = null;
  export let resourceGroup = {};
  export let transactions = {};
  export let reloadTransactions = () => {};
  export let isInputAllowed = false;
  export let projectId
  export let itemCode
  export let user

  let displayCompletedTasks = false
  let currentPageIndex = 0

  const { setHeaderProps } = usePageContext();

  $: iStyle = 0;

  $: checkedCache = new Set(); // Store the relief checks when turning to different pages

  $: isUseInstStartEndTimeExisted = true;
  // if UseInst_StartTime exist, show measure button in the UseInst_StartTime/EndTime column and hide Work_ResultStartTime/EndTime.
  // otherwise, show measure button in the Work_ResultStartTime/EndTime and does not hide them.
  let operations; // display data
  let allData; // all operation data including columns which are never displayed
  let title;
  let title_key;
  let UploadTimeStamp = null;
  let DownloadTimeStamp = null;

  $: setHeaderProps({ uploadTime: UploadTimeStamp});

  let httpStatus = 200;
  let totalPageNumber = -1;
  // let totalLength;
  let tableBody;
  $: currentGroup = "";
  $: currentResource = "";

  /**
   * Key is the operation's code
   * @type {{[key: string]: boolean}}
   */
  let reliefCheckedOperations = {}

  let dateList = [];

  // The customer wants format in "h:mm"
  function formatDateStr(dateStr) {
    const date = new Date(dateStr); 

    if (!dateStr || isNaN(date.getTime())) {
      return "";
    }
    
    let hours = date.getHours();  
    let minutes = date.getMinutes(); 
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes}`; 
  }

  async function refresh(resourceDetail, transactions) {
    if (!resourceDetail) return;

    let tmp_operations, tmp_allData, tmp_title, tmp_title_key;
    [
      tmp_operations,
      tmp_allData,
      tmp_title,
      tmp_title_key,
      UploadTimeStamp,
      DownloadTimeStamp,
    ] = await parseDispatchingViewResourceDetail(resourceDetail, iStyle);

    [tmp_operations, tmp_title, tmp_title_key] = addReliefColumn(
      tmp_operations,
      tmp_title,
      tmp_title_key
    );

    // fetch and merge transaction data

    [tmp_operations, tmp_allData] = mergeResourceDetail_and_TransactionData(
      tmp_operations,
      tmp_allData,
      tmp_title_key,
      transactions
    );

    operations = tmp_operations;
    allData = tmp_allData;
    title = tmp_title;
    title_key = tmp_title_key;

    // console.log("operations: ", operations);
    // console.log("allData: ", allData);
    // console.log("title: ", title);
    // console.log("title_key: ", title_key);

    isUseInstStartEndTimeExisted =
      title_key.includes("UseInst_StartTime") &&
      title_key.includes("UseInst_EndTime");

    dateList = [];
    for (const allDataRow of allData) {
      const formattedDate = getStartDate(allDataRow);
      if (!dateList.length || dateList[dateList.length - 1] !== formattedDate) {
        dateList.push(formattedDate);
      }
    }
    totalPageNumber = dateList.length;

    if (currentPageIndex === 0) {
      currentPageIndex = determinePageToDisplay(allData, dateList, totalPageNumber - 1) - 1
    }
  }

  const { handleNonBlockingFetchErrors } = getContext("fetchErrorHandler");

  $: refresh(resourceDetail, transactions);

  // load settings from localStorage and apply them if valid
  async function loadSavedSettings(userId, projectId, resourceGroup) {
    if (!userId || !projectId) return

    const savedSettings = getDispatchingViewProfile1Configuration(userId, projectId)
    // apply displayCompletedTasks
    if (savedSettings.displayCompletedTasks) {
      displayCompletedTasks = savedSettings.displayCompletedTasks
    }
    // apply styleName: before applying it, check if the style name is valid
    if (savedSettings.styleName) {
      const styles = await fetchStyles(projectId)
      const index = styles.findIndex(s => s === savedSettings.styleName)
      if (index !== -1) {
        iStyle = index
      }
    }
    // apply relief resource group and resource selection
    // check if it is valid before applying
    if (savedSettings.reliefResourceGroup) {
      if (resourceGroup[savedSettings.reliefResourceGroup]) {
        currentGroup = savedSettings.reliefResourceGroup

        if (savedSettings.reliefResource) {
          if (resourceGroup[currentGroup].includes(savedSettings.reliefResource)) {
            currentResource = savedSettings.reliefResource
          }
        }
      }
    }
    // apply relieving operations
    if (savedSettings.relievedOperations) {
      let relievedOperations = []
      try {
        relievedOperations = JSON.parse(savedSettings.relievedOperations)
      } catch (e) {}
      reliefCheckedOperations = Object.fromEntries(relievedOperations.map(op => [op, true]))
    }
  }

  onMount(async () => {
    if (!localStorage.getItem("reliefCheckedCache"))
      localStorage.setItem("reliefCheckedCache", "[]");
    checkedCache = new Set(
      JSON.parse(localStorage.getItem("reliefCheckedCache"))
    );

    const resource = await fetchResource(projectId);
    let resCode = resource.Rows.find((r) => (r[r.length - 1].toString() === itemCode))[0];
    setHeaderProps({ title: resCode });
    await loadSavedSettings(user.id, projectId, resourceGroup)
  });

  // reload settings if resource group is changed
  $: loadSavedSettings(user.id, projectId, resourceGroup)

  function changeReliefCheckedStatus(operationCode, isRelieving) {
    saveDispatchingViewProfile1RelievedOperationsConfiguration(operationCode, isRelieving, user?.id, projectId)

    reliefCheckedOperations[operationCode] = isRelieving
  }

  function styleSelectOnChange() {
    console.log(iStyle)
    refresh(resourceDetail, transactions);
  }

  function is_Operation_to_be_displayed(
    allData,
    operation_id,
    currentPageIndex,
    displayCompletedTasks
  ) {
    if (
      displayCompletedTasks === false &&
      hasOperationCompleted(allData, operation_id)
    ) {
      return false;
    }
    let operationStartDate = getStartDate(allData[operation_id]);

    return operationStartDate === dateList[currentPageIndex];
  }

  async function onStartEndButtonClick(event, rowIndex, transactionType) {
    const currentDatetime = dayjs().format("YYYY/MM/DD HH:mm:ss"); // current datetime

    const checkBoxElement = document.getElementById(`checkbox-${rowIndex}`);
    if (checkBoxElement == null) {
      console.log(`row index: ${rowIndex}, Null Checkbox`);
      return;
    }
    const isRelief = checkBoxElement.checked; // relief or not

    // change background color of column and make checkbox not clickable
    if (
      isRelief &&
      transactionType === "start" &&
      currentResource !== "" &&
      currentResource !== itemCode
    ) {
      const tableRow = document.getElementById(`row-${rowIndex}`);
      tableRow.style.backgroundColor = "#00000";
    }
    if (
      isRelief &&
      transactionType === "start" &&
      (currentResource === "" || currentResource === itemCode)
    ) {
      checkBoxElement.checked = false;
    }
    checkBoxElement.disabled = "disabled";

    // disable buttons while the update is in progress
    // if the update is to end an operation and the view is set not to display completed operations, disable all the buttons
    //   this is to avoid update mistakes (a feature requested by a PoC user)
    // otherwise disable only the pressed button
    const disabledButtons = [];
    if (transactionType === "end" && !displayCompletedTasks) {
      // 全てのボタンを押せなくする
      const startEndButtons =
        document.getElementsByClassName("button-start-end");
      for (const button of startEndButtons) {
        button.disabled = "disabled";
        disabledButtons.push(button);
      }
    } else {
      event.target.disabled = true;
      disabledButtons.push(event.target);
    }

    // prepare post data
    console.log("relief: ", isRelief);
    console.log("currentResourceValue: ", currentResource);
    console.log("item_code: ", itemCode);
    console.log(
      "currentResourceValue !== item_code: ",
      currentResource !== itemCode
    );

    const operationCode = getOperationCode(allData[rowIndex]);
    const Work_ResultMainRes =
      isRelief && currentResource !== itemCode ? currentResource : "";
    let bodyData;
    if (transactionType === "start") {
      bodyData = JSON.stringify({
        [operationCode]: {
          Work_Status: "T",
          Work_ResultStartTime: currentDatetime,
          Work_ResultMainRes: Work_ResultMainRes,
          Work_ResultQty: null,
          Work_ResultProgress: null,
          Work_ResultObtainTime: null
        },
      });
    } else {
      bodyData = JSON.stringify({
        [operationCode]: {
          Work_Status: "B",
          Work_ResultStartTime: allData[rowIndex]["Work_ResultStartTime"],
          Work_ResultEndTime: currentDatetime,
          Work_ResultMainRes: Work_ResultMainRes,
          Work_ResultQty: null,
          Work_ResultProgress: null,
          Work_ResultObtainTime: null
        },
      });
    }

    console.log(`body data: ${bodyData}`);
    console.log(`currentResource: ${currentResource}`);

    // post button api, and then fetch actual start end time
    try {
      await postStartEndData(projectId, bodyData);

      reloadTransactions();
      disabledButtons.forEach((button) => (button.disabled = false));
    } catch (e) {
      console.log(e);
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error);
      }
      disabledButtons.forEach((button) => (button.disabled = false));
    }
  }

  $: if (allData && currentResource === "") {
    allData.forEach((operation) => {
      if (operation.Work_ResultStartTime === "" && operation.Work_ResultMainRes === "") {
        changeReliefCheckedStatus(operation.MyScheReservedFixedProperty[0], false)
      }
    })
  }

  $: console.log(allData)
</script>

<div class="content-root">
  <Profile1DispatchingViewControls
    bind:iStyle
    {styles}
    bind:displayCompletedTasks={displayCompletedTasks}
    {resourceGroup}
    bind:currentPageIndex={currentPageIndex}
    bind:selectedGroup={currentGroup}
    bind:selectedResource={currentResource}
    {styleSelectOnChange}
    {dateList}
    userId={user?.id}
    projectId={projectId}
  />
  <!-- Main Table -->
  {#if operations && totalPageNumber >= 0}
    {#if operations.length > 0}
      <div class="table-container">
        <div class="headers-table-container">
          <table>
            <tr id="head">
              {#each title as opt, i}
                {#if title_key[i] === "MyScheReservedFixedProperty" || (isUseInstStartEndTimeExisted && (title_key[i] === "Work_ResultStartTime" || title_key[i] === "Work_ResultEndTime"))}
                  <!--blank: hide fixed property, and work_resultstartime/endtime columns only when useinststarttime/endtime exist-->
                {:else}
                  <th>{opt}</th>
                {/if}
              {/each}
            </tr>
          </table>
        </div>
        <div class="operations-table-container">
          <table>
            <tbody bind:this={tableBody}>
            {#each operations as _, i}
              {#if is_Operation_to_be_displayed(allData, i, currentPageIndex, displayCompletedTasks)}
                <tr
                  id={`row-${i}`}
                  style={`background-color: ${reliefCheckedOperations[allData[i].MyScheReservedFixedProperty[0]] ? "#D6EEEE" : "#FFFFFF"}`}
                >
                  {#each operations[i] as col, j}
                    <!-- {#if j == 0}
                      <td id="cell-head" class="{getFormattedDate(col)}">
                        {col.getMonth() + 1}/{col.getDate()}
                      </td> -->
                    {#if title_key[j] === "MyScheReservedFixedProperty" || (isUseInstStartEndTimeExisted && (title_key[j] === "Work_ResultStartTime" || title_key[j] === "Work_ResultEndTime"))}
                      <!--blank: hide fixed property, and work_resultstartime/endtime columns only when useinststarttime/endtime exist-->
                    {:else if title_key[j] === "relief"}
                      <td id="check-box-col">
                        <Checkbox
                          {i}
                          bind:checked={reliefCheckedOperations[allData[i].MyScheReservedFixedProperty[0]]}
                          onClick={(e) => changeReliefCheckedStatus(allData[i].MyScheReservedFixedProperty[0], e.target.checked)}
                          disabled={!currentResource || allData[i].Work_ResultStartTime !== "" || allData[i].Work_ResultMainRes !== ""}
                        />
                      </td>
                    {:else if (isUseInstStartEndTimeExisted && title_key[j] === "UseInst_StartTime") || (!isUseInstStartEndTimeExisted && title_key[j] === "Work_ResultStartTime")}
                      <!--if useinststart/endtime exist, show button in that column, or in the resultstart/endtime columns-->
                      <td>
                        <div style="padding: 6px;">
                          {formatDateStr(col)}
                          <div
                            style="height: 1px; background-color: #B5BDC3; width: 90%; margin: 0 auto;"
                          />
                          {#if hasOperationStarted(allData, i)}
                            <div style="color:#249E8E;">
                              {formatDateStr(allData[i]["Work_ResultStartTime"])}
                            </div>
                          {:else if col === "" && getStatus(allData[i]) === "B"}
                            <div style="color:#249E8E;">
                              {I18N_GetString("STATUS_COMPLETE")}
                            </div>
                          {:else}
                            <div style="display: flex; align-items: center;">
                              <GeneralButton
                                buttonDisabled={!isInputAllowed || allData[i]["Work_ResultStartTime"] !== ""}
                                buttonFunction={(e) => onStartEndButtonClick(e, i, "start")}
                                buttonValue={`startEndButton$${i}`}
                                buttonText={translate("generic.startShort")}
                                buttonExtraStyle="width: 96%"
                              />
                            </div>
                          {/if}
                        </div>
                      </td>
                    {:else if (isUseInstStartEndTimeExisted && title_key[j] === "UseInst_EndTime") || (!isUseInstStartEndTimeExisted && title_key[j] === "Work_ResultEndTime")}
                      <td>
                        <div style="padding: 6px;">
                          {formatDateStr(col)}
                          <div
                            style="height: 1px; background-color: #B5BDC3; width: 90%; margin: 0 auto;"
                          />
                          {#if hasOperationCompleted(allData, i)}
                            <div style="color:#249E8E;">
                              {formatDateStr(allData[i]["Work_ResultEndTime"])}
                            </div>
                          {:else if col === "" && getStatus(allData[i]) === "B"}
                            <div style="color:#249E8E;">
                              {I18N_GetString("STATUS_COMPLETE")}
                            </div>
                          {:else}
                            <div style="display: flex; align-items: center;">
                              <GeneralButton
                                buttonDisabled={!isInputAllowed ||
                                  allData[i]["Work_ResultStartTime"] === ""}
                                buttonFunction={(e) =>
                                  onStartEndButtonClick(e, i, "end")}
                                buttonValue={`startEndButton$${i}`}
                                buttonText={translate("generic.finishShort")}
                                buttonExtraStyle="width: 96%"
                              />
                            </div>
                          {/if}
                        </div>
                      </td>
                    {:else}
                      <td>{col}</td>
                    {/if}
                  {/each}
                </tr>
              {/if}
            {/each}
            </tbody>
          </table>
        </div>
      </div>
    {:else}
      <Loading message={I18N_GetString("NoOperations")} mark="!"/>
    {/if}
  {:else if httpStatus !== 200}
    <h1>{httpStatus}</h1>
    <p>{I18N_GetString("ErrGeneric")}</p>
  {:else}
    <Loading/>
  {/if}
</div>

<style>
  .content-root {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .table-container {
    display: flex;
    flex-direction: column;
    height: 100%
  }
  table tr#head {
    background-color: #bfc6cc;
    color: black;
    top: 0;
  }
  table {
    width: 100%;
    text-align: center;
    border: 1px solid #b5bdc3;
    border-collapse: collapse;
    table-layout: fixed;
    word-break: break-all;
  }
  td {
    border: 1px solid #d3d3d3;
    border-collapse: collapse;
    padding-left: 5px;
    line-height: 30px;
  }
  .headers-table-container {
    flex: 0;
  }
  .operations-table-container {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
  }
</style>
