<script>
  import { onMount } from "svelte";
  import { writable } from "svelte/store";

  export let store
  export let props

  $: ({
    Row,
    RowHeightInPx,
    OriginYInPx,
  } = props)

  const {
    CurrentDisplayedDates,
    RowDataFetcher,
  } = store;

  const RowData = writable(null)
  async function fetchRowData(Row) {
    if (!Row) return
    RowData.set(await $RowDataFetcher.apply(null, [Row]))
  }
  $: fetchRowData(Row)

  $: maxValue = Math.max(...$CurrentDisplayedDates.map(date => {
    if (!Row) return 0
    return Row.getValue($RowData, date.dateString)
  }))

  function getStylingValue(getter, defaultValue, getterArgs) {
    if (!getter) return defaultValue
    if (typeof getter === "string") return getter
    return getter(...getterArgs)
  }

  function calculateY(value) {
    if (maxValue === 0 || value === 0) {
      return OriginYInPx + RowHeightInPx-4;
    } else {
      return (OriginYInPx + RowHeightInPx*0.2 + (0.8-(value / maxValue * 0.8)) * (RowHeightInPx-4));
    }
  }

  function calculateHeight(value) {
    if (maxValue === 0) {
      return 0;
    } else {
      return (value / maxValue) * 0.8 * (RowHeightInPx-4);
    }
  }

  onMount(async () => {
    await fetchRowData()
  })
</script>

{#each $CurrentDisplayedDates as date, index}
  {#if date.startXInPercentage < 100 && RowHeightInPx && $RowData && $RowData.itemCode}
    <!-- the actual numbers displayed at the top of the row -->
    <text
      x="{(date.startXInPercentage + date.endXInPercentage) / 2}%"
      y="{OriginYInPx + RowHeightInPx*0.1}px"
      font-size="12px"
      font-weight={getStylingValue(Row.cellTextWeight, "normal", [$RowData, date.dateString, Row.getValue($RowData, date.dateString)])}
      text-anchor="middle"
      dominant-baseline="middle"
      fill={getStylingValue(Row.cellTextColor, "black", [$RowData, date.dateString, Row.getValue($RowData, date.dateString)])}
    >
      {Row.getValue($RowData, date.dateString)}
    </text>
    <!-- a rectangle which height is proportional to the value -->
    <rect
      x="{date.startXInPercentage}%"
      y="{calculateY(Row.getValue($RowData, date.dateString))}px"
      height="{calculateHeight(Row.getValue($RowData, date.dateString))}px"
      width="{Math.max(date.endXInPercentage - date.startXInPercentage, 0)}%"
      fill={getStylingValue(Row.cellBackgroundColor, "black", [$RowData, date.dateString, Row.getValue($RowData, date.dateString)])}
    />
    <!-- the line drawn on the top side of the rectangle -->
    <line
      x1="{date.startXInPercentage}%"
      x2="{date.endXInPercentage}%"
      y1="{calculateY(Row.getValue($RowData, date.dateString), Row.subRowPosition.index)}px"
      y2="{calculateY(Row.getValue($RowData, date.dateString), Row.subRowPosition.index)}px"
      stroke={Row.lineColor}
      stroke-width="2px"
    />
    {#if index !== 0}
      <!-- the line drawn on the left side of the rectangle -->
      <line
        x1="{date.startXInPercentage}%"
        x2="{date.startXInPercentage}%"
        y1="{calculateY(Row.getValue($RowData, date.dateString))}px"
        y2="{calculateY(Row.getValue($RowData, $CurrentDisplayedDates[index - 1].dateString))}px"
        stroke={Row.lineColor}
        stroke-width="2px"
      />
    {/if}
    <!-- the line drawn on the right side of the rectangle -->
    <line
      x1="{date.startXInPercentage}%"
      x2="{date.endXInPercentage}%"
      y1="{OriginYInPx + RowHeightInPx - 4}px"
      y2="{OriginYInPx + RowHeightInPx - 4}px"
      stroke="gray"
      stroke-width="2px"
      stroke-dasharray="3, 3"
    />
    <!-- the line in the bottom part of the row, indicating the height of 0 -->
    <line
      x1="{date.endXInPercentage}%"
      x2="{date.endXInPercentage}%"
      y1="{OriginYInPx}px"
      y2="{OriginYInPx + RowHeightInPx}px"
      stroke={date.date.getDay() === 0 ? "#888888" : "none"}
      stroke-width="2"
    />
  {/if}
{/each}
<!-- Line at the bottom of the row -->
<line
  x1="0"
  x2="100%"
  y1="{OriginYInPx + RowHeightInPx}px"
  y2="{OriginYInPx + RowHeightInPx}px"
  stroke={Row.subRowPosition.isLastRow ? "#888888" : "#C8C6C6"}
  stroke-width="2"
/>

<style>
  .overload {
    fill: red;
  }
</style>