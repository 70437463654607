<script>
  import { getContext, onMount } from "svelte";
  import {
    FetchError,
    fetchResourceDetail,
    fetchStyleOperationTable,
    fetchTransactionData,
    getOperationCode,
    getStatus
  } from "../../utils/getData";
  import { mergeResourceDetail_and_TransactionData } from "../../utils/mergeData";
  import { postStartEndData } from "../../utils/putData";
  import { useIsInputAllowed } from "../../hooks/useIsInputAllowed.js";
  import { usePageContext } from "../../components/pages/pageContext.js";
  import OperationTableControl from "./OperationTableControl.svelte";
  import {
    getOperationTableConfiguration
  } from "../../utils/configurationStorage/views/OperationTableConfigurationStorage.js";
  import GeneralButton from "../../components/button/GeneralButton.svelte";
  import Loading from "../../components/pages/Loading.svelte";
  import { translate } from "../../i18n/i18next.js";
  import { useProject } from "../../hooks/useProject.js";
  import { getTimeStringSec } from "../../utils/time.js";
  import { useTimezone } from "../../hooks/useTimezone.js";

  /**
   * @type {string}
   */
  export let projectId
  /**
   * @type {string}
   */
  export let code
  export let user

  $: styleName = "";

  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({ title: translate("mySchedule.views.operationTable.name") });
  $: setHeaderProps({ uploadTime: UploadTimeStamp});

  $: usingCompactStyle = false;

  const { isInputAllowed } = useIsInputAllowed(projectId);
  const project = useProject(projectId)
  const {getOffset} = useTimezone()

  let operations; // display data
  let allData; // all operation data including columns which are never displayed
  let title;
  let title_key;
  let UploadTimeStamp = null;
  let DownloadTimeStamp = null;
  let itemPerPage = 10;
  let currentPageIndex = 0;
  $: totalPageNumber = -1;
  let totalLength;

  $: {
    totalPageNumber = Math.ceil(totalLength / itemPerPage);
  }

  const { handleFetchErrors, handleNonBlockingFetchErrors } = getContext("fetchErrorHandler");

  async function loadSavedSettings(userId, projectId) {
    // load settings from localStorage and apply them
    const savedSettings = await getOperationTableConfiguration(userId, projectId);
    console.log("saved settings:", savedSettings);
    // apply usingCompactMode
    if (
      savedSettings.usingCompactMode !== undefined &&
      savedSettings.usingCompactMode != null
    ) {
      usingCompactStyle = savedSettings.usingCompactMode;
    }
    // apply itemsPerPage
    if (savedSettings.itemPerPage) {
      itemPerPage = savedSettings.itemPerPage;
    }
  }

  onMount(async () => {
    const styles = await fetchStyleOperationTable(projectId);
    styleName = styles.find((s) => (s[1] == code))[0];
    try {
      [
        operations,
        allData,
        title,
        title_key,
        UploadTimeStamp,
        DownloadTimeStamp,
      ] = await fetchResourceDetail("OperationTable", projectId, code);
      // fetch and merge transaction data
      let transactionData;
      transactionData = await fetchTransactionData(projectId, DownloadTimeStamp); // Or should max(UploadTimeStamp, DownloadTimeStamp)?
      // console.log(transactionData);

      [operations, allData] = mergeResourceDetail_and_TransactionData(
        operations,
        allData,
        title_key,
        transactionData
      );

      currentPageIndex = 0;

      await loadSavedSettings(user.id, projectId)

      totalLength = allData.length;
      totalPageNumber = Math.ceil(totalLength / itemPerPage);
    } catch (e) {
      if (e instanceof FetchError) {
        handleFetchErrors(e.error);
      }
    }
  });

  function is_Operation_to_be_displayed(operation_id, currentPageIndex, itemsPerPage) {
    const firstItem = currentPageIndex * itemsPerPage;
    return operation_id >= firstItem && operation_id < firstItem + itemsPerPage;
  }

  async function onStartEndButtonClick(event, rowIndex, transactionType) {
    // if project data is not ready yet, reject submission
    if (!$project?.timezone) return

    // if the operation has not been started yet, it cannot be ended.
    if (
      transactionType === "end" &&
      allData[rowIndex]["Work_ResultStartTime"] === ""
    ) {
      return;
    }

    // disable the button while updating
    const disabled = [event.target];
    disabled.forEach((elem) => (elem.disabled = true));

    const currentDateTimeString = getTimeStringSec(new Date(), $project.timezone, $getOffset)
    const operationCode = getOperationCode(allData[rowIndex]);

    // prepare post data
    const bodyData = JSON.stringify({
      [operationCode]: {
        Work_Status: transactionType === "start" ? "T" : "B",
        Work_ResultStartTime:
          transactionType === "start" ? currentDateTimeString : allData[rowIndex]["Work_ResultStartTime"],
        Work_ResultEndTime:
          transactionType === "end" ? currentDateTimeString : undefined,
        Work_ResultMainRes: "",
        Work_ResultQty: null,
        Work_ResultProgress: null,
        Work_ResultObtainTime: null
      },
    });

    try {
      await postStartEndData(projectId, bodyData);

      // change data in allData
      if (transactionType === "start") {
        allData[rowIndex]["Work_ResultStartTime"] = currentDateTimeString;
      } else {
        allData[rowIndex]["Work_ResultEndTime"] = currentDateTimeString;
      }
    } catch (e) {
      console.error(e);
      if (e instanceof FetchError) {
        handleNonBlockingFetchErrors(e.error);
      }
    }

    // enable buttons disabled for the update
    disabled.forEach((elem) => (elem.disabled = false));
  }

  // $: console.log(operations?.length, operations)
</script>

<div class="content-root">
  <!-- Title -->
  <OperationTableControl
    bind:itemPerPage={itemPerPage}
    bind:totalPageNumber
    bind:style={styleName}
    bind:usingCompactStyle
    bind:currentIndex={currentPageIndex}
    userId={user.id}
    projectId={projectId}
  />

  <div class="table-wrapper">
    <!-- Main Table -->
    {#if operations}
      {#if operations.length > 0}
        <table
          class:compact-table={usingCompactStyle}
          class:wide-table={!usingCompactStyle}
        >
          <thead>
            <tr id="head">
              {#each title as opt}
                {#if opt === "Fixed Properties"}
                  <!--blank -->
                {:else}
                  <th class:short-title={opt.length < 6}>{opt}</th>
                {/if}
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each operations as _, i}
              {#if is_Operation_to_be_displayed(i, currentPageIndex, itemPerPage)}
                <tr id={`row-${i}`}>
                  {#each operations[i] as col, j}
                    {#if title_key[j] === "MyScheReservedFixedProperty"}
                      <!--blank -->
                    {:else if title_key[j] === "Work_ResultStartTime"}
                      <td>
                        {#if allData[i]["Work_ResultStartTime"] !== "" || getStatus(allData[i]) === "B"}
                          <div style="color:#249E8E;">
                            {allData[i]["Work_ResultStartTime"]}
                          </div>
                        {:else}
                          <GeneralButton
                            buttonDisabled={!$isInputAllowed}
                            buttonFunction={(e) =>
                              onStartEndButtonClick(e, i, "start")}
                            buttonText={translate("generic.startShort")}
                            buttonExtraStyle="padding: 0; margin: 0; width: 100%; min-height: 50px"
                          />
                        {/if}
                      </td>
                    {:else if title_key[j] === "Work_ResultEndTime"}
                      <td>
                        {#if allData[i]["Work_ResultEndTime"] !== "" || getStatus(allData[i]) === "B"}
                          <div style="color:#249E8E;">
                            {allData[i]["Work_ResultEndTime"]}
                          </div>
                        {:else}
                          <GeneralButton
                            buttonDisabled={!$isInputAllowed ||
                              allData[i]["Work_ResultStartTime"] === ""}
                            buttonFunction={(e) =>
                              onStartEndButtonClick(e, i, "end")}
                            buttonText={translate("generic.finishShort")}
                            buttonExtraStyle="padding: 0; margin: 0; width: 100%; min-height: 50px"
                          />
                        {/if}
                      </td>
                    {:else}
                      <td>{col}</td>
                    {/if}
                  {/each}
                </tr>
              {/if}
            {/each}
          </tbody>
        </table>
      {:else}
        <p>{translate("frontend:views.operationTable.noOperation")}</p>
      {/if}
    {:else}
      <Loading/>
    {/if}
  </div>
</div>

<style>
  .content-root {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .table-wrapper {
    display: flex;
    justify-content: center;
    background-color: white;
    height: 100%;
    overflow-y: auto;
  }

  /* Common styles for both table modes */
  table {
    border: 1px solid #ebebeb;
    border-collapse: collapse;
    text-align: center;
  }
  table tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  table tr:nth-child(even) {
    background-color: white;
  }
  table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  table tbody {
    overflow: auto;
  }

  /* Compact mode styles */
  table.compact-table {
    width: 100%;
    table-layout: fixed;
    word-break: break-all;
    overflow-x: auto;
  }

  table.compact-table th {
    background-color: #28385d;
    color: white;
    font-size: 1rem;
    padding: 8px;
    word-wrap: break-word;
    text-align: center;
  }

  table.compact-table td {
    border: 1px solid #d3d3d3;
    padding: 8px;
    white-space: normal;
    text-align: center;
  }

  /* Wide mode styles */
  table.wide-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 auto;
    width: auto;
    min-width: 50%; /* Ensures the table is centered even with few columns */
  }

  table.wide-table th {
    background-color: #28385d;
    color: white;
    font-size: 1rem;
    padding: 8px;
    word-wrap: break-word;
    text-align: center;
  }
  table.wide-table th.short-title {
    min-width: 100px;
  }

  table.wide-table td {
    border: 1px solid #d3d3d3;
    padding: 8px;
    white-space: normal;
    text-align: center;
  }

  /* Responsive adjustments */
  @media (max-width: 600px) {
    table.compact-table th,
    table.compact-table td,
    table.wide-table th,
    table.wide-table td {
      font-size: 0.875rem;
      padding: 6px;
    }
  }
</style>
