<script>
  import {beforeUpdate, getContext} from 'svelte';
  import GanttHelper from './GanttHelper.js';
  import DataFetcher from './DataFetcher.js';
  import ResourceGanttBar from './ResourceGanttBar.svelte';
  import OrderGanttBar from './OrderGanttBar.svelte';

  export let GanttStore
  export let Row;
  export let RowType;
  export let Alias;
  export let IsNull;
  export let Height;
  export let OriginY;
  export let DownloadUrl;
  export let Token;
  export let StartDate;
  export let EndDate;
  export let AdjustStamp;
  export let IsOperation;
  export let ganttChartType;
  export let rowLabels;
  export let Index;
  // Data => Blocks
  let RowData = null;
  let Transactions = null;

  let currentStyle = "";
  let styleObjects = new Array();
  let currentStyleIndex = 0;

  // fetch error handler
  const {handleNonBlockingFetchErrors} = getContext("fetchErrorHandler")

  const IsItemVisible = (itemStart, itemEnd) => {
    return !((itemStart < StartDate && itemEnd < StartDate) || (itemStart > EndDate && itemEnd > EndDate));
  }

  const GenerateTimePoints = (startDate, endDate) => {
    const timePoints = [];
    const currentDate = new Date(startDate);

    let choicesPerDay = 4;
    if (ganttChartType == GanttStore.GanttChartTypes.SimpleOrderGantt || ganttChartType == GanttStore.GanttChartTypes.ExpectedActualCmp) {
      choicesPerDay = 1;
    }
    while (currentDate <= endDate) {
      Array.from(
        { length: choicesPerDay },
        (_, i) => (24 / choicesPerDay) * i
      ).forEach((hour) => timePoints.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hour, 0, 0)));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return timePoints;
  }

  $: timePoints = GenerateTimePoints(StartDate, EndDate);

  const IsGanttBarVisible = (data) => {
    let itemStart = new Date(0); // 1st january 1970
    let itemEnd = new Date(0);

    // Begin
    if (data?._setupArr?.length >= 2) itemStart = data._setupArr[0];
    else if (data?._productionArr?.length >= 2) itemStart = data._productionArr[0];
    else if (data?._teardownArr?.length >= 2) itemStart = data._teardownArr[0];

    // End
    if (data._teardownArr?.length >= 2) itemEnd = data._teardownArr[data._teardownArr.length-1];
    else if (data?._productionArr?.length >= 2) itemEnd = data._productionArr[data._productionArr.length-1];
    else if (data?._setupArr?.length >= 2) itemEnd = data._setupArr[data._setupArr.length-1];

    return IsItemVisible(itemStart, itemEnd);
  }

  const CalculateStartX = (itemStart) => Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(itemStart).getTime(), EndDate.getTime(), StartDate.getTime()));
  const CalculateEndX = (itemEnd) => Math.max(0, 100 * GanttHelper._gantHelper_scale(AdjustStamp(itemEnd).getTime(), EndDate.getTime(), StartDate.getTime()));
  const CalculateLengthX = (itemStart, itemEnd) => CalculateEndX(itemEnd) - CalculateStartX(itemStart);


  const ApplyTransactionsToData = (item, idx) => {
    if (Transactions !== null && Transactions[item] !== undefined) {
      const itemTr = Transactions[item];
      RowData.Data[idx]._resStatus = itemTr.Work_Status;
      RowData.Data[idx]._resultStart = itemTr.Work_ResultStartTime;
      RowData.Data[idx]._resultEnd = itemTr.Work_ResultEndTime;
      RowData.Data[idx]._resultQty = itemTr.Work_ResultQty;
      RowData.Data[idx]._resultProgress = itemTr.Work_ResultProgress;
      RowData.Data[idx]._resultObtainTime = itemTr.Work_ResultObtainTime;
    }
  }

  const FetchRowInformation = () => {
    if (DownloadUrl === "") return ;

    // Cache missing, shall fetch data
    // Should not check if (RowData === undefined) here, this may make old and wrong data not updated anymore

    // 1st method: Fetch the row from persistent client side database (IndexedDB) if the timestamp matches
    // TODO

    // 2nd method: Fetch the row from the backend
    switch (RowType) {
      case "Resource":
        RowData = DataFetcher.FetchResourceInfo(Row, DownloadUrl, handleNonBlockingFetchErrors);
        break;
      case "Order":
        // Order gantt chart downloading method
        RowData = DataFetcher.FetchOrderInfo(Row, DownloadUrl, Token);
        break;
    }
    // TODO: If 2nd method: save to IndexedDB
    // Update data using transactions
    if (RowType == "Resource") {
      RowData.Data.forEach((item, idx) => ApplyTransactionsToData(item._operation, idx));
    }
  }

  // let objectID2IntervalQty = {};
  const ExtractNecessaryIntervalQtyData = (rowData) => {
    const parseKey = (str) => {
      const regex = /^(\d+):([\d/:\s]+)-([\d/:\s]+)$/;
      const match = str.match(regex);

      if (match) {
          const objectIDInString = match[1]; 
          const start = new Date(match[2].trim()); 
          const end = new Date(match[3].trim()); 

          return { objectIDInString, start, end };
      }
      console.error(`Invalid IntervalQty Key String: ${str}`);
    }

    const hasIntersection = (s1, e1, s2, e2) =>{
      return s1 < e2 && s2 < e1;
    }

    let objectID2IntervalQtyAll = {};
    if (!rowData.IntervalQty) {
      return;
    }
    Object.entries(rowData.IntervalQty).forEach(([key, qty]) => {
      const {objectIDInString, start, end} = parseKey(key);
      if (objectIDInString in objectID2IntervalQtyAll) {
        objectID2IntervalQtyAll[objectIDInString].push({start, end, qty});
      }
      else 
        objectID2IntervalQtyAll[objectIDInString] = [{start, end, qty}];
    });

    // objectID2IntervalQty = {};
    rowData.Data.forEach((_, index) => {
      const row = rowData.Data[index];
      const objectIDInString = String(row._objectID);
      if (objectIDInString in objectID2IntervalQtyAll) {
        const startEndArray = row._operationStartEnd;
        const arr = objectID2IntervalQtyAll[objectIDInString];
        row._productionArr = [];
        arr.forEach((ele) => {
          const {start, end, qty} = ele;
          if (hasIntersection(startEndArray[0], startEndArray[1], start, end)) {
            rowData.Data[index]._productionArr = rowData.Data[index]._productionArr.concat([start, end]);
            if (!rowData.Data[index].intervalQty)
              rowData.Data[index].intervalQty = [];
            rowData.Data[index].intervalQty.push(qty);
          }
        });
        // If do not sort, the isItemVisible will make some bars that should be shown hidden
        rowData.Data[index]._productionArr.sort((lhs, rhs) => lhs - rhs);
        rowData.Data[index].intervalQty.sort((lhs, rhs) => lhs[1] - rhs[1]);
      }
    });
    
  }

  let rcunsub = () => {};
  let trunsub = () => {};
  let stunsub = () => {};
  let snunsub = () => {};

  beforeUpdate(() => {
    // Reinit on update
    RowData = null;
    rcunsub();
    trunsub();
    stunsub();
    snunsub();

    trunsub = GanttStore.Transactions.subscribe((tr) => {
      Transactions = tr;

      if (RowType == "Resource" && RowData != null) {
        //RowData.Data.forEach((item, idx) => ApplyTransactionsToData(item._operation, idx));
        GanttStore.RowsCached.update((cached) => {
          cached[Row].Data.forEach((item, idx) => ApplyTransactionsToData(item._operation, idx));
          return cached;
        });
      }
      //FetchRowInformation();
    });
    rcunsub = GanttStore.RowsCached.subscribe((cached) => {
      if (cached[Row] === undefined) {
        FetchRowInformation();
        if (ganttChartType == GanttStore.GanttChartTypes.CustomizedResGantt) {
          ExtractNecessaryIntervalQtyData(RowData);
        }
        GanttStore.RowsCached.update((cached) => {
          return {
            ...cached,
            [Row]: { ...RowData }
          }
        });

        console.log(Alias + " - Downloaded");
        // console.log(RowData);
      }
      else {
        RowData = cached[Row];
      }
      if (RowType == "Resource") {
        rowLabels[Index] = RowData.Label;
      }
    });
    snunsub = GanttStore.StyleObjects.subscribe((sn) => {
      styleObjects = sn;
    });

    stunsub = GanttStore.CurrentStyle.subscribe((cs) => {
      currentStyle = cs;
      currentStyleIndex = styleObjects.indexOf(currentStyle);
    });
  });

</script>

{#if IsNull === false}

  {#if RowData !== null}

    {#if RowType === "Resource" && (ganttChartType == GanttStore.GanttChartTypes.ResGantt || ganttChartType == GanttStore.GanttChartTypes.CustomizedResGantt)}
      {#each RowData.Shift as shift}
        {#if IsItemVisible(shift[0], shift[1])}
          <rect
            x="{CalculateStartX(shift[0])}%"
            y="{OriginY}%"
            height="{Height}%"
            width="{CalculateLengthX(shift[0], shift[1])}%"
            fill="#00000011"
            pointer-events="none"
          />
        {/if}
      {/each}
    {/if}


    {#if RowType === "Resource"}
      {#each RowData.Data as row}
        {#if IsGanttBarVisible(row)}
          <ResourceGanttBar
            GanttStore={GanttStore}
            ID={row._objectID}
            Resource={Row}
            Height={Height}
            OriginY={OriginY}
            Data={row}
            StartDate={StartDate}
            EndDate={EndDate}
            Style={currentStyle.StyleName}
            AdjustStamp={AdjustStamp}
            bind:ganttChartType
          />
        {/if}
      {/each}
    {/if}

    {#if RowType === "Order"}
      {#if !IsOperation}
        {#each RowData.OrderData as row}
          <!-- Do not check to show the triangles and texts -->
          <!-- {#if IsGanttBarVisible(row)} -->
            <OrderGanttBar
              GanttStore={GanttStore}
              ID={row._objectID}
              Height={Height}
              OriginY={OriginY}
              Data={row}
              StartDate={StartDate}
              EndDate={EndDate}
              StyleIndex={currentStyleIndex}
              AdjustStamp={AdjustStamp}
              IsOperationBarCoveredOnOrderBar={false}
              IsOperation={false}
              bind:ganttChartType
            />
          <!-- {/if} -->
          {/each}
        {/if}

        {#if ganttChartType == GanttStore.GanttChartTypes.OrderGantt || ganttChartType == GanttStore.GanttChartTypes.PeggedOrderGantt}
          {#each RowData.OperationData as row}
            <!-- Do not check to show the triangles and texts -->
            <!-- {#if IsGanttBarVisible(row)} -->
              {#if !IsOperation || Alias == row._operation}
                <OrderGanttBar
                  GanttStore={GanttStore}
                  ID={row._objectID}
                  Height={Height}
                  OriginY={OriginY}
                  Data={row}
                  StartDate={StartDate}
                  EndDate={EndDate}
                  StyleIndex={currentStyleIndex}
                  AdjustStamp={AdjustStamp}
                  IsOperationBarCoveredOnOrderBar={ganttChartType == GanttStore.GanttChartTypes.PeggedOrderGantt ? IsOperation : true}
                  IsOperation={IsOperation}
                  bind:ganttChartType
                />
              {/if}
            <!-- {/if} -->
          {/each}
        {/if}
      {/if}
    {/if}

  {#if ganttChartType == GanttStore.GanttChartTypes.SimpleResGantt || ganttChartType == GanttStore.GanttChartTypes.SimpleOrderGantt || ganttChartType == GanttStore.GanttChartTypes.ExpectedActualCmp}
    {#each timePoints as tp}
      <line
        x1="{CalculateStartX(tp)}%"
        y1="{OriginY}%"
        x2="{CalculateStartX(tp)}%"
        y2="{OriginY + Height}%"
        fill="#00000011"
        style="stroke: gray; stroke-opacity: 0.3; stroke-dasharray: 1 "
      />
    {/each}
  {/if}

  <line
    x1="0"
    x2="100%"
    y1="{OriginY + Height}%"
    y2="{OriginY + Height}%"
    stroke="#888888"
    stroke-width="2"
  />



{/if}

<style>

</style>
