import { initChartStore } from "../../components/chart/store/chartStore.js";
import { derived, writable } from "svelte/store";
import GanttHelper from "../../components/ganttchart/GanttHelper.js";
import dayjs from "dayjs";
import { initMPSSettings } from "./MPSSettings.js";

function* gen_dates(startDate, endDate) {
  let currentDate = new Date(startDate)
  while (currentDate <= endDate) {
    yield currentDate
    const nextDate = new Date(currentDate)
    nextDate.setDate(nextDate.getDate() + 1)
    currentDate = nextDate
  }
}

export function initMPSChartStore(initialValues) {
  const DisplayLoadNumbers = writable(initialValues.DisplayLoadNumbers ?? false)
  const GetItemColor = writable(initialValues.GetItemColor)

  const MPSSettings = initMPSSettings()

  const chartStore = initChartStore(initialValues)

  const CurrentDisplayedDates = derived(
    [
      chartStore.CurrentDisplayedStartDate,
      chartStore.CurrentDisplayedEndDate,
    ],
    ([CurrentDisplayedStartDate, CurrentDisplayedEndDate]) => {
      const returned = []
      for (const date of gen_dates(CurrentDisplayedStartDate, CurrentDisplayedEndDate)) {
        date.setHours(0, 0, 0, 0)
        returned.push({
          date,
          dateNumber: Math.floor(date.getTime() / (1000 * 60 * 60 * 24)),
          dateString: dayjs(date).format("YYYY/MM/DD"),
          startXInPercentage: 100 * GanttHelper._gantHelper_scale(
            date.getTime(),
            CurrentDisplayedEndDate.getTime(),
            CurrentDisplayedStartDate.getTime()
          ),
          endXInPercentage: 100 * GanttHelper._gantHelper_scale(
            Math.min(GanttHelper.GetNextDateGraduation(date, 'D').getTime(), CurrentDisplayedEndDate.getTime()),
            CurrentDisplayedEndDate.getTime(),
            CurrentDisplayedStartDate.getTime()
          )
        })
      }
      return returned
    })

  return {
    ...chartStore,
    CurrentDisplayedDates,
    DisplayLoadNumbers,
    GetItemColor,
    MPSSettings,
  }
}
