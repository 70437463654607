<script>

export let selectedElementX;
export let selectedElementY;
export let selectedElementHeight;
export let graphWidth;

</script>

<svg width="99%" height="99%"  style="pointer-events: none;">
  <line x1="{selectedElementX}" 
        y1="{selectedElementY}" 
        x2="{selectedElementX+graphWidth-2}" 
        y2="{selectedElementY}"
        stroke="#FFF500" 
        stroke-width="3"
  />
  <line x1="{selectedElementX+graphWidth-2}" 
        y1="{selectedElementY}" 
        x2="{selectedElementX+graphWidth-2}" 
        y2="{selectedElementY+selectedElementHeight}"
        stroke="#FFF500" 
        stroke-width="3"
  />
  <line x1="{selectedElementX+graphWidth-2}" 
        y1="{selectedElementY+selectedElementHeight}" 
        x2="{selectedElementX}" 
        y2="{selectedElementY+selectedElementHeight}"
        stroke="#FFF500" 
        stroke-width="3"
  />
  <line x1="{selectedElementX}" 
        y1="{selectedElementY+selectedElementHeight}" 
        x2="{selectedElementX}" 
        y2="{selectedElementY}"
        stroke="#FFF500" 
        stroke-width="3"
  />
</svg>