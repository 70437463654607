<script>
  import { getContext, onMount } from 'svelte';
  import DataFetcher from './DataFetcher.js';
  import ConnectionLine from './ConnectionLine.svelte';
  import ConnectionLineMarkers from './ConnectionLineMarkers.svelte';

  export let GanttStore
  export let AdjustStamp;
  export let RowDownloadUrl;

  let selectedEntry = null;
  let selectedEntryComplete = null;
  let cachedRows = {};
  let rowsAllFiltered = [];
  let rowsDisplayedStart = 0;
  let rowHeight = 100;
  let startDate = new Date();
  let endDate = new Date();
  let Transactions = null;


  let focusedBlock = null;
  let connectionsToRender = [];

  // tmp variable
  let _tmp_rowdata = null;

  // fetch error handler
  const {handleNonBlockingFetchErrors} = getContext("fetchErrorHandler")

  const ApplyTransactionsToData = (item, idx) => {
    if (Transactions !== null && Transactions[item] !== undefined) {
      const itemTr = Transactions[item];
      _tmp_rowdata.Data[idx]._resStatus = itemTr.Work_Status;
      _tmp_rowdata.Data[idx]._resultStart = itemTr.Work_ResultStartTime;
      _tmp_rowdata.Data[idx]._resultEnd = itemTr.Work_ResultEndTime;
      _tmp_rowdata.Data[idx]._resultQty = itemTr.Work_ResultQty;
      _tmp_rowdata.Data[idx]._resultProgress = itemTr.Work_ResultProgress;
      _tmp_rowdata.Data[idx]._resultObtainTime = itemTr.Work_ResultObtainTime;
    }
  }

  const DownloadRowIfUnloaded = (row, alias, rowType) => {
    if (RowDownloadUrl === undefined || RowDownloadUrl === "") return;
    const rowLink = RowDownloadUrl + row + ".json";
    switch (rowType) {
      case "Resource":
        _tmp_rowdata = DataFetcher.FetchResourceInfo(row, rowLink, handleNonBlockingFetchErrors);
        break;
    }

    // Update data using transactions
    _tmp_rowdata.Data.forEach((item, idx) => ApplyTransactionsToData(item._operation, idx));
    GanttStore.RowsCached.update((cached) => {
      cachedRows = {
        ...cached,
        [row]: { ..._tmp_rowdata }
      };
      return cachedRows;
    });

    console.log(alias + " - Downloaded");
    _tmp_rowdata = null;
  };

  const IsItemVisible = (itemStart, itemEnd) => {
    return !((itemStart < startDate && itemEnd < startDate) || (itemStart > endDate && itemEnd > endDate));
  }

  const GetConnectionsFromWiresSet = (wires) => {
    wires.forEach((wire) => {
      const headResId = wire[2];
      const tailResId = wire[3];
      const head = cachedRows[headResId].Data.find((el) => el._objectID === wire[0]);
      const tail = cachedRows[tailResId].Data.find((el) => el._objectID === wire[1]);

      if (head === undefined || tail === undefined) return;

      let itemStart = new Date(0); // 1st january 1970
      let itemEnd = new Date(0);

      const prevArr = tail._prevTask.split(";").filter(e => e !== "").map(Number);
      const headPosOnTail = prevArr.indexOf(head._objectID);

      let kasanariMethod = "ES";
      let pegType = "I";

      if (tail._timeConstraintMethod !== undefined) {
        kasanariMethod = tail._timeConstraintMethod[headPosOnTail];
        //console.log(kasanariMethod);
      }
      if (tail._pegType !== undefined) {
        pegType = tail._pegType[headPosOnTail];
      }

      // Simplified Time Constraint Method
      // Head End  => Tail Start : ES, ESE, EES, GES
      // Head Start => Tail Start : SS
      // Start-Start End-End : SSEE, SSEEE, ESSEE

      if (["SSEE", "SSEEE", "ESSEE"].includes(kasanariMethod)) {
        // SS

        // Begin
        if (head._productionArr.length >= 2) itemStart = head._productionArr[0];
        else if (head._setupArr.length >= 2) itemStart = head._setupArr[0];
        else if (head._teardownArr.length >= 2) itemStart = head._teardownArr[0];

        // End
        if (tail._productionArr.length >= 2) itemEnd = tail._productionArr[0];
        else if (tail._setupArr.length >= 2) itemEnd = tail._setupArr[0];
        else if (tail._teardownArr.length >= 2) itemEnd = tail._teardownArr[0];

        if (IsItemVisible(itemStart, itemEnd)) {
          let connection = {
            RowStart: rowsAllFiltered.findIndex(item => item.Row === head._row) - rowsDisplayedStart,
            RowEnd: rowsAllFiltered.findIndex(item => item.Row === tail._row) - rowsDisplayedStart,
            HeadMethod: "S",
            TailMethod: "S",
            SubConnection: tail._useinstmaster_type === "S" || head._useinstmaster_type === "S",
            PegType: pegType,
            StartStamp: itemStart,
            EndStamp: itemEnd,
            HTakenResourceCount: head._takenResourceCount,
            HVerticalPosition: head._verticalPosition,
            HNecessarySpace: head._necessarySpace,
            TTakenResourceCount: tail._takenResourceCount,
            TVerticalPosition: tail._verticalPosition,
            TNecessarySpace: tail._necessarySpace,
            WhiteLink: true,
            ExtraWidth: 2,
          };
          connectionsToRender.push({...connection});
          connection.WhiteLink = false;
          connection.ExtraWidth = 0;
          connectionsToRender.push(connection);
        }

        // EE

        // Begin
        if (head._productionArr.length >= 2) itemStart = head._productionArr[head._productionArr.length-1];
        else if (head._teardownArr.length >= 2) itemStart = head._teardownArr[head._teardownArr.length-1];
        else if (head._setupArr.length >= 2) itemStart = head._setupArr[head._setupArr.length-1];

        // End
        if (tail._productionArr.length >= 2) itemEnd = tail._productionArr[tail._productionArr.length-1];
        else if (tail._teardownArr.length >= 2) itemEnd = tail._teardownArr[tail._teardownArr.length-1];
        else if (tail._setupArr.length >= 2) itemEnd = tail._setupArr[tail._setupArr.length-1];

        if (IsItemVisible(itemStart, itemEnd)) {
          let connection = {
            RowStart: rowsAllFiltered.findIndex(item => item.Row === head._row) - rowsDisplayedStart,
            RowEnd: rowsAllFiltered.findIndex(item => item.Row === tail._row) - rowsDisplayedStart,
            HeadMethod: "E",
            TailMethod: "E",
            SubConnection: tail._useinstmaster_type === "S" || head._useinstmaster_type === "S",
            PegType: pegType,
            StartStamp: itemStart,
            EndStamp: itemEnd,
            HTakenResourceCount: head._takenResourceCount,
            HVerticalPosition: head._verticalPosition,
            HNecessarySpace: head._necessarySpace,
            TTakenResourceCount: tail._takenResourceCount,
            TVerticalPosition: tail._verticalPosition,
            TNecessarySpace: tail._necessarySpace,
            WhiteLink: true,
            ExtraWidth: 2,
          };
          connectionsToRender.push({...connection});
          connection.WhiteLink = false;
          connection.ExtraWidth = 0;
          connectionsToRender.push(connection);
        }
      }
      else {
        let _hm = "S";
        let _tm = "S";

        if (["SS"].includes(kasanariMethod)) {
          // Begin
          if (head._productionArr.length >= 2) itemStart = head._productionArr[0];
          else if (head._setupArr.length >= 2) itemStart = head._setupArr[0];
          else if (head._teardownArr.length >= 2) itemStart = head._teardownArr[0];

          // End
          if (tail._productionArr.length >= 2) itemEnd = tail._productionArr[0];
          else if (tail._setupArr.length >= 2) itemEnd = tail._setupArr[0];
          else if (tail._teardownArr.length >= 2) itemEnd = tail._teardownArr[0];
        }
        else {
          // ES

          _hm = "E";

          // Begin
          if (head._productionArr.length >= 2) itemStart = head._productionArr[head._productionArr.length-1];
          else if (head._teardownArr.length >= 2) itemStart = head._teardownArr[head._teardownArr.length-1];
          else if (head._setupArr.length >= 2) itemStart = head._setupArr[head._setupArr.length-1];

          // End
          if (tail._productionArr.length >= 2) itemEnd = tail._productionArr[0];
          else if (tail._setupArr.length >= 2) itemEnd = tail._setupArr[0];
          else if (tail._teardownArr.length >= 2) itemEnd = tail._teardownArr[0];
        }

        // No render if the connection is out of view
        if (IsItemVisible(itemStart, itemEnd)) {
          let connection = {
            RowStart: rowsAllFiltered.findIndex(item => item.Row === head._row) - rowsDisplayedStart,
            RowEnd: rowsAllFiltered.findIndex(item => item.Row === tail._row) - rowsDisplayedStart,
            HeadMethod: _hm,
            TailMethod: _tm,
            SubConnection: tail._useinstmaster_type === "S" || head._useinstmaster_type === "S",
            PegType: pegType,
            StartStamp: itemStart,
            EndStamp: itemEnd,
            HTakenResourceCount: head._takenResourceCount,
            HVerticalPosition: head._verticalPosition,
            HNecessarySpace: head._necessarySpace,
            TTakenResourceCount: tail._takenResourceCount,
            TVerticalPosition: tail._verticalPosition,
            TNecessarySpace: tail._necessarySpace,
            WhiteLink: true,
            ExtraWidth: 2,
          };
          connectionsToRender.push({...connection});
          connection.WhiteLink = false;
          connection.ExtraWidth = 0;
          connectionsToRender.push(connection);
        }
      }


      // ===========


    });
    //console.log(connectionsToRender);
  }

  const IsNodeWithinFilteredRows = (objId, localloc) => {
    const rowName = localloc[0].toString();
    return rowsAllFiltered.some(item => item.Row === rowName);
  }

  // TODO: Ignore connection if RowType is not resource
  const ReloadConnectionsToRender = () => {
    connectionsToRender = [];

    const baseEntry = selectedEntryComplete;
    if (baseEntry === null) return;

    const wires = new Set();
    const visited = new Set();
    const toExplore = [];
    const getNodes = (tstr) => tstr.split(";").filter(e => e !== "").map(Number);
    toExplore.push(baseEntry);

    while (toExplore.length > 0) {
      const _entry = toExplore.pop();
      const objId = parseInt(_entry[0]);
      const resId = _entry[1].toString();

      visited.add(objId);
      // Download node row here if doesn't exist
      const correspondingRow = rowsAllFiltered.find(item => item.Row === resId);
      if (cachedRows[resId] === undefined) {
        DownloadRowIfUnloaded(correspondingRow.Row, correspondingRow.Alias, correspondingRow.RowType);
      }
      const curObj = cachedRows[resId].Data.find((el) => el._objectID === objId);

      if (curObj !== undefined) {
        const previousNodes = getNodes(curObj._prevTask);
        const nextNodes = getNodes(curObj._nextTask);

        previousNodes.forEach((e, idx) => {
          if (!visited.has(e)) {
            const pn = parseInt(e);
            const ll = curObj._prevTaskLocalLocs[idx];
            if (IsNodeWithinFilteredRows(pn, ll)) {
              wires.add([pn, objId, ll[0].toString(), resId]);
              toExplore.push([pn, ll[0].toString()]);
            }
          }
        });
        nextNodes.forEach((e, idx) => {
          if (!visited.has(e)) {
            const nn = parseInt(e);
            const ll = curObj._nextTaskLocalLocs[idx];
            if (IsNodeWithinFilteredRows(nn, ll)) {
              wires.add([objId, nn, resId, ll[0].toString()]);
              toExplore.push([nn, ll[0].toString()]);
            }
          }
        });
      }
    }

    //console.log(wires);
    GetConnectionsFromWiresSet(wires);
  }

  onMount(async () => {
    GanttStore.Transactions.subscribe((tr) => {
      Transactions = tr;
    });
    GanttStore.CurrentDisplayedEndDate.subscribe((newDate) => {
      endDate = newDate;
      ReloadConnectionsToRender();
    });
    GanttStore.CurrentDisplayedStartDate.subscribe((newDate) => startDate = newDate);
    GanttStore.DisplayedRowCount.subscribe((drc) => {
      rowHeight = (drc > 0) ? 100 / drc : 100;
      ReloadConnectionsToRender();
    });
    GanttStore.RowsAllFiltered.subscribe((raf) => {
      rowsAllFiltered = raf;
      ReloadConnectionsToRender();
    });
    GanttStore.RowsDisplayedStart.subscribe((rds) => {
      rowsDisplayedStart = rds;
      ReloadConnectionsToRender();
    });
    GanttStore.RowsCached.subscribe((rc) => {
      cachedRows = rc;
    });
    GanttStore.TimelineItems.subscribe((entries) => {
      const countEntries = entries.length;
      // Unique item selected
      if (countEntries === 1) {
        selectedEntry = entries[0][0];
        selectedEntryComplete = entries[0];
        focusedBlock = cachedRows[entries[0][1]].Data.find((el) => el._objectID === selectedEntry);
      }
      // Rubberband or cancel
      else {
        selectedEntry = null;
        focusedBlock = null;
        selectedEntryComplete = null;
      }
      ReloadConnectionsToRender();
    });

  });
</script>

<ConnectionLineMarkers />
<g>
  {#each connectionsToRender as connection}
    {#if connection.SubConnection === true}
      <ConnectionLine
        Connection={connection}
        RowHeight={rowHeight}
        AdjustStamp={AdjustStamp}
        StartDate={startDate}
        EndDate={endDate}
      />
    {/if}
  {/each}
</g>
<g>
{#each connectionsToRender as connection}
  {#if connection.SubConnection === false}
    <ConnectionLine
      Connection={connection}
      RowHeight={rowHeight}
      AdjustStamp={AdjustStamp}
      StartDate={startDate}
      EndDate={endDate}
    />
  {/if}
{/each}
</g>

<style>


</style>
