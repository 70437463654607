<script>
  import UpperPane from "../../../components/chart/UpperPane.svelte";
  import LeftMenuDialog from "../../../components/layout/leftMenuDialog/LeftMenuDialog.svelte";
  import LeftMenuDialogSectionTitle from "../../../components/layout/leftMenuDialog/LeftMenuDialogSectionTitle.svelte";
  import GeneralSelect from "../../../components/select/GeneralSelect.svelte";
  import HelpTooltip from "../../../components/tooltip/HelpTooltip.svelte";

  export let store
  const {MPSSettings} = store

  let leftMenuDialog = null
  function onClickUpperPaneHamburgerButton() {
    leftMenuDialog?.showModal()
  }
  function closeLeftMenuDialog() {
    leftMenuDialog?.close()
  }

  function onChangeSettings() {

  }
</script>

<UpperPane
  store={store}
  onClickHamburgerButton={onClickUpperPaneHamburgerButton}
>
  <LeftMenuDialog
    slot="left-menu-dialog"
    bind:dialog={leftMenuDialog}
    onClose={closeLeftMenuDialog}
  >
    <div class="left-menu-dialog-section">
      <LeftMenuDialogSectionTitle>在庫日数</LeftMenuDialogSectionTitle>
        <div class="left-menu-dialog-section-entry">
          <div class="left-menu-dialog-section-entry-title">
            在庫日数1日分の計算方法
            <HelpTooltip title="在庫日数の計算方法">
              PSI表の「在庫日数」行に表示される数値の計算方法を指定します。

              <div style="padding-top: 1rem;">
                <span>・来週の受注合計 / 5 (平日)</span>
                <div>
                  来週の受注合計を平日分の5で割った数値を在庫日数1日分として、在庫日数を計算します。
                  来週の受注合計が0のときは計算できないため、"--"を表示します。
                </div>
              </div>
            </HelpTooltip>
          </div>
          <GeneralSelect
            options={[
              // {value: "divideByMonthlyAverage", text: "当月の受注合計 / 当月の稼働日"},
              {value: "divideByNextWeekAverage", text: "来週の受注合計 / 5 (平日)"}
            ]}
            onChangeEvent={() => {}}
            value={$MPSSettings.psiTable.aggregateMethod}
            extraStyle="width: 100%"
          />
        </div>
    </div>
  </LeftMenuDialog>
</UpperPane>

<style>
  .left-menu-dialog-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .left-menu-dialog-section-entry {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>