<script>
  import { onMount } from "svelte";
  import GanttHelper from "./GanttHelper.js";
  import GeneralButton from "../button/GeneralButton.svelte";
  import { getTimeStringSec } from "../../utils/time";
  import { translate } from "../../i18n/i18next.js";
  import { fly } from 'svelte/transition';

  export let operationCode;
  export let onSubmit;
  export let onClose;
  export let prevStartDateTime;
  export let prevEndDateTime;
  export let prevStatus;
  export let prevQty;
  export let itemQty;
  export let scheduledStartDateTime;
  export let scheduledEndDateTime;
  export let prevProgress;
  export let prevObtainTime;

  let backupStartDateTimeString = null;
  let backupEndDateTimeString = null;

  let statusEnum = [
    GanttHelper.EStatus.NOTSTARTED,
    GanttHelper.EStatus.DESIGNED,
    GanttHelper.EStatus.CONFIRMED,
    GanttHelper.EStatus.STARTED,
    GanttHelper.EStatus.COMPLETE,
  ];

  const statusTranslationKeys = [
    "asprova.terms.operationStatuses.scheduled",
    "asprova.terms.operationStatuses.released",
    "asprova.terms.operationStatuses.frozen",
    "asprova.terms.operationStatuses.started",
    "asprova.terms.operationStatuses.completed"
  ];

  let obtainDateInput = null;
  let startDateInput = null;
  let endDateInput = null;
  let currentStatus = null;

  let startClearButton = null;
  let endClearButton = null;

  const N_SELECT = 3;
  let obtainSelects = Array(N_SELECT);
  let startSelects = Array(N_SELECT);
  let endSelects = Array(N_SELECT);
  let quantityInputs = Array(2);
  let quantityInputToggleDisabled = false;
  let progressInputToggleDisabled = false;

  let quantityInputBackup = Array(2);

  let ranges = [getHourRange, getMinuteRange, getSecondRange];

  function getHourRange(year, month) {
    return Array.from({ length: 24 }, (_, index) => index);
  }

  function getMinuteRange(year, month) {
    return Array.from({ length: 60 }, (_, index) => index);
  }

  function getSecondRange(year, month) {
    return Array.from({ length: 60 }, (_, index) => index);
  }

  // options: Array of options, only for dates
  function populateSelect(select, options, idx) {
    select.innerHTML = "";
    options.forEach((option) => {
      let optionElement = document.createElement("option");
      optionElement.value = option;
      optionElement.text = option;
      if (optionElement.text.length < 2) {
        optionElement.text = "0" + optionElement.text;
      }
      select.appendChild(optionElement);
    });
  }

  function selects2DateTime(dateInput, selects) {
    let date = new Date(dateInput.value);
    date.setHours(selects[0].value, selects[1].value, selects[2].value);
    return date;
  }

  function dateTimeString2selects(dateInput, selects, dateTimeString) {
    let dateTime = new Date(dateTimeString);

    if (!dateTimeString || isNaN(dateTime.getTime())) {
      dateInput.value = '';
      selects.forEach(function (select, index) {
        select.value = '0';
      });
      return;
    }
    let initialDates = [
      dateTime.getFullYear(),
      dateTime.getMonth() + 1,
      dateTime.getDay(),
      dateTime.getHours(),
      dateTime.getMinutes(),
      dateTime.getSeconds(),
    ];

    const y = dateTime.getFullYear();
    const m = ("0" + (dateTime.getMonth() + 1)).slice(-2);
    const d = ("0" + dateTime.getDate()).slice(-2);

    dateInput.value = `${y}-${m}-${d}`; // Should be yyyy-mm-dd

    selects.forEach(function (select, index) {
      select.value = initialDates[index + 3].toString();
    });
  }


  function clearStartDateInputs() {
    let currentStartDateTime = selects2DateTime(startDateInput, startSelects);
    backupStartDateTimeString = currentStartDateTime ? getTimeStringSec(currentStartDateTime, null) : null;
    dateTimeString2selects(startDateInput, startSelects, null); // Clear
  }

  function clearEndDateInputs() {
    let currentEndDateTime = selects2DateTime(endDateInput, endSelects);
    backupEndDateTimeString = currentEndDateTime ? getTimeStringSec(currentEndDateTime, null) : null;
    dateTimeString2selects(endDateInput, endSelects, null); // Clear
  }

  function switchStartDateInputsStatus(status) {
    if (startDateInput.disabled == !status) {
      // No need to do anything
      return;
    }
    startSelects.forEach((select) => (select.disabled = !status));
    startDateInput.disabled = !status;
    startClearButton.disabled = !status;
    if (!status) {
      clearStartDateInputs();
    } else {
      if (backupStartDateTimeString) {
        dateTimeString2selects(startDateInput, startSelects, backupStartDateTimeString);
      }
    }
  }

  function switchEndDateInputsStatus(status) {
    if (endDateInput.disabled == !status) {
      // No need to do anything
      return;
    }
    endSelects.forEach((select) => (select.disabled = !status));
    endDateInput.disabled = !status;
    endClearButton.disabled = !status;
    if (!status) {
      clearEndDateInputs();
    } else {
      if (backupEndDateTimeString) {
        dateTimeString2selects(endDateInput, endSelects, backupEndDateTimeString);
      }
    }
  }

  function disableDatesDependingOnStatus() {
    const status = parseInt(currentStatus);
    switch (status) {
      case GanttHelper.EStatus.COMPLETE:
        switchStartDateInputsStatus(true);
        switchEndDateInputsStatus(true);
        break;
      case GanttHelper.EStatus.STARTED:
        switchStartDateInputsStatus(true);
        switchEndDateInputsStatus(false);
        break;
      case GanttHelper.EStatus.UNSET:
      case GanttHelper.EStatus.CONFIRMED:
      case GanttHelper.EStatus.DESIGNED:
      case GanttHelper.EStatus.NOTSTARTED:
      default:
        switchStartDateInputsStatus(false);
        switchEndDateInputsStatus(false);
        break;
  }

    switch (status) {
      case GanttHelper.EStatus.STARTED:
      case GanttHelper.EStatus.COMPLETE:
        quantityInputToggleDisabled = false;
        progressInputToggleDisabled = false;

        if (quantityInputBackup[0] || quantityInputBackup[0] == 0) {
          quantityInputs[0].value = quantityInputBackup[0];
        }
        if (quantityInputBackup[1] || quantityInputBackup[1] == 0) {
          quantityInputs[1].value = quantityInputBackup[1];
        }
        break;
      default:
        quantityInputToggleDisabled = true;
        progressInputToggleDisabled = true;

        quantityInputBackup[0] = quantityInputs[0].value;
        quantityInputBackup[1] = quantityInputs[1].value;
        quantityInputs[0].value = '';
        quantityInputs[1].value = '';
        break;
    }
  }


  function onConfirm() {
    let newObtainTime = selects2DateTime(obtainDateInput, obtainSelects);
    if (isNaN(newObtainTime.getTime())) {
      newObtainTime = null;
    }
    const newObtainDateTimeString = newObtainTime ? getTimeStringSec(newObtainTime, null) : null

    let newStartDateTime = selects2DateTime(startDateInput, startSelects);
    if (startDateInput.disabled || isNaN(newStartDateTime.getTime())) {
      newStartDateTime = null;
    }
    const newStartDateTimeString = newStartDateTime ? getTimeStringSec(newStartDateTime, null) : null

    let newEndDateTime = selects2DateTime(endDateInput, endSelects);
    if (endDateInput.disabled  || isNaN(newEndDateTime.getTime())) {
      newEndDateTime = null;
    }
    const newEndDateTimeString = newEndDateTime ? getTimeStringSec(newEndDateTime, null) : null

    const newStatus = GanttHelper.GetStatusString(parseInt(currentStatus));
    const newQty = (quantityInputToggleDisabled || quantityInputs[0].value === '')
      ? null
      : Number(quantityInputs[0].value);

    const newProgress = (progressInputToggleDisabled || quantityInputs[1].value === '')
      ? null
      : Number(quantityInputs[1].value);

    onSubmit(newStartDateTimeString, newEndDateTimeString, newStatus, newQty, newProgress, newObtainDateTimeString);
  }

  onMount(async () => {
    for (let i = 0; i < N_SELECT; i++) {
      populateSelect(
        obtainSelects[i],
        ranges[i](obtainSelects[0].value, obtainSelects[1].value),
        i
      );
      populateSelect(
        startSelects[i],
        ranges[i](startSelects[0].value, startSelects[1].value),
        i
      );
      populateSelect(
        endSelects[i],
        ranges[i](endSelects[0].value, endSelects[1].value),
        i
      );
    }
  
    if (!prevQty || prevQty == -1) { 
      prevQty = '';
    }
    if (!prevProgress || prevProgress == -1) { 
      prevProgress = '';
    }
    
    currentStatus = GanttHelper.GetStatus(prevStatus);

    quantityInputs[0].value = prevQty;
    quantityInputs[1].value = prevProgress;

    dateTimeString2selects(obtainDateInput, obtainSelects, prevObtainTime);
    dateTimeString2selects(startDateInput, startSelects, prevStartDateTime);
    dateTimeString2selects(endDateInput, endSelects, prevEndDateTime);
    disableDatesDependingOnStatus();
  });
</script>

<div class="root">
  <div class="editWindow" in:fly={{ duration: 200 }} out:fly={{ duration: 200 }}>
    <div class="top-panel">
      <div class="top-panel-title">
        <span>
          {translate("generic.editResultShort")}&nbsp;/&nbsp;{operationCode}
        </span>
      </div>
      <div class="top-panel-info">
        <span>
          {translate("asprova.terms.productionStartTime")}: <b>{getTimeStringSec(scheduledStartDateTime)}</b>
        </span>
        <br/>
        <span>
          {translate("asprova.terms.productionEndTime")}: <b>{getTimeStringSec(scheduledEndDateTime)}</b>
        </span>
      </div>
    </div>
    <hr class="horizontal"/>
    <div class="editWindow_contexts">
      <div class="editWindow_editstatus">
        <div>
        <span class="result-edit-title">
          {translate("asprova.terms.status")}
        </span>
          <div class="status-radio-container">
            {#each statusTranslationKeys.map((translationKey, i) => [statusEnum[i], translationKey]) as option}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                class="status-radio"
                style="margin-top: 2%;"
                on:click={() => {
                currentStatus = option[0];
                disableDatesDependingOnStatus();
              }}
              >
                <input
                  type="radio"
                  bind:group={currentStatus}
                  value={option[0]}
                  on:change={disableDatesDependingOnStatus}
                />
                {translate(option[1])}
              </div>
            {/each}
          </div>
        </div>
      </div>
      <div style="border-right: solid 1px #888888;min-width: 1px"></div>
      <div class="editWindow_editstamps">
        <div>
          <div class="result-edit-title">
            {translate("asprova.terms.resultObtainTime")}
          </div>
          <br/>
          <div class="result-time-inputs-container">
            <input type="date" class="dateInput" bind:this={obtainDateInput} />
            <div class="result-time-time-inputs-container">
              <select
                class="obtainSelects"
                bind:this={obtainSelects[0]}
                style="margin-right: 2%;"
              />
              <span class="units">:</span>
              <select class="obtainSelects" bind:this={obtainSelects[1]} />
              <span class="units">:</span>
              <select class="obtainSelects" bind:this={obtainSelects[2]} />
            </div>
            <button
              class="clearButton"
              on:click={dateTimeString2selects(obtainDateInput, obtainSelects, null)}
            >
              {translate("generic.clear")}
            </button>
          </div>
        </div>
        <div>
          <div class="result-edit-title">
            {translate("asprova.terms.resultStartTime")}
          </div>
          <br/>
          <div class="result-time-inputs-container">
            <input type="date" class="dateInput" bind:this={startDateInput} />
            <div class="result-time-time-inputs-container">
              <select
                class="startSelects"
                bind:this={startSelects[0]}
                style="margin-right: 2%;"
              />
              <span class="units">:</span>
              <select class="startSelects" bind:this={startSelects[1]} />
              <span class="units">:</span>
              <select class="startSelects" bind:this={startSelects[2]} />
            </div>
            <button
              class="clearButton"
              on:click={clearStartDateInputs}
              bind:this={startClearButton}
            >
              {translate("generic.clear")}
            </button>
          </div>
        </div>
        <div>
          <div class="result-edit-title">
            {translate("asprova.terms.resultEndTime")}
          </div>
          <br/>
          <div class="result-time-inputs-container">
            <input type="date" class="dateInput" bind:this={endDateInput} />
            <div class="result-time-time-inputs-container">
              <select
                class="endSelects"
                bind:this={endSelects[0]}
                style="margin-right: 2%;"
              />
              <span class="units">:</span>
              <select class="endSelects" bind:this={endSelects[1]} />
              <span class="units">:</span>
              <select class="endSelects" bind:this={endSelects[2]} />
            </div>
            <button
              class="clearButton"
              on:click={clearEndDateInputs}
              bind:this={endClearButton}
            >
              {translate("generic.clear")}
            </button>
          </div>
        </div>
        <div>
          <div style="display: flex;">
            <div style="flex: 1">
              <span class="result-edit-title">
                {translate("asprova.terms.resultQuantity")}
              </span>
              <br/>
              <input
                style="max-width: 5rem;"
                type="number"
                class="quantityInputs"
                min="0"
                max={itemQty}
                bind:this={quantityInputs[0]}
                disabled={quantityInputToggleDisabled}
              />
              <span class="units" style="font-size: 1.3rem;">&nbsp;/ {itemQty}</span>
            </div>
            
            <div style="flex: 1">
              <span class="result-edit-title">
                {translate("asprova.terms.resultProgress")}
              </span>
              <br/>
              <input
                style="max-width: 5rem;"
                type="number"
                class="quantityInputs"
                min="0"
                max="100"
                bind:this={quantityInputs[1]}
                disabled={quantityInputToggleDisabled}
              />
              <span class="units" style="font-size: 1.3rem;">%&nbsp;/ 100%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="horizontal"/>
    <div class="bottom-controls">
      <GeneralButton
        buttonText={translate("generic.cancel")}
        buttonType="secondary"
        buttonFunction={onClose}
        buttonExtraStyle="min-width: 150px; min-height: 35px;"
      />
      <GeneralButton
        buttonText={"OK"}
        buttonFunction={onConfirm}
        buttonExtraStyle="min-width: 150px; min-height: 35px;"
      />
    </div>
  </div>
</div>
<style>
  hr {
    height: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    border: 0;
  }
  hr.horizontal {
    border-top: 1px solid #888888;
  }
  .obtainSelects,
  .startSelects,
  .endSelects,
  .quantityInputs {
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    text-align: center;
    font-size: 1.5rem;
    font-family: "BIZ UDPGothic", sans-serif;
    cursor: pointer;
  }
  .startSelects:disabled,
  .endSelects:disabled,
  .quantityInputs:disabled,
  .dateInput:disabled {
    background-color: #00000022;
    /* text-emphasis-style: 'x'; */
    color: gray;
    cursor: default;
    /* border: none; */
    position: relative; 
    /* opacity: 0; */
  }
  
  .top-panel {
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .top-panel-title {
    max-width: 100%;
    flex: 1;
    white-space: nowrap;
    overflow-wrap: anywhere;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .top-panel-info {
    white-space: nowrap;
    text-align: right;
    color: rgb(151, 151, 151);
    font-size: 1rem;
  }
  .root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 102;
  }

  .editWindow {
    background-color: white;
    max-height: 92vh;
    max-width: 90%;
    overflow-y: auto;
    border: 2px solid rgb(40, 56, 93);
    border-radius: 10px;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
  }
  .editWindow_contexts {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
  }
  .editWindow_editstatus {
    display: flex;
    min-height: 100%;
    flex-direction: column;
  }
  .editWindow_editstamps {
    display: flex;
    min-height: 100%;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 16px
  }
  .clearButton {
    white-space: nowrap;
    margin-right: 2%;
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .clearButton:disabled {
    /* opacity: 0; */
    background-color: #00000022;
    color: gray;
    cursor: default;
    /* border: none; */
  }
  .units {
    color: rgb(0, 0, 0);
    font-size: 1rem;
    text-align: center;
  }

  .dateInput {
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    text-align: center;
    font-size: 1.5rem;
    font-family: "BIZ UDPGothic", sans-serif;
    cursor: pointer;
  }
  .status-radio-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    height: 100%; 
  }
  .status-radio {
    width: 200px;
    height: 3rem;
    background-color: transparent;
    border: 1px solid black;
    color: rgb(26, 58, 145);
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-family: "BIZ UDPGothic", sans-serif;
    box-sizing: border-box;
    cursor: pointer;
  }
  .result-time-inputs-container {
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    padding: 8px 0;
  }
  .result-time-time-inputs-container {
    display: inline-flex;
    align-items: center;
    gap: .3rem;
  }
  .result-edit-title {
    font-size: 1.2rem;
    font-weight: bold;
    display: inline-flex; 
    align-items: center; 
    gap: 1rem
  }
  .bottom-controls {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    font-size: 1.2rem;
  }
</style>
