<script>
  import ChartContainer from "../../../components/chart/ChartContainer.svelte";
  import UpperPane from "../../../components/chart/UpperPane.svelte";
  import { fetchLoadGraphMPSResourceLoads } from "../../../utils/getData.js";
  import LoadGraphMPSChartContentRow from "./LoadGraphMPSChartContentRow.svelte";
  import dayjs from "dayjs";
  import { initMPSChartStore } from "../MPSChartStore.js";
  import MenuBarItem from "../../../components/menuBar/MenuBarItem.svelte";
  import ToggleSwitch from "../../../components/input/ToggleSwitch.svelte";
  import TableArea from "../../../components/chart/TableArea.svelte";
  import ChartArea from "../../../components/chart/ChartArea.svelte";
  import ChartContent from "../../../components/chart/ChartContent.svelte";
  import MPSCalendarHeader from "../MPSCalendarHeader.svelte";
  import {Random, createRandomSeedFromString} from "../../../utils/random.js";
  import { usePageContext } from "../../../components/pages/pageContext.js";
  import { useProject } from "../../../hooks/useProject.js";
  import { getContext } from "svelte";

  export let projectId
  export let project
  export let resources

  const {handleFetchErrors} = getContext("fetchErrorHandler")
  const myScheduleProjectData = useProject(projectId, handleFetchErrors)
  const pageContext = usePageContext()
  $: pageContext.setHeaderProps({
    title: $myScheduleProjectData ? `${$myScheduleProjectData.name} - 負荷グラフ` : `負荷グラフ`,
    uploadTime: project.UploadTimeStamp,
  })

  let rows = resources.Resources.map(resource => ({Title: resource, Color: "#cccccc", objectId: resource}))
  let LoadsDataByRowCache = null
  async function RowDataFetcher(rowData) {
    if (LoadsDataByRowCache && $LoadsDataByRowCache[rowData.objectId]) return $LoadsDataByRowCache[rowData.objectId]
    const data = await fetchLoadGraphMPSResourceLoads(projectId, rowData.objectId)
    data.byDates = Object.fromEntries(resources.Dates.map((dateString, index) => [dayjs(dateString).format("YYYY/MM/DD"), {Load: data.Loads[index], ByItem: data.ByItem[index]}]))
    LoadsDataByRowCache.update(prev => ({...prev, [rowData.objectId]: data}))
    return data
  }

  const itemColorsDict = new Map()
  function GetItemColor(itemCode) {
    if (itemColorsDict.has(itemCode)) return itemColorsDict.get(itemCode)
    const random = new Random(createRandomSeedFromString(itemCode))
    const h = random.nextInt(1, 360)
    const s = random.nextInt(0, 100)
    const l = random.nextInt(40, 100)
    const color = `hsl(${h}deg, ${s}%, ${l}%)`
    itemColorsDict.set(itemCode, color)
    return color
  }

  const store = initMPSChartStore({
    CurrentDisplayedStartDate: new Date(resources.Dates[0]),
    DisplayedEndDate: new Date(resources.Dates.at(-1)),
    DisplayedRowCount: 5,
    DisplayedSpanInSeconds: 604800 * 6,
    DisplayedStartDate: new Date(resources.Dates[0]),
    GetItemColor,
    RowDataFetcher,
    Rows: rows,
    RowsDisplayedStart: 0,
    TableAreaColumnTitle: "資源",
    ValidStartDate: new Date(resources.Dates[0]),
    ValidEndDate: new Date(resources.Dates.at(-1)),
  })

  const {
    DisplayLoadNumbers,
    RowsCached,
  } = store

  LoadsDataByRowCache = RowsCached

  $: console.log($RowsCached)
</script>

<div class="root">
  <UpperPane
    store={store}
  >
    <MenuBarItem slot="upperPane-settings">
      <span slot="label">稼働率・稼働時間表示</span>
      <ToggleSwitch bind:on={$DisplayLoadNumbers} />
    </MenuBarItem>
  </UpperPane>
  <ChartContainer store={store}>
    <TableArea
      slot="table-area"
      store={store}
      getColor={row => row.Color}
      getTitle={row => $RowsCached[row.Title]?.Res_Code ?? row.Title}
    />
    <ChartArea store={store} slot="chart-area">
      <MPSCalendarHeader store={store}/>
      <ChartContent store={store} let:props>
        <LoadGraphMPSChartContentRow
          slot="chart-content-row"
          store={store}
          props={{
            ...props,
            dateStrings: resources.Dates,
            dates: resources.Dates.map(dateString => dayjs(dateString).toDate())
          }}
        />
      </ChartContent>
    </ChartArea>
  </ChartContainer>
</div>

<style>
  .root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
</style>