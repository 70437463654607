<script>
  import AuthenticationRequired from "../../../components/functional/AuthenticationRequired.svelte";
  import ProjectPageBase from "../../../components/pages/projects/[project]/ProjectPageBase.svelte";
  import FetchPSITableData from "../../../components/pages/projects/[project]/fetchers/FetchPSITableData.svelte";
  import PSIGraph from "../../../views/MPS/PSIGraph/PSIGraph.svelte";
</script>

<AuthenticationRequired>
  <ProjectPageBase
    let:projectId
  >
    <FetchPSITableData
      projectId={projectId}
      let:data
    >
      <PSIGraph
        projectId={projectId}
        data={data}
      />
    </FetchPSITableData>
  </ProjectPageBase>
</AuthenticationRequired>