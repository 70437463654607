<script>
  import { derived } from "svelte/store";
  import TableAreaRow from "../../components/chart/TableAreaRow.svelte";

  export let store;
  export let onClick;

  export let items = [];

  const { DisplayedRowCount, RowsCurrentlyDisplayed } = store;

  const rows = derived([RowsCurrentlyDisplayed, DisplayedRowCount], ([RowsCurrentlyDisplayed, DisplayedRowCount]) => {
    const countsByItem = {};
    for (const row of RowsCurrentlyDisplayed) {
      const item = row.itemCode;
      if (item in countsByItem) countsByItem[item] += 1;
      else countsByItem[item] = 1;
    }
    const intrinsicHeightInPercentage = DisplayedRowCount > 0 ? 100 / DisplayedRowCount : 100;
    let cumulativeHeightInPercentage = 0;
    return Object.keys(countsByItem).map(item => {
      const height = countsByItem[item] * intrinsicHeightInPercentage;
      cumulativeHeightInPercentage += height;
      return {
        Title: item,
        Color: "#E9E9F0",
        Height: height,
        OriginY: cumulativeHeightInPercentage - height,
      };
    });
  });

</script>

{#each $rows as row, i (row.Title)}
  <g on:click={(e) => onClick(e, row.Title)} style="cursor: pointer;" class="container" bind:this={items[i]} row-title={row.Title}>
    <TableAreaRow
      Row={row.Title}
      Color={row === null ? "white" : row.Color}
      IsNull={row === null || row.Height === 0}
      Height={row.Height}
      OriginY={row.OriginY}
    />
  </g>
{/each}

<style>
  /* Ensure the container allows overflow */
  .container {
    position: relative;
    overflow: visible; /* Allow line to exceed container's bounds */
  }

</style>
