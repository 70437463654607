import { addWeeks, eachDayOfInterval, endOfWeek, format, nextDay, parse, startOfMonth, startOfWeek } from "date-fns"

/**
 *
 * @param {"divideByMonthlyAverage" | "divideByNextWeekAverage"} method
 * @param psiData
 * @param itemData
 */
export function aggregatePSITable(method, psiData, itemData) {
  switch (method) {
    case "divideByMonthlyAverage":
      return _divideByMonthlyAverage(psiData, itemData)
    case "divideByNextWeekAverage":
      return _divideByNextWeekAverage(itemData)
  }
}

function _divideByMonthlyAverage(psiData, itemData) {
  throw new Error("NOT IMPLEMENTED YET")
}

function _divideByNextWeekAverage(itemData) {
  const calculatedDemandsOfWeek = {}
  function calculateDemandsOfWeek(date) {
    const startOfTheWeek = startOfWeek(date, {weekStartsOn: 1})
    const dateString = format(startOfTheWeek, "yyyy/MM/dd")
    if (calculatedDemandsOfWeek[dateString]) {
      return calculatedDemandsOfWeek[dateString]
    }
    const endOfTheWeek = endOfWeek(startOfTheWeek, {weekStartsOn: 1})
    const dayStringsOfTheWeek = eachDayOfInterval({start: startOfTheWeek, end: endOfTheWeek})
      .map(date => format(date, "yyyy/MM/dd"))
    const totalDemand = dayStringsOfTheWeek
      .map(dateString => itemData.demand[dateString])
      .filter(demand => typeof demand === "number")
      .reduce((acc, val) => acc + val, 0)
    calculatedDemandsOfWeek[dateString] = totalDemand
    return totalDemand
  }

  const result = {}
  for (const dateString in itemData.inventory) {
    const date = parse(dateString, "yyyy/MM/dd", new Date("2020-01-01"))
    const sameDayOfNextWeek = addWeeks(date, 1)
    const demandOfNextWeek = calculateDemandsOfWeek(sameDayOfNextWeek)
    result[dateString] = demandOfNextWeek > 0
      ? itemData.inventory[dateString] / (demandOfNextWeek / 5)
      : null
  }
  return result
}

/**
 * Returns the start of the month, assuming every month starts on the weekDay given in the parameter.
 * @param {Date} date
 * @param {0 | 1 | 2 | 3 | 4 | 5 | 6} weekStartDay  0 = Sunday, 1 = Monday, ... 6 = Saturday
 */
function myStartOfMonth(date, weekStartDay = 1) {
  const myStartOfWeek = startOfWeek(date, {weekStartsOn: weekStartDay})
  if (myStartOfWeek.getMonth() !== date.getMonth()) {
    const startOfLastMonth = startOfMonth(myStartOfWeek)
    if (startOfLastMonth.getDay() === weekStartDay) return startOfLastMonth
    return nextDay(startOfLastMonth, weekStartDay)
  }

  const startOfThisMonth = startOfMonth(date)
  if (startOfThisMonth.getDay() === weekStartDay) return startOfThisMonth
  return nextDay(startOfThisMonth, weekStartDay)
}
