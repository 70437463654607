<script>
  import { onMount } from "svelte";
  import { writable, derived } from 'svelte/store';
  export let store;
  export let itemClicked;
  export let getValueDemand;
  export let getValueProduction;
  export let getValueInventory;

  export let graphWidth;
  export let graphHeight;
  export let closeGraph;
/*   export let graphWidth;
  export let graphHeight; */
  let demandSum = 0;
  let supplySum = 0;
  $: {
    console.log("1graphWidth", graphWidth);
  }
  console.log("graphWidth", graphWidth);
  console.log("graphHeight", graphHeight);
  let demandAccDataInit = {};
  let supplyAccDataInit = {};
  let demandAccData = {};
  let supplyAccData = {};
/*   let preValueS = null;
  let preValueD = null; */
  let preItem = null;
  let initialized = false;
  let maxValue = 0;
  const {
    CurrentDisplayedDates,
    DisplayedEndDate,
    DisplayedStartDate,
    RowDataFetcher,
  } = store;

  const dateNum = derived(
    [CurrentDisplayedDates],
    ([CurrentDisplayedDates]) => {
      return Object.keys(CurrentDisplayedDates).length;
    }
  );

  $: {
    console.log("dateNum", $dateNum)
  }

  const RowData = writable(null)
  async function fetchRowData(item) {
    if ($RowData == null || item != preItem) {
      preItem = item
      RowData.set(await $RowDataFetcher.apply(null, [{itemCode: item}]))
    }
  }
  $: if (itemClicked && initialized) {
    console.log("switch", itemClicked);
    fetchRowData(itemClicked)
      .then(() => initialization())
      .then(() => updateData());
  }
  // chart width and height: 80%



  function calculateX(ind, num) {

    return 80/(num-1)*ind;
  }

  function calculateY(value, sum) {
    /* console.log("value", value); */
    if (!value) {
      if (value != 0) {
        return 0;
      }
    }
    /* console.log("sum", sum); */
    return 80 - value/sum * 80;
  }

  function calculateIntersection(xPrev, yPrevSupply, xCurrent, yCurrentSupply, yPrevDemand, yCurrentDemand) {
    // Calculate slopes
    let mSupply = (yCurrentSupply - yPrevSupply) / (xCurrent - xPrev);
    let mDemand = (yCurrentDemand - yPrevDemand) / (xCurrent - xPrev);

    // Calculate intercepts
    let bSupply = yPrevSupply - mSupply * xPrev;
    let bDemand = yPrevDemand - mDemand * xPrev;

    // Find the intersection point
    let xIntersection = (bDemand - bSupply) / (mSupply - mDemand);
    let yIntersection = mSupply * xIntersection + bSupply;

    return { x: xIntersection, y: yIntersection };
  }
  function updateData() {
    console.log("run");

    // Iterate through each date in CurrentDisplayedDates
    $CurrentDisplayedDates.forEach(element => {
      const date = element.dateString;

      // Check if the date is already in demandAccData and supplyAccData
      if (!(date in demandAccData)) {
        // Add the date with the last demand value if missing
        demandAccData[date] = demandSum;
      }

      if (!(date in supplyAccData)) {
        // Add the date with the last supply value if missing
        supplyAccData[date] = supplySum;
      }
    });

  }

  $: if ($CurrentDisplayedDates.length > 0  && initialized) {
    console.log("update");
    updateData();
  }

  async function initialization() {
    let runningTotalD = 0;
    let runningTotalS = 0;

    // Helper function to format the date as 'YYYY/MM/DD'
    function formatDate(date) {
      return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
    }

    // Helper function to iterate over the date range
    function* dateRange(startDate, endDate) {
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        yield new Date(currentDate);  // Return a copy of the current date
        currentDate.setDate(currentDate.getDate() + 1);  // Move to the next day
      }
    }

    // Iterate over the date range from DisplayedStartDate to DisplayedEndDate
    for (const dateObj of dateRange($DisplayedStartDate, $DisplayedEndDate)) {
      const date = formatDate(dateObj);  // Format the date as 'YYYY/MM/DD'

      if (!runningTotalS) {
        runningTotalS += getValueDemand($RowData, date) + getValueInventory($RowData, date) - getValueProduction($RowData, date);
      }

      if (getValueDemand($RowData, date)) {
        runningTotalD += getValueDemand($RowData, date);
      }

      if (getValueProduction($RowData, date)) {
        runningTotalS += getValueProduction($RowData, date);
      }

      // Set the cumulative total for this date
      demandAccDataInit[date] = runningTotalD;
      supplyAccDataInit[date] = runningTotalS;

      // Optional logging if needed
      /* console.log("runningTotalD", runningTotalD);
      console.log("runningTotalS", runningTotalS); */
    }
    // Calculate the final demand and supply sums
    demandSum = demandAccDataInit[Object.keys(demandAccDataInit).pop()];
    supplySum = supplyAccDataInit[Object.keys(supplyAccDataInit).pop()];
    demandAccData = { ...demandAccDataInit };
    supplyAccData = { ...supplyAccDataInit };

    // Set the max value between demandSum and supplySum
    maxValue = Math.max(demandSum, supplySum);
  }

  onMount(async () => {
    await fetchRowData(itemClicked);
    console.log("RowData", $RowData);
    await initialization();
    initialized = true;
  })

</script>

<svg class="acc-graph" >

  <image
    x="{graphWidth - 50}px"
    y="20px"
    width="20" height="20"
    href="/assets/icons/icon_help_close.svg"
    alt="close_graph" 
    on:click="{closeGraph}"
    style="cursor: pointer;"
  />

  <!-- Title -->
  <text 
    x="{0.5 * graphWidth}px" 
    y="20px"       
    text-anchor="middle"
    dominant-baseline="middle"
    font-size="24px" 
    fill="#000"
  >
    {itemClicked}
  </text>

  <!-- Y-axis (累積数) -->
  <line 
    x1="{0.15 * graphWidth}px" 
    y1="{0.1 * graphHeight}px" 
    x2="{0.15 * graphWidth}px" 
    y2="{0.9 * graphHeight}px" 
    stroke="#C8C6C6" 
    stroke-width="2"
  />

  <!-- X-axis (日付) -->
  <line 
    x1="{0.15 * graphWidth}px" 
    y1="{0.9 * graphHeight}px" 
    x2="{0.95 * graphWidth}px" 
    y2="{0.9 * graphHeight}px" 
    stroke="#C8C6C6" 
    stroke-width="2"
  />

  <!-- Y-axis labels -->
  <text 
    x="{0.14 * graphWidth}px" 
    y="{0.08 * graphHeight}px" 
    text-anchor="end" 
    font-size="12px"
  >
    累積数
  </text>
  <text 
    x="{0.14 * graphWidth}px" 
    y="{0.1 * graphHeight}px" 
    text-anchor="end" 
    dominant-baseline="middle" 
    font-size="12px"
  >
    6000
  </text>
  <line 
    x1="{0.15 * graphWidth}px" 
    y1="{0.1 * graphHeight}px" 
    x2="{0.95 * graphWidth}px" 
    y2="{0.1 * graphHeight}px" 
    stroke="#C8C6C6" 
    stroke-width="2"
  />

  <text 
    x="{0.14 * graphWidth}px" 
    y="{0.3 * graphHeight}px" 
    text-anchor="end" 
    dominant-baseline="middle" 
    font-size="12px"
  >
    4500
  </text>
  <line 
    x1="{0.15 * graphWidth}px" 
    y1="{0.3 * graphHeight}px" 
    x2="{0.95 * graphWidth}px" 
    y2="{0.3 * graphHeight}px" 
    stroke="#C8C6C6" 
    stroke-width="2"
  />

  <text 
    x="{0.14 * graphWidth}px" 
    y="{0.5 * graphHeight}px" 
    text-anchor="end" 
    dominant-baseline="middle" 
    font-size="12px"
  >
    3000
  </text>
  <line 
    x1="{0.15 * graphWidth}px" 
    y1="{0.5 * graphHeight}px" 
    x2="{0.95 * graphWidth}px" 
    y2="{0.5 * graphHeight}px" 
    stroke="#C8C6C6" 
    stroke-width="2"
  />

  <text 
    x="{0.14 * graphWidth}px" 
    y="{0.7 * graphHeight}px" 
    text-anchor="end" 
    dominant-baseline="middle" 
    font-size="12px"
  >
    1500
  </text>
  <line 
    x1="{0.15 * graphWidth}px" 
    y1="{0.7 * graphHeight}px" 
    x2="{0.95 * graphWidth}px" 
    y2="{0.7 * graphHeight}px" 
    stroke="#C8C6C6" 
    stroke-width="2"
  />

  <text 
    x="{0.14 * graphWidth}px" 
    y="{0.9 * graphHeight}px" 
    text-anchor="end" 
    dominant-baseline="middle" 
    font-size="12px"
  >
    0
  </text>
<!--   <text x="15%" y="15%" text-anchor="end" font-size="12px">0000</text>
  <text x="15%" y="15%" text-anchor="end" font-size="12px">0000</text> -->
  <!-- <text x={padding - 10} y={mapToY(12000)} text-anchor="end" font-size="12px">12,000</text> -->
  {#each $CurrentDisplayedDates as date, index}
    <text x="{(15+calculateX(index, $dateNum))/100*graphWidth}px" y="{0.91 * graphHeight}px" text-anchor="middle" dominant-baseline="Hanging" font-size="12px">
      {date.dateString.split('/').slice(1).join('/')}
    </text>
    {#if index != 0 && $dateNum && demandAccData && supplyAccData && maxValue}
      <line x1="{(15+calculateX(index, $dateNum))/100*graphWidth}px" y1="{0.9 * graphHeight}px" x2="{(15+calculateX(index, $dateNum))/100*graphWidth}px" y2="{0.1 * graphHeight}px" stroke="#C8C6C6" stroke-width="2"/>
      <!-- Determine the y-coordinates for supply and demand -->

      <!-- hardcode 6000 to maxvalue for test -->
      {@const currentSupplyY = 10 + calculateY(supplyAccData[date.dateString], 6000)}
      {@const previousSupplyY = 10 + calculateY(supplyAccData[$CurrentDisplayedDates[index-1].dateString], 6000)}
      {@const currentDemandY = 10 + calculateY(demandAccData[date.dateString], 6000)}
      {@const previousDemandY = 10 + calculateY(demandAccData[$CurrentDisplayedDates[index-1].dateString], 6000)}

      {#if currentSupplyY <= currentDemandY && previousSupplyY <= previousDemandY}
        <!-- Supply is above Demand, use #C4C4FF -->
        <polygon points="
          {(15+calculateX(index, $dateNum))/100*graphWidth},{currentSupplyY/100*graphHeight}
          {(15+calculateX(index, $dateNum))/100*graphWidth},{currentDemandY/100*graphHeight}
          {(15+calculateX(index-1, $dateNum))/100*graphWidth},{previousDemandY/100*graphHeight}
          {(15+calculateX(index-1, $dateNum))/100*graphWidth},{previousSupplyY/100*graphHeight}" 
          fill="#C4C4FF" opacity="0.3"/>
      {:else if currentSupplyY > currentDemandY && previousSupplyY < previousDemandY}
        <!-- Calculate the intersection point -->
        {@const intersection = calculateIntersection(
          (15 + calculateX(index - 1, $dateNum)) / 100 * graphWidth, previousSupplyY / 100 * graphHeight,
          (15 + calculateX(index, $dateNum)) / 100 * graphWidth, currentSupplyY / 100 * graphHeight,
          previousDemandY / 100 * graphHeight, currentDemandY / 100 * graphHeight
        )}
        <!-- Supply and demand lines intersect -->
        <polygon points="
          {(15 + calculateX(index, $dateNum)) / 100 * graphWidth},{currentSupplyY / 100 * graphHeight}
          {(15 + calculateX(index, $dateNum)) / 100 * graphWidth},{currentDemandY / 100 * graphHeight}
          {intersection.x},{intersection.y}" 
          fill="#FFAEC9" opacity="0.3"/>

        <polygon points="
          {(15 + calculateX(index-1, $dateNum)) / 100 * graphWidth},{previousDemandY / 100 * graphHeight}
          {(15 + calculateX(index-1, $dateNum)) / 100 * graphWidth},{previousSupplyY / 100 * graphHeight}
          {intersection.x},{intersection.y}" 
          fill="#C4C4FF" opacity="0.3"/>
        <rect width="{(15 + calculateX(index, $dateNum)) / 100 * graphWidth - intersection.x}px" height="{0.8 * graphHeight}px" x="{intersection.x}px" y="{0.1 * graphHeight}px" fill="#FFAEC9" opacity="0.2"/>
      {:else if currentSupplyY < currentDemandY && previousSupplyY > previousDemandY}
        <!-- Calculate the intersection point -->
        {@const intersection = calculateIntersection(
          (15 + calculateX(index - 1, $dateNum)) / 100 * graphWidth, previousSupplyY / 100 * graphHeight,
          (15 + calculateX(index, $dateNum)) / 100 * graphWidth, currentSupplyY / 100 * graphHeight,
          previousDemandY / 100 * graphHeight, currentDemandY / 100 * graphHeight
        )}
        <!-- Supply and demand lines intersect -->
        <polygon points="
          {(15 + calculateX(index, $dateNum)) / 100 * graphWidth},{currentSupplyY / 100 * graphHeight}
          {(15 + calculateX(index, $dateNum)) / 100 * graphWidth},{currentDemandY / 100 * graphHeight}
          {intersection.x},{intersection.y}" 
          fill="#C4C4FF" opacity="0.3"/>

        <polygon points="
          {(15 + calculateX(index-1, $dateNum)) / 100 * graphWidth},{previousDemandY / 100 * graphHeight}
          {(15 + calculateX(index-1, $dateNum)) / 100 * graphWidth},{previousSupplyY / 100 * graphHeight}
          {intersection.x},{intersection.y}" 
          fill="#FFAEC9" opacity="0.3"/>
        
        <rect width="{intersection.x - (15 + calculateX(index-1, $dateNum)) / 100 * graphWidth}px" height="{0.8 * graphHeight}px" x="{(15 + calculateX(index-1, $dateNum)) / 100 * graphWidth}px" y="{0.1 * graphHeight}px" fill="#FFAEC9" opacity="0.2"/>
      {:else if currentSupplyY >= currentDemandY && previousSupplyY >= previousDemandY}
        <polygon points="
        {(15+calculateX(index, $dateNum))/100*graphWidth},{currentSupplyY/100*graphHeight}
        {(15+calculateX(index, $dateNum))/100*graphWidth},{currentDemandY/100*graphHeight}
        {(15+calculateX(index-1, $dateNum))/100*graphWidth},{previousDemandY/100*graphHeight}
        {(15+calculateX(index-1, $dateNum))/100*graphWidth},{previousSupplyY/100*graphHeight}" 
        fill="#FFAEC9" opacity="0.3"/>
        <rect width="{(15+calculateX(index, $dateNum))/100*graphWidth - (15 + calculateX(index-1, $dateNum)) / 100 * graphWidth}px" height="{0.8 * graphHeight}px" x="{(15 + calculateX(index-1, $dateNum)) / 100 * graphWidth}px" y="{0.1 * graphHeight}px" fill="#FFAEC9" opacity="0.2"/>
      {/if}

      <!-- supply line -->
      <line x1="{(15+calculateX(index, $dateNum))/100*graphWidth}px" 
            y1="{currentSupplyY/100*graphHeight}px" 
            x2="{(15+calculateX(index-1, $dateNum))/100*graphWidth}px" 
            y2="{previousSupplyY/100*graphHeight}px" 
            stroke="#7171FF" stroke-width="2"/>
<!--       <text x="{(15+calculateX(index, $dateNum))/100*graphWidth}px" 
            y="{currentSupplyY/100*graphHeight}px" 
            text-anchor="middle" dominant-baseline="Auto" font-size="12px">
        {supplyAccData[date.dateString]}
      </text> -->
      <!-- demand line -->
      <line x1="{(15+calculateX(index, $dateNum))/100*graphWidth}px" 
            y1="{currentDemandY/100*graphHeight}px" 
            x2="{(15+calculateX(index-1, $dateNum))/100*graphWidth}px" 
            y2="{previousDemandY/100*graphHeight}px" 
            stroke="#FF4080" stroke-width="2"/>
<!--       <text x="{(15+calculateX(index, $dateNum))/100*graphWidth}px" 
            y="{currentDemandY/100*graphHeight}px" 
            text-anchor="middle" dominant-baseline="Auto" font-size="12px">
        {demandAccData[date.dateString]}
      </text> -->

    {/if}
  {/each}

  <!-- Legend -->
  <text x="80%" y="8%" font-size="16px" text-anchor="end" dominant-baseline="middle" fill="#7171FF">供給</text>
  <line x1="74%" y1="8%" x2="69%" y2="8%" stroke="#7171FF" stroke-width="3"/>
  <text x="95%" y="8%" font-size="16px" text-anchor="end" dominant-baseline="middle" fill="#FF4080">需要</text>
  <line x1="89%" y1="8%" x2="84%" y2="8%" stroke="#FF4080" stroke-width="3"/>
</svg>

<style>
  .acc-graph {
    position: absolute;
    top: 10px; /* 10px space from the top */
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 10px);
    max-height: 100%; /* Constrain the height relative to the viewport */
    border: 2px solid #888888;
    border-bottom: none;
    box-sizing: border-box;
    background-color: white;
  }
</style>
