<script>
  import GanttHelper from "../ganttchart/GanttHelper.js";

  export let store
  const {
    DisplayedRowCount,
    DisplayedSpanInSeconds,
    RowsCurrentlyDisplayed,
  } = store

  const GeneralZoom = (e) => {
    e.preventDefault();

    if (e.ctrlKey) {
      // Row nb scroll
      if (e.deltaY > 0)
        DisplayedRowCount.update((n) => (n + 1 >= 100) ? n : n + 1);
      else if (e.deltaY < 0)
        DisplayedRowCount.update((n) => (n > 1) ? n - 1 : n);
      else pass
      ```
      // Zoom
      if (e.deltaY > 0)
        DisplayedSpanInSeconds.update((d) => Math.min(d * 1.05, GanttHelper.GHYEAR));
      else if (e.deltaY < 0)
        DisplayedSpanInSeconds.update((d) => Math.max(d / 1.05, GanttHelper.GHDAY));
      ```
    }
  }

  let svgEl = null
  let rootSvgDimension = {width: 0, height: 0}
  $: {
    if (svgEl) {
      const clientRect = svgEl.getBoundingClientRect()
      rootSvgDimension = {
        width: clientRect.width,
        height: clientRect.height,
      }
    }
  }

</script>

<svg id="chartArea_chartContent" class="pullable" on:wheel={GeneralZoom} bind:this={svgEl}>
  <pattern
    id="stripe"
    patternUnits="userSpaceOnUse"
    width="6.5"
    height="6.5"
    patternTransform="rotate(45)"
  >
    <line
      x1="0"
      y1="0"
      x2="0"
      y2="6.5"
      stroke="#828282"
      stroke-width="1"
    />
  </pattern>
  {#each $RowsCurrentlyDisplayed as row, index}
    {#if row != null}
      {#key row.objectId}
        <slot
          name="chart-content-row"
          props={{
            Row: row,
            RootSvgDimension: rootSvgDimension,
            RowHeightInPx: ($DisplayedRowCount > 0) ? rootSvgDimension.height / $DisplayedRowCount : rootSvgDimension.height,
            OriginYInPx: (($DisplayedRowCount > 0) ? rootSvgDimension.height / $DisplayedRowCount : rootSvgDimension.height) * index
          }}
        />
      {/key}
    {/if}
  {/each}
</svg>

<style>
  svg#chartArea_chartContent {
    width: 100%;
    flex: 1;
  }
</style>
