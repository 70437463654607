<script>
  import { onMount } from "svelte";
  import MapCard from "./MapCard.svelte";
  import MapConnection from "./MapConnection.svelte";
  import { translate } from "../../../i18n/i18next.js";
  import { SelectRectangle } from "../../../utils/selectRectangle";

  export let GanttStore

  const {
    RowsAllFiltered

  } = GanttStore;

  // Display map or error switch
  let displayMap = false;
  let errorMessage = "";
  // Map elements
  let highlightedBlock = null;
  let cachedRows = {};
  let mapCards = {};
  let currentStyle = "";
  let mapLinks = [];
  // SVG manipulation variables
  let svgInstance;
  let zoomFactor = 1;

  // Map card callbacks (same callback as in timeline area)
  const JumpToDateCallback = (data) => {
    GanttStore.CurrentDisplayedStartDate.set(data._productionArr[0]);
    const _index = $RowsAllFiltered.findIndex((el) => el.Row === data._row);
    if (_index >= 0) {
      GanttStore.RowsDisplayedStart.update((_) => _index);
    }
    else {
      console.warn("Filtered row");
      // Display warning notification
    }
    SelectRectangle.ForceSelect([data._objectID], data._row);
  }

  onMount(async () => {
    GanttStore.RowsCached.subscribe((rc) => cachedRows = rc);
    GanttStore.CurrentStyle.subscribe((cs) => currentStyle = cs);
    GanttStore.TimelineItems.subscribe((entries) => {
      const countEntries = entries.length;
      // Rubberband selection
      if (countEntries > 1) {
        displayMap = false;
        highlightedBlock = null;
        errorMessage = translate("frontend:ganttChart.mapDisplay.multipleSelectedOperation");
      }
      // Single operation selection
      else if (countEntries === 1) {
        displayMap = true;
        highlightedBlock = entries[0];
        errorMessage = "";
      }
      // Empty
      else {
        displayMap = false;
        highlightedBlock = null;
        errorMessage = translate("frontend:ganttChart.mapDisplay.noSelectedOperation");
      }
    });
  });

  const GenerateMapCards = () => {
    if (cachedRows === {} || highlightedBlock === null) return;

    mapCards = {};
    mapLinks = [];
    // All the algorhithm logic goes here

    // Init: Store parameters of the first (highlighted) element
    const _initialOpeID = parseInt(highlightedBlock[0]);
    const _initialResID = highlightedBlock[1];

    // Step 1: Fetch all basic map card information from cachedRows
    {
      let stack = [];

      stack.push([_initialOpeID, _initialResID]); // [Operation ID, Resource ID, Tree Depth]
      while (stack.length > 0) {
        let _top = stack[stack.length - 1];
        stack.pop();

        // 1.1: Get current node information from cached rows and append to mapCards
        let _cd = {};
        let _opId = _top[0];
        let _rsId = _top[1];
        let _data = cachedRows[_rsId].Data.find((el) => el._objectID == _opId);

        // Ignore the block if not found
        if (_data === undefined) continue;

        const _parents = _data._prevTask.split(";").filter(e => e !== "").map(Number);
        const _children = _data._nextTask.split(";").filter(e => e !== "").map(Number);
        _cd["ID"] = _opId;
        _cd["Data"] = _data;
        _cd["Pos"] = [0, 30];
        _cd["Highlighted"] = (_opId === _initialOpeID);
        _cd["Parents"] = _parents;
        _cd["Children"] = _children;

        // 1.2: Use LocalLocs to get the parent/children resource location and add parents/childrens to the stack
        _parents.forEach((parent, idx) => {
          if (!(parent in mapCards)) {
            const _parentRsId = _data._prevTaskLocalLocs[idx][0].toString();
            if (_parentRsId in cachedRows)
              stack.push([parent, _parentRsId]);
            else
              _cd["Parents"][idx] = null;
          }
        });
        _children.forEach((child, idx) => {
          if (!(child in mapCards)) {
            const _childRsId = _data._nextTaskLocalLocs[idx][0].toString();
            if (_childRsId in cachedRows) {
              stack.push([child, _childRsId]);
            }
            else
              _cd["Children"][idx] = null;
          }
        });

        _cd["Parents"] = _cd["Parents"].filter(x => x != null);
        _cd["Children"] = _cd["Children"].filter(x => x != null);

        mapCards[_opId] = _cd;
      }
    }

    // Step 1.5: For each cards, sanitize the parents and children for which blocks has been not found
    {
      for (const key of Object.keys(mapCards)) {
        if (!mapCards[key]["Highlighted"])
          if (mapCards[key].Data._useinstmaster_type === "S") delete mapCards[key];
      }

      for (const key of Object.keys(mapCards)) {
        mapCards[key]["Parents"] = mapCards[key]["Parents"].filter(x => mapCards[x] !== undefined);
        mapCards[key]["Children"] = mapCards[key]["Children"].filter(x => mapCards[x] !== undefined);
      }
    }

    // Step 2: Calculate row positionments (Y-axis) for each node using each parent-less nodes as roots
    {
      // 2.1: List each root nodes
      let _roots = [];
      for (const [key, val] of Object.entries(mapCards)) {
        if (val.Parents.length === 0) _roots.push(parseInt(key));
      }

      // 2.2: Calculate rows iteratively, new roots always come on a new row for simplicity
      let _row = 0;

      _roots.forEach((_root) => {
        let stack = [];

        stack.push(_root);

        while (stack.length > 0) {
          let _top = stack[stack.length - 1];
          stack.pop();

          let _childrenList = mapCards[_top].Children;
          mapCards[_top].COORD_Row = _row;

          while (_childrenList.length > 0) {
            for (let j = _childrenList.length - 1; j >= 1; j--) {
              // Not visited yet
              const _chld = _childrenList[j];
              if (mapCards[_chld].COORD_Row === undefined)
                stack.push(_chld);
            }

            _top = _childrenList[0];
            _childrenList = mapCards[_top].Children;
            if (mapCards[_top].COORD_Row === undefined)
              mapCards[_top].COORD_Row = _row;
          }

          _row = _row + 1;
        }
      });
    }

    // Step 3: Calculate layer positionments (X-axis) for each node using a DFS
    {
      // 3.1: List each root nodes
      let _roots = [];
      for (const [key, val] of Object.entries(mapCards)) {
        if (val.Parents.length === 0) _roots.push(parseInt(key));
      }

      // 3.2: Process BFS iteratively
      _roots.forEach((_root) => {
        let stack = [];

        stack.push([_root, 0]);

        while (stack.length > 0) {
          let _top = stack[stack.length - 1];
          stack.pop();

          // Set stacked level
          let [_topId, _level] = _top;
          mapCards[_topId].COORD_Level = _level;

          let _childrenList = mapCards[_topId].Children;
          _childrenList.forEach((child) => {
            const _newLevel = _level + 1;
            // Also re-add if the new level is higher (keep the highest layer for a node)
            if (mapCards[child].COORD_Level === undefined || _newLevel > mapCards[child].COORD_Level) {
              stack.push([child, _newLevel]);
            }
          });
        }
      });

      // TODO: For multiple roots find a way to start at a relevant level per root instead of always 0
    }

    // Step 4: Localize the coordinates of the highlighted node and set graph coordinates for all nodes
    {
      // 4.1: Localize the coordinates of the highlighted node
      const _hCoords = [mapCards[_initialOpeID].COORD_Level, mapCards[_initialOpeID].COORD_Row];
      const _cCoords = [0, 30];
      const _cellSize = [120, 40];

      // 4.2: Convert grid coordinates to graph coordinates
      for (let card of Object.values(mapCards)) {
        card["Pos"] = [
          _cCoords[0] + _cellSize[0] * (card.COORD_Level - _hCoords[0]),
          _cCoords[1] + _cellSize[1] * (card.COORD_Row - _hCoords[1]),
        ];
      }
    }

    // Step 5: Using the graph coordinates, generate the map links
    {
      for (const card of Object.values(mapCards)) {
        const _posOut = [card.Pos[0] + 81, card.Pos[1] + 15];

        for (const child of card.Children) {
          const _ref = mapCards[child];
          const _posIn = [_ref.Pos[0] - 1, _ref.Pos[1] + 15];

          // Get Peg Type and set arrow color
          const prevArr = _ref.Data._prevTask.split(";").filter(e => e !== "").map(Number);
          const headPosOnTail = prevArr.indexOf(card.Data._objectID);

          let peg = "I";
          if (_ref.Data._pegType !== undefined) {
            peg = _ref.Data._pegType[headPosOnTail];
          }

          let _linkColor = "blue";
          if (peg === "E") _linkColor = "magenta";
          if (peg === "T") _linkColor = "cyan";
          // ----------------------------------

          const _subConnection = (card.Data._useinstmaster_type === "S" || _ref.Data._useinstmaster_type === "S");

          // Case 1: If straight arrow or diagonal between neighboring layers
          if (_ref.COORD_Level - card.COORD_Level === 1 || _ref.COORD_Row === card.COORD_Row) {
            const _link = {
              X: [_posOut[0], _posIn[0]],
              Y: [_posOut[1], _posIn[1]],
              Color: _linkColor,
              SubConnection: _subConnection,
              Arrow: true
            };

            mapLinks.push(_link);
          }
          // Case 2: Split complex arrows in 2 to avoid weird looking layouts
          else {
            const _midpointX = _ref.Pos[0] - 39;

            const _link1 = {
              X: [_posOut[0], _midpointX],
              Y: [_posOut[1], _posOut[1]],
              Color: _linkColor,
              Arrow: false
            };
            const _link2 = {
              X: [_midpointX, _posIn[0]],
              Y: [_posOut[1], _posIn[1]],
              Color: _linkColor,
              Arrow: true
            };
            mapLinks.push(_link1);
            mapLinks.push(_link2);
          }

        }
      }
    }

    console.log(mapCards);
  }

  const GeneralZoom = (e) => {
    event.preventDefault();

    // https://stackoverflow.com/questions/76150884/how-to-use-the-mouse-wheel-to-zoom-on-an-svg-using-the-viewbox
    let scale = e.deltaY / 1000;
    scale = Math.abs(scale) < .1 ? .1 * e.deltaY / Math.abs(e.deltaY) : scale;


    let pt = new DOMPoint(e.clientX, e.clientY);
    pt = pt.matrixTransform(svgInstance.getScreenCTM().inverse());

    let [x, y, width, height] = svgInstance.getAttribute('viewBox').split(' ').map(Number);

    let [xPropW, yPropH] = [(pt.x - x) / width, (pt.y - y) / height];

    let [width2, height2] = [width + width * scale, height + height * scale];
    let x2 = pt.x - xPropW * width2;
    let y2 = pt.y - yPropH * height2;

    // Default viewBox sizes are 100 100
    zoomFactor = 100 / width2;

    svgInstance.setAttribute('viewBox', `${x2} ${y2} ${width2} ${height2}`);
  };

  const SVGHandleDrag = (el) => {
    // https://www.w3schools.com/howto/howto_js_draggable.asp

    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let startDistance = 0;

    if ('ontouchstart' in window)
      el.ontouchstart = dragTabletDown;
    else
      el.onmousedown = dragMouseDown;

    function getDistance(touches) {
      const [touch1, touch2] = touches;
      const dx = touch1.clientX - touch2.clientX;
      const dy = touch1.clientY - touch2.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    }

    function getCenter(touches) {
      const [touch1, touch2] = touches;
      const x = (touch1.clientX + touch2.clientX) / 2;
      const y = (touch1.clientY + touch2.clientY) / 2;
      return [x, y];
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    function dragTabletDown(e) {
      e = e || window.event;
      e.preventDefault();
      // Zoom
      if (event.touches.length === 2) {
        startDistance = getDistance(event.touches);
        document.ontouchend = closeTabletEvent;
        document.ontouchmove = elementZoom;
      }
      // Swipe
      else if (event.touches.length === 1) {
        pos3 = e.touches[0].clientX;
        pos4 = e.touches[0].clientY;
        document.ontouchend = closeTabletEvent;
        document.ontouchmove = elementSwipe;
      }
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      let [x, y, width, height] = svgInstance.getAttribute('viewBox').split(' ').map(Number);

      const moveRatio = height / 300;
      let x2 = x + pos1 * moveRatio;
      let y2 = y + pos2 * moveRatio;
      svgInstance.setAttribute('viewBox', `${x2} ${y2} ${width} ${height}`);
    }

    function elementSwipe(e) {
      e = e || window.event;
      e.preventDefault();
      pos1 = pos3 - e.touches[0].clientX;
      pos2 = pos4 - e.touches[0].clientY;
      pos3 = e.touches[0].clientX;
      pos4 = e.touches[0].clientY;

      let [x, y, width, height] = svgInstance.getAttribute('viewBox').split(' ').map(Number);

      const moveRatio = height / 300;
      let x2 = x + pos1 * moveRatio;
      let y2 = y + pos2 * moveRatio;
      svgInstance.setAttribute('viewBox', `${x2} ${y2} ${width} ${height}`);
    }

    function elementZoom(e) {
      e = e || window.event;
      e.preventDefault();
      if (event.touches.length === 2) {
        const currentDistance = getDistance(event.touches);
        const [currentCenterX, currentCenterY] = getCenter(event.touches);

        let pt = new DOMPoint(currentCenterX, currentCenterY);
        pt = pt.matrixTransform(svgInstance.getScreenCTM().inverse());
        const scale = (startDistance / currentDistance) - 1;

        let [x, y, width, height] = svgInstance.getAttribute('viewBox').split(' ').map(Number);

        let [xPropW, yPropH] = [(pt.x - x) / width, (pt.y - y) / height];

        let [width2, height2] = [width + width * scale, height + height * scale];
        let x2 = pt.x - xPropW * width2;
        let y2 = pt.y - yPropH * height2;

        // Default viewBox sizes are 100 100
        zoomFactor = 100 / width2;

        svgInstance.setAttribute('viewBox', `${x2} ${y2} ${width2} ${height2}`);

        startDistance = currentDistance;
      }
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }

    function closeTabletEvent() {
      document.ontouchend = null;
      document.ontouchmove = null;
    }
  }

  $: if (svgInstance) {
      SVGHandleDrag(svgInstance);
  };
  $: highlightedBlock, cachedRows, GenerateMapCards();


</script>

<div id="processmap_maparea">
  <svg
    id="processmap_maparea_chartcontent"
    viewBox="0 0 100 100"
    bind:this={svgInstance}
    on:wheel={GeneralZoom}
  >
  <defs>
    {#each ["blue", "magenta", "cyan"] as color}
      <marker
        id="map_link_{color}"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="2"
        markerHeight="2"
        orient="auto-start-reverse"
        fill={color}
        >
        <path d="M 0 0 L 10 5 L 0 10 z" />
      </marker>
    {/each}
  </defs>
  <pattern
    id="stripeMap"
    patternUnits="userSpaceOnUse"
    width="1.5"
    height="1.5"
    patternTransform="rotate(45)"
  >
    <line
      x1="0"
      y="0"
      x2="0"
      y2="1.5"
      stroke="#82828241"
      stroke-width="0.5"
    />
  </pattern>

    {#if displayMap === false}
      <text x="50" y="50" text-anchor="middle">{errorMessage}</text>
    {:else}
      {#each Object.values(mapCards) as card}
        <MapCard
          Card={card}
          Zoom={zoomFactor}
          Style={currentStyle}
          JumpToDateCallback={JumpToDateCallback}
        />
      {/each}

      {#each mapLinks as link}
        <MapConnection
          Connection={link}
        />
      {/each}
    {/if}
  </svg>
  <div id="processmap_maparea_uppergui">
    {translate("frontend:ganttChart.mapDisplay.zoom", null, {zoom: parseInt(zoomFactor * 100, 10)})}
  </div>
</div>

<style>
  svg#processmap_maparea_chartcontent {
    width: 100%;
    height: 100%;
    background-color: white;
    grid-area: 1/1;
  }

  div#processmap_maparea {
    height: 100%;
    width: 100%;
    display: grid;
  }

  div#processmap_maparea_uppergui {
    height: 100%;
    width: 100%;
    pointer-events: none;
    grid-area: 1/1;
  }
</style>
