<script>
  export let dialog
  export let onClose
  export let title = "設定"

  function onClickDialog(event) {
    if (event.target === dialog) {
      onClose()
    }
  }

  /**
   * This value should match that of MenuBar
   */
  let topControlsGap = "36px"
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<dialog bind:this={dialog} on:click={onClickDialog}>
  <div class="inner">
    <div class="top-controls" style="--gap: {topControlsGap}">
      <button class="close-button" on:click={onClose}>＜</button>
      <span class="title">{title}</span>
    </div>
    <div class="contents">
      <slot />
    </div>
  </div>
</dialog>

<style>
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  dialog {
    overflow: visible;
    top: 50px;
    max-width: none;
    width: auto;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
  }
  .inner {
    left: 0;
    height: 100%;
    width: 300px;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  }
  .top-controls {
    line-height: 1.5;
    padding: 8px var(--gap);
    display: flex;
    gap: var(--gap);
  }
  .close-button {
    font-weight: bold;
    font-size: 2rem;
    color: darkgrey;
  }
  .title {
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .contents {
    padding: 1rem;
  }
</style>
