<script>
  import { onMount } from "svelte";

  export let GanttStore

  export let RowIndex;
  export let Row;
  export let Color;
  export let IsNull;
  export let Height;
  export let OriginY;
  export let IsOperation;
  export let IsExtraColumn = false;
  export let displayedRows;

  let ganttChartType;
  let unFoldedSet;

  $: imgSrc = unFoldedSet?.has(RowIndex) ? imgSrc = "/assets/rss_up.svg" : "/assets/rss_down.svg";

  $: y = `calc(${OriginY + 50 / displayedRows - 1.5}%)`;

  GanttStore.GanttChartType.subscribe((newType) => (ganttChartType = newType));
  GanttStore.RowsUnfoldedForOrderGantt.subscribe((st) => {
    unFoldedSet = st;
  });

  const onClickFoldButton = () => {
    GanttStore.RowsUnfoldedForOrderGantt.update((st) => {
      if (st.has(RowIndex)) {
        st.delete(RowIndex);
      } else {
        st.add(RowIndex);
      }
      return st;
    });
  };

  onMount(async () => {});
</script>

{#if IsNull === false}
  <rect
    class="gantt_tablearea_resourcesvg_row"
    fill={Color}
    height="{Height}%"
    width="100%"
    x="0"
    y="{OriginY}%"
  >
    <title>
      {Row}
    </title>
  </rect>

  <foreignObject
    x="30px"
    y="{OriginY}%"
    height="{Height}%"
    width="100%"
  >
    <div style="
      display: table;
      height:100%;
      width:calc(100% - 30px);
      overflow: hidden;
    ">
      <div style="
        display: table-cell;
        height:100%;
        width:100%;
        overflow: hidden;
        vertical-align: middle;
        text-align:start;
        color:black;
        font-size:1em;
      ">
        {Row}
      </div>
    </div>
  </foreignObject>

  {#if !IsExtraColumn && (ganttChartType == GanttStore.GanttChartTypes.OrderGantt || ganttChartType == GanttStore.GanttChartTypes.PeggedOrderGantt) && !IsOperation}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <image
      height="3%"
      x="6px"
      {y}
      xlink:href={imgSrc}
      clip-path="url(#circleView)"
      on:click={onClickFoldButton}
      style="cursor: pointer;"
    />
  {/if}

  <line
    x1="0"
    x2="100%"
    y1="{OriginY + Height}%"
    y2="{OriginY + Height}%"
    stroke="#888888"
    stroke-width="2"
  />
{/if}

<style>
</style>
