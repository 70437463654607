<script>
  import GanttHelper from './GanttHelper.js';

  /*
  ** {
  **  RowStart: Int
  **  RowEnd: Int
  **  SubConnection: Bool
  **  StartStamp: Date
  **  EndStamp: Date
  **  HTakenResourceCount : Double
  **  HVerticalPosition : Double
  **  HNecessarySpace : Double
  **  TTakenResourceCount : Double
  **  TVerticalPosition : Double
  **  TNecessarySpace : Double
  ** }
  */
  export let Connection;
  export let RowHeight;
  export let AdjustStamp;
  export let StartDate;
  export let EndDate;

  const BASE_BLOCK_HEIGHT = 0.7;

  const IsRowHeightBounded = (row) => RowHeight * (0.5 + row) >= 0 && RowHeight * (0.5 + row) <= 100;
  const IsStampVisible = (stamp) => stamp >= StartDate && stamp <= EndDate;

  const IsLineVisible = (r, s) => IsRowHeightBounded(r);// && IsStampVisible(s);
  const IsLineVisibleEx = (s) => IsStampVisible(s);

  const GetLineStyle = (sub, peg, white, exwidth) => {
    let baseColor = "blue";
    if (peg === "E") baseColor = "magenta";
    if (peg === "T") baseColor = "cyan";

    const stroke = (white) ? "white" : baseColor;
    const strokeWidth = 3 + exwidth;
    return `stroke:${stroke};stroke-width:${strokeWidth}`;
  }

  const GetMarker = (sub, peg, white, exwidth, angle) => {
    let baseColor = "blue";
    if (peg === "E") baseColor = "magenta";
    if (peg === "T") baseColor = "cyan";

    const stroke = (white) ? "white" : baseColor;
    return `url(#triangle_${stroke}_${angle})`;
  }

  const CalculateStampXUnclamped = (stamp) => 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime());
  const CalculateStampX = (stamp) => Math.max(0, Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime())));
  const CalculateStampXDifferential = (stamp1, stamp2) => CalculateStampX(stamp2) - CalculateStampX(stamp1);

  const GetMidPointX = (s, e) => {
    const x1 = CalculateStampXUnclamped(s);
    const x2 = CalculateStampXUnclamped(e);
    return (x1 + x2) / 2;
  }
  const GetMidPointXClamped = (s, e) => {
    return Math.max(0, Math.min(100, GetMidPointX(s, e)));
  }
  const GetDatesAverage = (d1, d2) => {
    const d1msecs = new Date(d1).getTime();
    const d2msecs = new Date(d2).getTime();
    const avgTime = (d1msecs + d2msecs) / 2;
    return new Date(avgTime);
  }

  const ClampedRowPercent = (r, rh) => {
    const rp = rh * (0.5 + r);
    return Math.max(0, Math.min(100, rp));
  }

  const ClampedRowPercentH = (r, rh) => {
    const rp = rh * ((1 - BASE_BLOCK_HEIGHT) / 2
      + BASE_BLOCK_HEIGHT * (Connection.HVerticalPosition / Connection.HNecessarySpace)
      + BASE_BLOCK_HEIGHT * (Connection.HTakenResourceCount / Connection.HNecessarySpace) / 2
      + r);
    return Math.max(0, Math.min(100, rp));
  }

  const ClampedRowPercentHUp = (r, rh) => {
    const rp = rh * ((1 - BASE_BLOCK_HEIGHT) / 2
      + BASE_BLOCK_HEIGHT * (Connection.HVerticalPosition / Connection.HNecessarySpace)
      + r);
    return Math.max(0, Math.min(100, rp));
  }

  const ClampedRowPercentHDown = (r, rh) => {
    const rp = rh * ((1 - BASE_BLOCK_HEIGHT) / 2
      + BASE_BLOCK_HEIGHT * (Connection.HVerticalPosition / Connection.HNecessarySpace)
      + BASE_BLOCK_HEIGHT * (Connection.HTakenResourceCount / Connection.HNecessarySpace)
      + r);
    return Math.max(0, Math.min(100, rp));
  }

  const ClampedRowPercentT = (r, rh) => {
    const rp = rh * ((1 - BASE_BLOCK_HEIGHT) / 2
      + BASE_BLOCK_HEIGHT * (Connection.TVerticalPosition / Connection.TNecessarySpace)
      + BASE_BLOCK_HEIGHT * (Connection.TTakenResourceCount / Connection.TNecessarySpace) / 2
      + r);
    return Math.max(0, Math.min(100, rp));
  }

  const ClampedRowPercentTUp = (r, rh) => {
    const rp = rh * ((1 - BASE_BLOCK_HEIGHT) / 2
      + BASE_BLOCK_HEIGHT * (Connection.TVerticalPosition / Connection.TNecessarySpace)
      + r);
    return Math.max(0, Math.min(100, rp));
  }

  const ClampedRowPercentTDown = (r, rh) => {
    const rp = rh * ((1 - BASE_BLOCK_HEIGHT) / 2
      + BASE_BLOCK_HEIGHT * (Connection.TVerticalPosition / Connection.TNecessarySpace)
      + BASE_BLOCK_HEIGHT * (Connection.TTakenResourceCount / Connection.TNecessarySpace)
      + r);
    return Math.max(0, Math.min(100, rp));
  }

</script>

<!-- Straight line if same resource -->
{#if IsLineVisibleEx(Connection.StartStamp) && (Connection.RowStart == Connection.RowEnd)}
  <line
    x1="{CalculateStampX(Connection.StartStamp)}%"
    x2="{CalculateStampX(Connection.EndStamp)}%"
    y1="{ClampedRowPercentH(Connection.RowStart, RowHeight)}%"
    y2="{ClampedRowPercentT(Connection.RowEnd, RowHeight)}%"
    style={GetLineStyle(Connection.SubConnection, Connection.PegType, Connection.WhiteLink, Connection.ExtraWidth)}
    stroke-linecap="square"
    stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
  />
{/if}

{#if Connection.RowStart < Connection.RowEnd}
  {#if IsLineVisibleEx(Connection.StartStamp)}
    <line
      x1="{CalculateStampX(Connection.StartStamp)}%"
      x2="{CalculateStampX(Connection.StartStamp)}%"
      y1="{ClampedRowPercentHDown(Connection.RowStart, RowHeight)}%"
      y2="{RowHeight * (Connection.RowStart + 1)}%"
      style={GetLineStyle(Connection.SubConnection, Connection.PegType, Connection.WhiteLink, Connection.ExtraWidth)}
      stroke-linecap="square"
      stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
      marker-start={GetMarker(
        Connection.SubConnection,
        Connection.PegType,
        Connection.WhiteLink,
        Connection.ExtraWidth,
        (Connection.HeadMethod === "E") ? "180deg" : "270deg"
        )}
    />
  {/if}

  {#if IsLineVisible(Connection.RowStart, Connection.StartStamp)}
    <line
      x1="{CalculateStampX(Connection.StartStamp)}%"
      x2="{CalculateStampX(Connection.EndStamp)}%"
      y1="{RowHeight * (Connection.RowStart + 1)}%"
      y2="{RowHeight * (Connection.RowStart + 1)}%"
      style={GetLineStyle(Connection.SubConnection, Connection.PegType, Connection.WhiteLink, Connection.ExtraWidth)}
      stroke-linecap="square"
      stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
    />
  {/if}

  {#if IsLineVisibleEx(Connection.EndStamp)}
    <line
      x1="{CalculateStampX(Connection.EndStamp)}%"
      x2="{CalculateStampX(Connection.EndStamp)}%"
      y1="{RowHeight * (Connection.RowStart + 1)}%"
      y2="{ClampedRowPercentTUp(Connection.RowEnd, RowHeight)}%"
      style={GetLineStyle(Connection.SubConnection, Connection.PegType, Connection.WhiteLink, Connection.ExtraWidth)}
      stroke-linecap="square"
      stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
      marker-end={GetMarker(
        Connection.SubConnection,
        Connection.PegType,
        Connection.WhiteLink,
        Connection.ExtraWidth,
        (Connection.TailMethod === "E") ? "90deg" : "0deg"
        )}
    />
  {/if}

{/if}

{#if Connection.RowStart > Connection.RowEnd}
  {#if IsLineVisibleEx(Connection.StartStamp)}
    <line
      x1="{CalculateStampX(Connection.StartStamp)}%"
      x2="{CalculateStampX(Connection.StartStamp)}%"
      y1="{ClampedRowPercentHUp(Connection.RowStart, RowHeight)}%"
      y2="{RowHeight * Connection.RowStart}%"
      style={GetLineStyle(Connection.SubConnection, Connection.PegType, Connection.WhiteLink, Connection.ExtraWidth)}
      stroke-linecap="square"
      stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
      marker-start={GetMarker(
        Connection.SubConnection,
        Connection.PegType,
        Connection.WhiteLink,
        Connection.ExtraWidth,
        (Connection.HeadMethod === "E") ? "90deg" : "0deg"
        )}
    />
  {/if}

  {#if IsLineVisible(Connection.RowStart, Connection.StartStamp)}
    <line
      x1="{CalculateStampX(Connection.StartStamp)}%"
      x2="{CalculateStampX(Connection.EndStamp)}%"
      y1="{RowHeight * Connection.RowStart}%"
      y2="{RowHeight * Connection.RowStart}%"
      style={GetLineStyle(Connection.SubConnection, Connection.PegType, Connection.WhiteLink, Connection.ExtraWidth)}
      stroke-linecap="square"
      stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
    />
  {/if}

  {#if IsLineVisibleEx(Connection.EndStamp)}
    <line
      x1="{CalculateStampX(Connection.EndStamp)}%"
      x2="{CalculateStampX(Connection.EndStamp)}%"
      y1="{RowHeight * Connection.RowStart}%"
      y2="{ClampedRowPercentTDown(Connection.RowEnd, RowHeight)}%"
      style={GetLineStyle(Connection.SubConnection, Connection.PegType, Connection.WhiteLink, Connection.ExtraWidth)}
      stroke-linecap="square"
      stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
      marker-end={GetMarker(
        Connection.SubConnection,
        Connection.PegType,
        Connection.WhiteLink,
        Connection.ExtraWidth,
        (Connection.TailMethod === "E") ? "180deg" : "270deg"
        )}
    />
  {/if}

{/if}

<style>

</style>
