<script>
  import GanttHelper from './GanttHelper.js';

  export let Timestamps;
  export let Color;
  export let StartYMain;
  export let HeightYMain;
  export let StartYSub;
  export let HeightYSub;
  export let StartDate;
  export let EndDate;
  export let AdjustStamp;
  export let DisplayRatio = 1;
  export let GanttChartType;
  export let GanttStore = null;
  export let IntervalQtyDisplayPropertyPack = null;
  export let IntervalQty = null;
  export let AccIntervalQty = null;

  // Optimization parameters
  export let HiddenBarBorders;
  export let SimplifiedShape;

  const CalculateStampX = (stamp) => Math.max(0, Math.min(100, 100 * GanttHelper._gantHelper_scale(AdjustStamp(stamp).getTime(), EndDate.getTime(), StartDate.getTime())));
  const CalculateStampXDifferential = (stamp1, stamp2) => CalculateStampX(stamp2) - CalculateStampX(stamp1);
  const DarkenColor = (color) => GanttHelper._gantHelper_darkenColor(GanttHelper._gantHelper_rgbToHex(color), 30);

  const CalculateStampXDisplayRatioBalanced = (stamps, displayRatio) => {
    function _getInterval(K, D) {
        let totalRange = 0;
        for (let i = 0; i < K.length; i += 2) {
            totalRange += K[i + 1] - K[i];
        }

        let targetValue = D * totalRange;

        let cumulativeRange = 0;
        for (let i = 0; i < K.length; i += 2) {
            let intervalStart = K[i];
            let intervalEnd = K[i + 1];
            let intervalRange = intervalEnd - intervalStart;

            if (targetValue <= cumulativeRange + intervalRange) {
                let intervalPercentage = (targetValue - cumulativeRange) / intervalRange;
                const input = intervalStart + intervalPercentage * intervalRange;
                return (input - K[0]) / (K[K.length - 1] - K[0])
            }

            cumulativeRange += intervalRange;
        }
        return 1;
    }

    const _actualRatio = _getInterval(stamps.map((e) => e.getTime()), displayRatio);
    const _diff = (stamps[stamps.length - 1].getTime() - stamps[0].getTime()) * _actualRatio;
    const _endDate = new Date(stamps[0].getTime() + _diff);
    return CalculateStampXDifferential(stamps[0], _endDate);
  }

</script>

{#if SimplifiedShape === true}
  <rect
    x="{CalculateStampX(Timestamps[0])}%"
    y="{StartYMain}%"
    height="{HeightYMain}%"
    width="{CalculateStampXDisplayRatioBalanced(Timestamps, DisplayRatio)}%"
    fill={Color}
    stroke={(HiddenBarBorders === true) ? "none" : DarkenColor(Color)}
    stroke-width={(HiddenBarBorders === true) ? 0 : 1}
  />

{:else}
  <rect
    x="{CalculateStampX(Timestamps[0])}%"
    y="{StartYSub}%"
    height="{HeightYSub}%"
    width="{CalculateStampXDifferential(Timestamps[0],Timestamps[Timestamps.length-1])}%"
    fill={Color}
    stroke={(HiddenBarBorders === true) ? "none" : DarkenColor(Color)}
    stroke-width={(HiddenBarBorders === true) ? 0 : 1}
  />
  {#each Timestamps as timestamp, index}
    {#if index % 2 === 0}
      <rect
        x="{CalculateStampX(Timestamps[index])}%"
        y="{StartYMain}%"
        height="{HeightYMain}%"
        width="{CalculateStampXDifferential(Timestamps[index],Timestamps[index+1])}%"
        fill={Color}
        stroke={(HiddenBarBorders === true) ? "none" : DarkenColor(Color)}
        stroke-width={(HiddenBarBorders === true) ? 0 : 1}
      />
    {/if}
  {/each}

  {#if HiddenBarBorders === false}
    <rect
      x="{CalculateStampX(Timestamps[0])}%"
      y="{StartYSub}%"
      height="{HeightYSub}%"
      width="{CalculateStampXDifferential(Timestamps[0],Timestamps[Timestamps.length-1])}%"
      fill={Color}
    />
  {/if}
{/if}

{#if GanttChartType === GanttStore?.GanttChartTypes.CustomizedResGantt && IntervalQtyDisplayPropertyPack}
  <svg
    x="{CalculateStampX(Timestamps[0])}%"
    y="{IntervalQtyDisplayPropertyPack.OriginY +
      (IntervalQtyDisplayPropertyPack.trc / IntervalQtyDisplayPropertyPack.ns <
      1
        ? IntervalQtyDisplayPropertyPack.Height *
          IntervalQtyDisplayPropertyPack.BASE_BLOCK_OFF
        : IntervalQtyDisplayPropertyPack.Height *
          (IntervalQtyDisplayPropertyPack.BASE_BLOCK_OFF + 0.02)) +
      (IntervalQtyDisplayPropertyPack.vp / IntervalQtyDisplayPropertyPack.ns) *
        IntervalQtyDisplayPropertyPack.Height *
        IntervalQtyDisplayPropertyPack.BASE_BLOCK_HEIGHT}%"
    width="100%"
    height="{IntervalQtyDisplayPropertyPack.Height *
      (IntervalQtyDisplayPropertyPack.BASE_BLOCK_HEIGHT -
        IntervalQtyDisplayPropertyPack.RESULT_BAR_THICKNESS) *
      (IntervalQtyDisplayPropertyPack.trc /
        IntervalQtyDisplayPropertyPack.ns)}%"
    style="overflow:none;"
  >
    <text
      fill="black"
      font-size="16px"
      x="0"
      y="1px"
      text-anchor="start"
      dominant-baseline="hanging"
      pointer-events="none"
    >
      <!-- {Number(IntervalQty.curr).toFixed(2)}/{Number(IntervalQty.acc).toFixed(2)} -->
      <!-- {Number(IntervalQty).toFixed(2)} \n {Number(AccIntervalQty).toFixed(2)} -->
      <tspan
        x="0"
        dy="14px"
      >
        {Number(IntervalQty).toFixed(2)}
      </tspan>
      <tspan
        x="0"
        dy="18px"
      >
        {Number(AccIntervalQty).toFixed(2)}
      </tspan>

    </text>
  </svg>
{/if}

<style>
</style>
