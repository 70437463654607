/**
 *
 * @param {() => any} func  value getter function that may cause TypeError
 * @param {any} defaultValue  default value that will be used when "func" throws TypeError
 * @returns {any}
 */
export function getValueWrapper(func, defaultValue) {
  try {
    return func() ?? defaultValue
  } catch (e) {
    if (e instanceof TypeError) return defaultValue
    throw e
  }
}

