<script>
  export let Row;
  export let Color;
  export let IsNull;
  export let Height;
  export let OriginY;
</script>

{#if IsNull === false}
  <rect
    class="gantt_tablearea_resourcesvg_row"
    fill={Color}
    height="{Height}%"
    width="100%"
    x="0"
    y="{OriginY}%"
  >
    <title>
      {Row}
    </title>
  </rect>

  <foreignObject
    x="0"
    y="{OriginY}%"
    height="{Height}%"
    width="100%"
  >
    <div style="
      display: table;
      height:100%;
      width:100%;
      overflow: hidden;
    ">
      <div style="
        padding: 0.5rem;
        display: table-cell;
        height:100%;
        width:100%;
        overflow: hidden;
        vertical-align: middle;
        text-align:center;
        color:black;
        font-size:1em;
      ">
        {Row}
      </div>
    </div>
  </foreignObject>
  <line
    x1="0"
    x2="100%"
    y1="{OriginY + Height}%"
    y2="{OriginY + Height}%"
    stroke="#888888"
    stroke-width="2"
  />
{/if}
