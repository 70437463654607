import { useQuery } from "@sveltestack/svelte-query"
import { fetchPSIItems, FetchError } from "../../../utils/getData.js";

/**
 * svelte query hook for PSI items data
 * @param {string} projectId
 * @param fetchErrorHandler
 */
export function usePSIGenericDataQuery(projectId, fetchErrorHandler) {
  return useQuery(`/api/projects/${projectId}/PSI/generated/psi.json`, () => fetchPSIItems(projectId), {
    onError: err => {
      console.log(err)
      if (err instanceof FetchError) {
        fetchErrorHandler(err.error)
      }
    },
    retry: false,
  })
}
