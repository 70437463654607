<script>
  import {Route} from "svelte-navigator"
  import AdminProjectsPage from "./admin/projects.svelte"
  import AdminUsersPage from "./admin/users.svelte"
  import IndexPage from "./index.svelte"
  import LoginPage from "./login.svelte"
  import ProjectsProjectAdminPage from "./projects/[project]/admin.svelte"
  import ProjectsProjectCustomizedResGanttPage from "./projects/[project]/CustomizedResGantt.svelte"
  import ProjectsProjectDashboardPage from "./projects/[project]/Dashboard.svelte"
  import ProjectsProjectDispatchingViewIndexPage from "./projects/[project]/DispatchingView/index.svelte"
  import ProjectsProjectDispatchingViewCodePage from "./projects/[project]/DispatchingView/[code].svelte"
  import ProjectsProjectDispatchingView_Profile1IndexPage from "./projects/[project]/DispatchingView_Profile1/index.svelte"
  import ProjectsProjectDispatchingView_Profile1CodePage from "./projects/[project]/DispatchingView_Profile1/[code].svelte"
  import ProjectsProjectExpectedActualCmpPage from "./projects/[project]/ExpectedActualCmp.svelte"
  import ProjectsProjectIndexPage from "./projects/[project]/index.svelte"
  import ProjectsProjectIntegratedMasterIndexPage from "./projects/[project]/IntegratedMaster/index.svelte"
  import ProjectsProjectLoadGraphPage from "./projects/[project]/LoadGraph.svelte"
  import ProjectsProjectLoadGraph_MPSPage from "./projects/[project]/LoadGraph_MPS.svelte"
  import ProjectsProjectMHIResGanttPage from "./projects/[project]/MHIResGantt.svelte"
  import ProjectsProjectOperationTableIndexPage from "./projects/[project]/OperationTable/index.svelte"
  import ProjectsProjectOperationTableCodePage from "./projects/[project]/OperationTable/[code].svelte"
  import ProjectsProjectOrderGanttPage from "./projects/[project]/OrderGantt.svelte"
  import ProjectsProjectOrderLeadTimePage from "./projects/[project]/OrderLeadTime.svelte"
  import ProjectsProjectProductionOrderPage from "./projects/[project]/ProductionOrder.svelte"
  import ProjectsProjectPSIGraphPage from "./projects/[project]/PSIGraph.svelte"
  import ProjectsProjectPSITablePage from "./projects/[project]/PSITable.svelte"
  import ProjectsProjectResGanttIndexPage from "./projects/[project]/ResGantt/index.svelte"
  import ProjectsProjectResultGraphPage from "./projects/[project]/ResultGraph.svelte"
  import ProjectsProjectSimpleOrderGanttPage from "./projects/[project]/SimpleOrderGantt.svelte"
  import ProjectsProjectSimpleResGanttPage from "./projects/[project]/SimpleResGantt.svelte"
  import SignupPage from "./signup.svelte"
  import ProjectsProjectPeggedOrderGanttPage from "./projects/[project]/PeggedOrderGantt.svelte"
  
</script>

<Route path="/" component={IndexPage}/>
<Route path="/:lang/" component={IndexPage}/>
<Route path="/:lang/admin/projects" component={AdminProjectsPage}/>
<Route path="/:lang/admin/users" component={AdminUsersPage}/>
<Route path="/:lang/login" component={LoginPage}/>
<Route path="/:lang/projects/:project" component={ProjectsProjectIndexPage}/>
<Route path="/:lang/projects/:project/CustomizedResGantt" component={ProjectsProjectCustomizedResGanttPage}/>
<Route path="/:lang/projects/:project/Dashboard" component={ProjectsProjectDashboardPage}/>
<Route path="/:lang/projects/:project/DispatchingView" component={ProjectsProjectDispatchingViewIndexPage}/>
<Route path="/:lang/projects/:project/DispatchingView/:code" component={ProjectsProjectDispatchingViewCodePage}/>
<Route path="/:lang/projects/:project/DispatchingView_Profile1" component={ProjectsProjectDispatchingView_Profile1IndexPage}/>
<Route path="/:lang/projects/:project/DispatchingView_Profile1/:code" component={ProjectsProjectDispatchingView_Profile1CodePage}/>
<Route path="/:lang/projects/:project/ExpectedActualCmp" component={ProjectsProjectExpectedActualCmpPage}/>
<Route path="/:lang/projects/:project/IntegratedMaster" component={ProjectsProjectIntegratedMasterIndexPage}/>
<Route path="/:lang/projects/:project/LoadGraph" component={ProjectsProjectLoadGraphPage}/>
<Route path="/:lang/projects/:project/LoadGraph_MPS" component={ProjectsProjectLoadGraph_MPSPage}/>
<Route path="/:lang/projects/:project/MHIResGantt" component={ProjectsProjectMHIResGanttPage}/>
<Route path="/:lang/projects/:project/OperationTable" component={ProjectsProjectOperationTableIndexPage}/>
<Route path="/:lang/projects/:project/OperationTable/:code" component={ProjectsProjectOperationTableCodePage}/>
<Route path="/:lang/projects/:project/OrderGantt" component={ProjectsProjectOrderGanttPage}/>
<Route path="/:lang/projects/:project/OrderLeadTime" component={ProjectsProjectOrderLeadTimePage}/>
<Route path="/:lang/projects/:project/PSIGraph" component={ProjectsProjectPSIGraphPage}/>
<Route path="/:lang/projects/:project/PSITable" component={ProjectsProjectPSITablePage}/>
<Route path="/:lang/projects/:project/ProductionOrder" component={ProjectsProjectProductionOrderPage}/>
<Route path="/:lang/projects/:project/ResGantt" component={ProjectsProjectResGanttIndexPage}/>
<Route path="/:lang/projects/:project/ResultGraph" component={ProjectsProjectResultGraphPage}/>
<Route path="/:lang/projects/:project/SimpleOrderGantt" component={ProjectsProjectSimpleOrderGanttPage}/>
<Route path="/:lang/projects/:project/SimpleResGantt" component={ProjectsProjectSimpleResGanttPage}/>
<Route path="/:lang/projects/:project/admin" component={ProjectsProjectAdminPage}/>
<Route path="/:lang/signup" component={SignupPage}/>
<Route path="/admin/projects" component={AdminProjectsPage}/>
<Route path="/admin/users" component={AdminUsersPage}/>
<Route path="/login" component={LoginPage}/>
<Route path="/projects/:project" component={ProjectsProjectIndexPage}/>
<Route path="/projects/:project/CustomizedResGantt" component={ProjectsProjectCustomizedResGanttPage}/>
<Route path="/projects/:project/Dashboard" component={ProjectsProjectDashboardPage}/>
<Route path="/projects/:project/DispatchingView" component={ProjectsProjectDispatchingViewIndexPage}/>
<Route path="/projects/:project/DispatchingView/:code" component={ProjectsProjectDispatchingViewCodePage}/>
<Route path="/projects/:project/DispatchingView_Profile1" component={ProjectsProjectDispatchingView_Profile1IndexPage}/>
<Route path="/projects/:project/DispatchingView_Profile1/:code" component={ProjectsProjectDispatchingView_Profile1CodePage}/>
<Route path="/projects/:project/ExpectedActualCmp" component={ProjectsProjectExpectedActualCmpPage}/>
<Route path="/projects/:project/IntegratedMaster" component={ProjectsProjectIntegratedMasterIndexPage}/>
<Route path="/projects/:project/LoadGraph" component={ProjectsProjectLoadGraphPage}/>
<Route path="/projects/:project/LoadGraph_MPS" component={ProjectsProjectLoadGraph_MPSPage}/>
<Route path="/projects/:project/MHIResGantt" component={ProjectsProjectMHIResGanttPage}/>
<Route path="/projects/:project/OperationTable" component={ProjectsProjectOperationTableIndexPage}/>
<Route path="/projects/:project/OperationTable/:code" component={ProjectsProjectOperationTableCodePage}/>
<Route path="/projects/:project/OrderGantt" component={ProjectsProjectOrderGanttPage}/>
<Route path="/projects/:project/OrderLeadTime" component={ProjectsProjectOrderLeadTimePage}/>
<Route path="/projects/:project/PSIGraph" component={ProjectsProjectPSIGraphPage}/>
<Route path="/projects/:project/PSITable" component={ProjectsProjectPSITablePage}/>
<Route path="/projects/:project/ProductionOrder" component={ProjectsProjectProductionOrderPage}/>
<Route path="/projects/:project/ResGantt" component={ProjectsProjectResGanttIndexPage}/>
<Route path="/projects/:project/ResultGraph" component={ProjectsProjectResultGraphPage}/>
<Route path="/projects/:project/SimpleOrderGantt" component={ProjectsProjectSimpleOrderGanttPage}/>
<Route path="/projects/:project/SimpleResGantt" component={ProjectsProjectSimpleResGanttPage}/>
<Route path="/projects/:project/CustomizedResGantt" component={ProjectsProjectCustomizedResGanttPage}/>
<Route path="/projects/:project/PeggedOrderGantt" component={ProjectsProjectPeggedOrderGanttPage}/>
<Route path="/projects/:project/admin" component={ProjectsProjectAdminPage}/>
<Route path="/signup" component={SignupPage}/>