<script>
  import { useParams } from "svelte-navigator";
  import { useMyProjectPermission } from "../../../hooks/useMyProjectPermission.js";
  import { useProject } from "../../../hooks/useProject.js";
  import { getContext } from "svelte";
  import PrimaryBorderedButton from "../../../components/button/generic/PrimaryBorderedButton.svelte";
  import { usePageContext } from "../../../components/pages/pageContext.js";
  import { fade } from "svelte/transition";
  import Loading from "../../../components/pages/Loading.svelte";
  import { translate } from "../../../i18n/i18next.js";
  import { navigateI18n } from "../../../i18n/navigate.js";
  import Alert from "../../../components/Alerts/Alert.svelte";
  import { useEnabledExperimentalViews } from "../../../hooks/useEnabledExperimentalViews.js";
  import DispatchingViewViewCard from "../../../components/views/cards/DispatchingViewViewCard.svelte";
  import ResGanttViewCard from "../../../components/views/cards/ResGanttViewCard.svelte";
  import OrderGanttViewCard from "../../../components/views/cards/OrderGanttViewCard.svelte";
  import OrderLeadTimeViewCard from "../../../components/views/cards/OrderLeadTimeViewCard.svelte";
  import OperationTableViewCard from "../../../components/views/cards/OperationTableViewCard.svelte";
  import IntegratedMasterViewCard from "../../../components/views/cards/IntegratedMasterViewCard.svelte";
  import { useTimezone } from "../../../hooks/useTimezone.js";

  const params = useParams();
  const projectId = $params.project;
  const {getOffset} = useTimezone()
  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({ title: $project?.name ?? translate("generic.project") });

  const { handleFetchErrors } = getContext("fetchErrorHandler");
  const { permission } = useMyProjectPermission(projectId);
  const project = useProject(projectId, handleFetchErrors);
  const enabledExperimentalViews = useEnabledExperimentalViews()

  const views = {
    DispatchingView: {
      iconSrc: "/assets/icons/icon_dispatching_view_l.png",
      href: `/projects/${projectId}/DispatchingView`,
      name: translate("mySchedule.views.dispatchingView.name"),
    },
    DispatchingView_Profile1: {
      iconSrc: "/assets/icons/icon_dispatching_view_l.png",
      href: `/projects/${projectId}/DispatchingView`,
      name: translate("mySchedule.views.dispatchingView.name"),
    },
    ResGantt: {
      iconSrc: "/assets/icons/icon_resource_gantt_l.png",
      href: `/projects/${projectId}/ResGantt`,
      name: translate("mySchedule.views.resGantt.name"),
    },
    OrderGantt: {
      iconSrc: "/assets/icons/icon_order_gantt_l.png",
      href: `/projects/${projectId}/OrderGantt`,
      name: translate("mySchedule.views.orderGantt.name"),
    },
    OrderLeadTime: {
      iconSrc: "/assets/icons/icon_order_leadtime_l.png",
      href: `/projects/${projectId}/OrderLeadTime`,
      name: translate("mySchedule.views.orderLeadTime.name"),
    },
    IntegratedMaster: {
      iconSrc: "/assets/icons/icon_integrated_master_l.png",
      href: `/projects/${projectId}/IntegratedMaster`,
      name: translate("mySchedule.views.integratedMaster.name"),
    },
    Dashboard: {
      iconSrc: "/assets/icons/icon_dashboard_l.png",
      href: `/projects/${projectId}/Dashboard`,
      name: translate("mySchedule.views.dashboard.name"),
    },
    OperationTable: {
      iconSrc: "/assets/icons/icon_operation_table_l.png",
      href: `/projects/${projectId}/OperationTable`,
      name: translate("mySchedule.views.operationTable.name"),
    },
  };
  const experimentalViews = {
    LoadGraph: {
      iconSrc: "/assets/icons/icon_load_graph_l.png",
      href: `/projects/${projectId}/LoadGraph`,
      name: {
        ja: "負荷率グラフ",
      },
    },
    ProductionOrder: {
      iconSrc: "/assets/icons/icon_production_order_l.png",
      href: `/projects/${projectId}/ProductionOrder`,
      name: {
        ja: "生産指示書",
      },
    },
    ResultGraph: {
      iconSrc: "/assets/icons/icon_result_graph_l.png",
      href: `/projects/${projectId}/ResultGraph`,
      name: {
        ja: "実績グラフ",
      },
    },
    SimpleOrderGantt: {
      iconSrc: "/assets/icons/icon_simple_order_gantt_l.png",
      href: `/projects/${projectId}/SimpleOrderGantt`,
      name: {
        ja: "シンプル版オーダガントチャート",
      },
    },
    SimpleResGantt: {
      iconSrc: "/assets/icons/icon_simple_res_gantt_l.png",
      href: `/projects/${projectId}/SimpleResGantt`,
      name: {
        ja: "シンプル版資源ガントチャート",
      },
    },
    ExpectedActualCmp: {
      iconSrc: "/assets/icons/icon_expected_actual_cmp.png",
      href: `/projects/${projectId}/ExpectedActualCmp`,
      name: {
        ja: "工程毎予実比較",
      },
    },
    LoadGraph_MPS: {
      iconSrc: "/assets/icons/icon_load_graph_mps.png",
      href: `/projects/${projectId}/LoadGraph_MPS`,
      name: {
        ja: "負荷グラフ (MPS)",
      },
    },
    PSITable: {
      iconSrc: "/assets/icons/icon_psi_table.png",
      href: `/projects/${projectId}/PSITable`,
      name: {
        ja: "PSI表",
      },
    },
    PSIGraph: {
      iconSrc: "/assets/icons/icon_psi_graph.png",
      href: `/projects/${projectId}/PSIGraph`,
      name: {
        ja: "PSIグラフ",
      },
    },
  }

  let cloneElement = null;
  let cloneContainer = null; 

  function handleCardClick(event, href) {
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const screenCenterX = window.innerWidth / 2;
    const screenCenterY = window.innerHeight / 2;

    // Create a clone element to perform animation
    cloneElement = target.cloneNode(true);
    cloneElement.style.position = "fixed";
    cloneElement.style.top = `${rect.top}px`;
    cloneElement.style.left = `${rect.left}px`;
    cloneElement.style.width = `${rect.width}px`;
    cloneElement.style.height = `${rect.height}px`;
    cloneElement.style.zIndex = 1000;
    cloneElement.style.transition = "transform 0.4s, opacity 0.4s, filter 0.4s";
    cloneElement.classList.add('clone');

    cloneContainer = document.createElement('div');
    cloneContainer.appendChild(cloneElement);
    document.body.appendChild(cloneContainer);

    requestAnimationFrame(() => {
      const translateX = screenCenterX - centerX;
      const translateY = screenCenterY - centerY;
      cloneElement.style.transform = `translate(${translateX}px, ${translateY}px) scale(3)`;
      cloneElement.style.opacity = "0";
      cloneElement.style.filter = "blur(5px)";
    });

    setTimeout(() => {
      document.body.removeChild(cloneContainer);
      navigateI18n(href);
    }, 400);
  }

  const timezoneOffset = new Date().getTimezoneOffset() * -60
  $: projectTimezoneOffset = $getOffset($project?.timezone)

  $: isTimezoneDifferent = projectTimezoneOffset && timezoneOffset !== projectTimezoneOffset
</script>

<main>
  <div id="content-container">
    {#if isTimezoneDifferent}
      <Alert type="warn">
        <span style="font-size: 0.8rem">
          {translate("frontend:pages:projects.project.index.differentTimezoneWarning", null, {timezone: $project.timezone})}
        </span>
      </Alert>
    {/if}
    {#if $permission === "ProjectAdmin" || $permission === "ServerAdmin"}
      <div id="top-button-container">
        <PrimaryBorderedButton onClick={() => navigateI18n(`/projects/${projectId}/admin`)}>
          <span style="display: inline-flex; align-items: center; gap: 1rem;">
            <img src="/assets/icons/icon_setting_blue.png" alt="settings icon" class="button-icon"/>
            {translate("frontend:pages:projects.project.index.settingsButtonLabel")}
          </span>
        </PrimaryBorderedButton>
      </div>
    {/if}
    <div id="view-button-cards-container">
      {#if $project?.views}
        {#each $project.views as viewName}
          {#if viewName === "DispatchingView"}
            <DispatchingViewViewCard
              onClick={() => navigateI18n(`/projects/${projectId}/DispatchingView`)}
            />
          {/if}
          {#if viewName === "ResGantt"}
            <ResGanttViewCard
              onClick={() => navigateI18n(`/projects/${projectId}/ResGantt`)}
            />
          {/if}
          {#if viewName === "OrderGantt"}
            <OrderGanttViewCard
              onClick={() => navigateI18n(`/projects/${projectId}/OrderGantt`)}
            />
          {/if}
          {#if viewName === "OrderLeadTime"}
            <OrderLeadTimeViewCard
              onClick={() => navigateI18n(`/projects/${projectId}/OrderLeadTime`)}
            />
          {/if}
          {#if viewName === "OperationTable"}
            <OperationTableViewCard
              onClick={() => navigateI18n(`/projects/${projectId}/OperationTable`)}
            />
          {/if}
          {#if viewName === "IntegratedMaster"}
            <IntegratedMasterViewCard
              onClick={() => navigateI18n(`/projects/${projectId}/IntegratedMaster`)}
            />
          {/if}
          {#if experimentalViews[viewName] && $enabledExperimentalViews.includes(viewName)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="view-button-card" in:fade={{ duration: 300 }} on:click={(event) => handleCardClick(event, experimentalViews[viewName].href)}>
              <img src={experimentalViews[viewName].iconSrc} alt="icon" class="view-icon"/>
              <div class="view-button-card-title">
                {experimentalViews[viewName].name.ja}
              </div>
            </div>
          {/if}
        {:else}
          <div>{translate("frontend:pages:projects.project.index.noUiWarning")}</div>
        {/each}
      {:else}
        <Loading/>
      {/if}
    </div>
  </div>
</main>


<style>
  a {
    text-decoration: none;
    color: inherit;
  }
  main {
    height: 100%;
    background-color: white;
  }
  #top-button-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 1rem;
  }
  #content-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  .button-icon {
    height: 1rem;
    aspect-ratio: 1;
  }
  #view-button-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  .view-button-card {
    border: 1px solid darkgray;
    border-radius: 5px;
    width: 220px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  .view-icon {
    width: 100%;
    height: auto;
  }
  .view-button-card-title {
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-align: center;
    background-color: #f7f7f7;
    flex-grow: 1;
  }
</style>

