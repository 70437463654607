<script>
  import GanttHelper from '../GanttHelper.js';
  import { translate } from "../../../i18n/i18next.js";
  import { I18N_ResourceGanttTimelineFormatDate } from "../../../utils/i18n";

  export let Card;
  export let Zoom;
  export let Style;
  export let JumpToDateCallback = () => {};

  const GENERIC_ORDERS = {
    "S": translate("frontend:ganttChart.mapDisplay.orderSales"),
    "P": translate("frontend:ganttChart.mapDisplay.orderPurchase"),
    "I": translate("frontend:ganttChart.mapDisplay.orderInventory"),
    "J": translate("frontend:ganttChart.mapDisplay.orderInventory"),
    "U": translate("frontend:ganttChart.mapDisplay.orderUnofficial")
  };

  let CARD_HEIGHT = 30;
  let CARD_WIDTH = 80;

  $: FooterX = Card.Pos[0] + 0.2;
  $: FooterY = Card.Pos[1] + CARD_HEIGHT - 2.2;
  $: FooterWidth = CARD_WIDTH - 0.4;
  $: FooterHeight = 2;

  $: FormattedDate = I18N_ResourceGanttTimelineFormatDate(Card.Data._productionArr[0], Card.Data._productionArr[Card.Data._productionArr.length - 1]);
  $: BarString = Card.Data._taskText[Style.StyleName];
  $: HoverTooltip = Card.Data._hoverTooltip[Style.StyleName];

  $: Color = Card.Data._color[Style.StyleName];
  $: DarkColor = GanttHelper._gantHelper_darkenColor(GanttHelper._gantHelper_rgbToHex(Color), 30);
  $: HasFooter = Card.Data._resStatus !== "A";
  $: FooterColor = GanttHelper.GetStatusColor(GanttHelper.GetStatus(Card.Data._resStatus));

  $: HasStripe = Card.Data._resStatus === "B" || (Card.Data._resStatus === "T" && (Card.Data._resultQty > 0 || Card.Data?._resultProgress > 0));
  $: StripeWidth = CARD_WIDTH * (Card.Data._resStatus === "B" ? 1 : Math.max(Math.min(1, parseFloat(Card.Data._resultQty) / parseFloat(Card.Data._outputItemQty)), Card.Data?._resultProgress / 100));

  const JumpToDate = (e) => {
    e.preventDefault();
    JumpToDateCallback(Card.Data);
  }

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<g
  style="cursor:pointer;pointer-events: bounding-box;"
  on:click={JumpToDate}
>
  <title>{HoverTooltip}</title>
  <rect
    x={Card.Pos[0]}
    y={Card.Pos[1]}
    width={CARD_WIDTH}
    height={CARD_HEIGHT}
    style="fill:{Color};
      stroke:{DarkColor};
      stroke-width:0.4"
  />

  {#if HasFooter}
    <rect
      x={FooterX}
      y={FooterY}
      width={FooterWidth}
      height={FooterHeight}
      style="fill:{FooterColor}"
    />
  {/if}

  {#if HasStripe}
    <rect
      x={Card.Pos[0]}
      y={Card.Pos[1]}
      width={StripeWidth}
      height={CARD_HEIGHT}
      style="fill:url(#stripeMap)"
    />
  {/if}

  {#if Card.Highlighted}
    <rect
      x={Card.Pos[0] - 2}
      y={Card.Pos[1] - 2}
      width={CARD_WIDTH + 4}
      height={CARD_HEIGHT + 4}
      style="fill:none;
        stroke:yellow;
        stroke-width:2"
    />
  {/if}

  <foreignObject
    x={Card.Pos[0]}
    y={Card.Pos[1]}
    width={CARD_WIDTH}
    height={CARD_HEIGHT}
  >
    <div style="
      margin-top: 2px;
      margin-left: 2px;
      height:calc(100% - 2px);
      width:calc(100% - 4px);
      overflow: hidden;
    ">
      {#if !Card.Data._orderType || Card.Data._orderType === "M"}
        <!-- Production order -->
        {#if !Card.Data._procNo}
          <div style="white-space:pre-line;font-size: 8px;">{translate("frontend:ganttChart.mapDisplay.processInfoNotFound")}</div>
        {:else if Card.Data._procName !== undefined && Card.Data._procName !== ""}
          <div style="white-space:pre-line;font-size: 8px;">{Card.Data._procName}</div>
        {:else if Card.Data._procCode !== undefined && Card.Data._procCode !== ""}
          <div style="white-space:pre-line;font-size: 8px;">{Card.Data._procCode}</div>
        {:else}
          <div style="white-space:pre-line;font-size: 18px;">{Card.Data._procNo}</div>
        {/if}
      {:else if Card.Data._orderType === "E"}
        <!-- Event order -->
        <div style="white-space:pre-line;font-size: 12px;">{translate("frontend:ganttChart.mapDisplay.orderEvent")}</div>
      {:else}
        <!-- Generic order types -->
        {#if Object.keys(GENERIC_ORDERS).includes(Card.Data._orderType)}
          <div style="white-space:pre-line;font-size: 6px;">{GENERIC_ORDERS[Card.Data._orderType]}</div>
          {#if !Card.Data._orderItemCode && !Card.Data._orderItemName}
            <div style="white-space:pre-line;font-size: 8px;">{translate("frontend:ganttChart.mapDisplay.itemInfoNotFound")}</div>
          {:else if !Card.Data._orderItemName}
            <div style="white-space:pre-line;font-size: 8px;">{Card.Data._orderItemCode}</div>
          {:else}
            <div style="white-space:pre-line;font-size: 8px;">{Card.Data._orderItemName}</div>
          {/if}
        {:else}
          <div style="white-space:pre-line;font-size: 8px;">{translate("frontend:ganttChart.mapDisplay.unsupportedOrder")}</div>
        {/if}
      {/if}
    </div>
  </foreignObject>

</g>

<style>

</style>
