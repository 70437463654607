<script>
  import ChartContainer from "../../../components/chart/ChartContainer.svelte";
  import dayjs from "dayjs";
  import { initMPSChartStore } from "../MPSChartStore.js";
  import TableArea from "../../../components/chart/TableArea.svelte";
  import ChartArea from "../../../components/chart/ChartArea.svelte";
  import ChartContent from "../../../components/chart/ChartContent.svelte";
  import MPSCalendarHeader from "../MPSCalendarHeader.svelte";
  import { createRandomSeedFromString, Random } from "../../../utils/random.js";
  import { usePageContext } from "../../../components/pages/pageContext.js";
  import { useProject } from "../../../hooks/useProject.js";
  import { getContext, onMount, onDestroy } from "svelte";
  import MPSPSITableAreaItemRows from "../MPSPSITableAreaItemRows.svelte";
  import { fetchPSIItemData } from "../../../utils/getData.js";
  import PSITableChartContentRow from "./PSITableChartContentRow.svelte";
  import AccumulativeGraph from "./AccumulativeGraph.svelte";
  import { getValueWrapper } from "./utils.js";
  import GanttHelper from "../../../components/ganttchart/GanttHelper.js";
  import { derived, writable } from "svelte/store";
  import SelectedFrame from "./SelectedFrame.svelte";
  import PSITableUpperPane from "./PSITableUpperPane.svelte";
  import {aggregatePSITable} from "./aggregate.js";

  export let projectId
  export let data

  let showGraph = false;
  let showFrame = false;

  let itemClicked = null;
  let selectedElement;

  let selectedElementX;
  let selectedElementY;
  let selectedElementWidth;
  let selectedElementHeight;
  let selectedRowTitle;

  let items;
  $: console.log("items", items);
  const headerHeight = 50.39;
  let viewportWidth = writable(window.innerWidth);
  let viewportHeight = writable(window.innerHeight);
  const graphHeight = derived(
    [viewportHeight],
    ([$viewportHeight]) => {
      return $viewportHeight - headerHeight - 10;
    }
  );

  const graphWidth = derived(
    [viewportWidth],
    ([$viewportWidth]) => {
      console.log("viewportWidth", $viewportWidth);
      return $viewportWidth / 2;
    }
  );
  const {handleFetchErrors} = getContext("fetchErrorHandler")
  const myScheduleProjectData = useProject(projectId, handleFetchErrors)
  const pageContext = usePageContext()
  $: pageContext.setHeaderProps({
    title: $myScheduleProjectData ? `${$myScheduleProjectData.name} - PSI表` : `PSI表`,
  })

  const rows = Object.keys(data.Items).toSorted().map(itemCode=> {
    const item = data.Items[itemCode]
    return [
    {
      Title: "S: 受注",
      itemCode: item.itemCode,
      cellBackgroundColor: "#FFF4F8",
      cellTextColor: "#757575",
      subRowPosition: {
        index: 0,
        total: 3 + item.resources.length,
        isFirstRow: true,
        isLastRow: false,
      },
      getValue: (itemData, dateString) => getValueWrapper(() => itemData.demand[dateString], 0)
    },
    ...item.resources
      .toSorted()
      .map((resource, index) => ({
        Title: resource,
        itemCode: item.itemCode,
        subRowPosition: {
          index: 1 + index,
          total: 3 + item.resources.length,
          isFirstRow: false,
          isLastRow: false,
        },
        getValue: (itemData, dateString) => getValueWrapper(() => itemData.production[dateString][resource], 0),
      })),
    {
      Title: "I: 在庫",
      itemCode: item.itemCode,
      cellBackgroundColor: "#EAFFF0",
      cellTextColor: (_, __, cellValue) => {
        if (cellValue <= 0) return "red"
        return "#757575"
      },
      cellTextWeight: (_, __, cellValue) => {
        if (cellValue <= 0) return "bold"
        return "normal"
      },
      subRowPosition: {
        index: 1 + item.resources.length,
        total: 3 + item.resources.length,
        isFirstRow: false,
        isLastRow: false,
      },
      getValue: (itemData, dateString) => getValueWrapper(() => itemData.inventory[dateString], 0)
    },
    {
      Title: "在庫日数",
      itemCode: item.itemCode,
      cellBackgroundColor: "#EAFFF0",
      cellTextColor: "#757575",
      subRowPosition: {
        index: 2 + item.resources.length,
        total: 3 + item.resources.length,
        isFirstRow: false,
        isLastRow: true,
      },
      getValue: (itemData, dateString) => {
        const val = getValueWrapper(() => itemData.computed["divideByNextWeekAverage"][dateString], null)
        if (typeof val !== "number") return "--"
        return Math.round(val * 10) / 10
      }
    },
  ]
  }).flat()
  let DataByRowCache = null
  async function RowDataFetcher(rowData) {
    if (DataByRowCache && $DataByRowCache[rowData.itemCode]) return $DataByRowCache[rowData.itemCode]
    const data = await fetchPSIItemData(projectId, rowData.itemCode)
    const calculated = aggregatePSITable("divideByNextWeekAverage", null, data)
    data.computed = {
      "divideByNextWeekAverage": calculated
    }
    DataByRowCache.update(prev => ({...prev, [rowData.itemCode]: data}))
    return data
  }

  const itemColorsDict = new Map()
  function GetItemColor(itemCode) {
    if (itemColorsDict.has(itemCode)) return itemColorsDict.get(itemCode)
    const random = new Random(createRandomSeedFromString(itemCode))
    const h = random.nextInt(1, 360)
    const s = random.nextInt(0, 100)
    const l = random.nextInt(40, 100)
    const color = `hsl(${h}deg, ${s}%, ${l}%)`
    itemColorsDict.set(itemCode, color)
    return color
  }

  const store = initMPSChartStore({
    CurrentDisplayedStartDate: new Date(data.Dates[0]),
    DisplayedEndDate: new Date(data.Dates.at(-1)),
    DisplayedRowCount: 20,
    DisplayedSpanInSeconds: 604800 * 6,
    DisplayedStartDate: new Date(data.Dates[0]),
    GetItemColor,
    RowDataFetcher,
    Rows: rows,
    RowsDisplayedStart: 0,
    TableAreaColumnTitle: "品目",
    ValidStartDate: new Date(data.Dates[0]),
    ValidEndDate: new Date(data.Dates.at(-1)),
  })

  const {
    RowsCached,
    RowsCurrentlyDisplayed,
    DisplayedSpanInSeconds,
    CurrentDisplayedStartDate,
    ValidEndDate,
    CurrentDisplayedEndDate,
    ValidStartDate,
    RowsCountTotal,
    DisplayLoadNumbers,
  } = store
  console.log(store);
  DataByRowCache = RowsCached

  function getValueDemand(itemData, dateString) {
    return getValueWrapper(() => itemData.demand[dateString], 0);
  }
  function getValueProduction(itemData, dateString) {
    return getValueWrapper(() => {
      const productionData = itemData.production[dateString];

      // Check if productionData is an object with multiple production lines (like {2号: 120, 1号: 130})
      if (productionData && typeof productionData === 'object') {
        // Sum all the values in the productionData object
        return Object.values(productionData).reduce((sum, value) => sum + value, 0);
      }

      // Return 0 if there's no production data for the date
      return 0;
    }, 0)
  }
  function getValueInventory(itemData, dateString) {
    return getValueWrapper(() => itemData.inventory[dateString], 0);
  }

  const ScrollTime = (e) => {
    e.preventDefault();
    if (e.ctrlKey) {
      const currentDisplayedSpanInSeconds = $DisplayedSpanInSeconds
      let newDisplayedSpanInSeconds
      // Zoom
      if (e.deltaY > 0) {
        // If we want a more strict restriction use the following code. Currently, I think it's unnecessary
        // GanttStore.DisplayedSpanInSeconds.update((d) => Math.min(d * 1.05, GanttHelper.GHYEAR, (validEndDate.getTime() - validStartDate.getTime()) / 1000));
        newDisplayedSpanInSeconds = Math.min(currentDisplayedSpanInSeconds * 1.05, GanttHelper.GHYEAR)
      } else if (e.deltaY < 0) {
        newDisplayedSpanInSeconds = Math.max(currentDisplayedSpanInSeconds / 1.05, GanttHelper.GHDAY)
      }
      DisplayedSpanInSeconds.set(newDisplayedSpanInSeconds)
    }
    else {
      // Move
      let newCurrentDisplayedStartDate
      if (e.deltaY > 0) {
        if (isNaN($ValidEndDate.getTime())) {
          newCurrentDisplayedStartDate = new Date($CurrentDisplayedStartDate.getTime() + ($CurrentDisplayedEndDate.getTime() - $CurrentDisplayedStartDate.getTime()) / 48);
        } else {
          newCurrentDisplayedStartDate = new Date(Math.min($ValidEndDate.getTime() - $DisplayedSpanInSeconds * 1000, $CurrentDisplayedStartDate.getTime() + 1000 * 60 * 60 * 24));
        }
        if (newCurrentDisplayedStartDate < $CurrentDisplayedStartDate) {
          newCurrentDisplayedStartDate = $CurrentDisplayedStartDate;
        }
      }
      else if (e.deltaY < 0) {
        if (isNaN($ValidStartDate.getTime())) {
          newCurrentDisplayedStartDate = new Date($CurrentDisplayedStartDate.getTime() - ($CurrentDisplayedEndDate.getTime() - $CurrentDisplayedStartDate.getTime()) / 48);
        } else {
          newCurrentDisplayedStartDate = new Date(Math.max($ValidStartDate.getTime(), $CurrentDisplayedStartDate.getTime() - 1000 * 60 * 60 * 24))
        }
        if (newCurrentDisplayedStartDate > $CurrentDisplayedStartDate) {
          newCurrentDisplayedStartDate = $CurrentDisplayedStartDate;
        }
      }
      CurrentDisplayedStartDate.set(newCurrentDisplayedStartDate)
    }
  }

  function handleRowClick(e, title) {
    console.log("Row clicked:", title);
    if (itemClicked !== title) {
      itemClicked = title;  // Explicitly reassigning triggers Svelte reactivity
    } 
    console.log("itemClicked:", itemClicked);

    showGraph = true; 
    const element = e;
    console.log("Element:", e);
    selectedElement = element.currentTarget;
    selectedRowTitle = title;

    // Set initial values for position and size
    updateElementCoordinates();
    showFrame = true;
  }

  let observer;
  function updateElementCoordinates() {
    if (selectedElement) {
      const rect = selectedElement.getBoundingClientRect();
      selectedElementX = rect.x;
      selectedElementY = rect.y;
      selectedElementWidth = rect.width;
      selectedElementHeight = rect.height;
      /* console.log(`X: ${selectedElementX}, Y: ${selectedElementY}, Width: ${selectedElementWidth}, Height: ${selectedElementHeight}`); */
    }
  }

  $: setupObserver(), items;
  function setupObserver() {
    if (observer) {
      observer.disconnect();
    }
    const items = document.querySelectorAll("[row-title]");
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log("Observed entry:", entry.target.getAttribute('row-title'), entry.isIntersecting);
        if (entry.isIntersecting && selectedRowTitle && entry.target.getAttribute('row-title') === selectedRowTitle) {
          selectedElement = entry.target;
          updateElementCoordinates();
          console.log("Reassigned selected element as it reappeared.");
        }
      });
    });

    items.forEach(item => observer.observe(item));
}

  function handleOuterScroll(e) {
    e.preventDefault();
    console.log("Outer scroll", e);
    requestAnimationFrame(() => {
        updateElementCoordinates();
    });
  }

  function handleResize() {
    viewportWidth.set(window.innerWidth);
    viewportHeight.set(window.innerHeight);
  }

  onMount(() => {
    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Set up IntersectionObserver
    setupObserver();

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
      if (observer) observer.disconnect();
    };
  });


</script>

<div class="root">
  <div class="main-psi-table">
    <PSITableUpperPane store={store} />
    <ChartContainer store={store}>
      <svelte:fragment slot="table-area">
        <div on:wheel={handleOuterScroll}>
          <TableArea store={store}>
            <MPSPSITableAreaItemRows slot="rows" 
              store={store} 
              onClick={handleRowClick}
              bind:items={items}
            />
          </TableArea>
        </div>
        <div on:wheel={handleOuterScroll}>
          <TableArea
            headerTitleString="組付ライン"
            store={store}
            shouldDisplayControls={false}
          />
        </div>
      </svelte:fragment>
      <ChartArea store={store} slot="chart-area">
        <MPSCalendarHeader store={store} ScrollTime={ScrollTime} />
        <ChartContent store={store} let:props>
          <PSITableChartContentRow
            slot="chart-content-row"
            store={store}
            props={{
              ...props,
              dateStrings: data.Dates,
              dates: data.Dates.map(dateString => dayjs(dateString).toDate()),
            }}
          />
        </ChartContent>
      </ChartArea>
    </ChartContainer>
    {#if showFrame}
      <div class="row-boundary-overlay">
        <SelectedFrame 
          selectedElementX={selectedElementX}
          selectedElementY={selectedElementY}
          selectedElementHeight={selectedElementHeight}
          graphWidth={$graphWidth}
        />
      </div>
    {/if}
  </div>
  {#if showGraph}
    <div class="graph-part" on:wheel={ScrollTime}>
      <AccumulativeGraph
        store={store}
        itemClicked={itemClicked}
        getValueDemand={getValueDemand}
        getValueProduction={getValueProduction}
        getValueInventory={getValueInventory}
        graphHeight={$graphHeight}
        graphWidth={$graphWidth}
        closeGraph={() => {showGraph = false; showFrame = false;}}
      />
    </div>
  {/if}
</div>

<style>
  .root {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .main-psi-table {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .main-psi-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
  }

  .graph-part {
    position: relative;
    flex: 1;
    height: 100%;
  }

  .row-boundary-overlay {
    position: absolute;  /* Ensure it can be stacked using z-index */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;  /* High z-index to place it on top */
    pointer-events: none; /* Ensure it doesn't block interactions with the chart */
  }
</style>