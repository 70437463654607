<script>
  import ChartContainer from "../../../components/chart/ChartContainer.svelte";
  import UpperPane from "../../../components/chart/UpperPane.svelte";
  import dayjs from "dayjs";
  import { initMPSChartStore } from "../MPSChartStore.js";
  import TableArea from "../../../components/chart/TableArea.svelte";
  import ChartArea from "../../../components/chart/ChartArea.svelte";
  import ChartContent from "../../../components/chart/ChartContent.svelte";
  import MPSCalendarHeader from "../MPSCalendarHeader.svelte";
  import { createRandomSeedFromString, Random } from "../../../utils/random.js";
  import { usePageContext } from "../../../components/pages/pageContext.js";
  import { useProject } from "../../../hooks/useProject.js";
  import { getContext } from "svelte";
  import MPSPSITableAreaItemRows from "../MPSPSITableAreaItemRows.svelte";
  import { fetchPSIItemData } from "../../../utils/getData.js";
  import { getValueWrapper } from "./utils.js";
  import PSIGraphMPSChartContentRow from "./PSIGraphMPSChartContentRow.svelte";
  export let projectId
  export let data

  const {handleFetchErrors} = getContext("fetchErrorHandler")
  const myScheduleProjectData = useProject(projectId, handleFetchErrors)
  const pageContext = usePageContext()
  $: pageContext.setHeaderProps({
    title: $myScheduleProjectData ? `${$myScheduleProjectData.name} - PSIグラフ` : `PSIグラフ`,
  })

  const rows = Object.keys(data.Items).toSorted().map(itemCode=> {
    const item = data.Items[itemCode]
    return [
    {
      Title: "S: 受注",
      itemCode: item.itemCode,
      cellBackgroundColor: "#FFAEC9",
      cellTextColor: "black",
      lineColor: "#FF4080",
      subRowPosition: {
        index: 0,
        total: 3,
        isFirstRow: true,
        isLastRow: false,
      },
      getValue: (itemData, dateString) => getValueWrapper(() => itemData.demand[dateString], 0)
    },
    {
      Title: "P: 生産",
      itemCode: item.itemCode,
      cellBackgroundColor: "#C4C4FF",
      cellTextColor: "black",
      lineColor: "#7171FF",
      subRowPosition: {
        index: 1,
        total: 3,
        isFirstRow: false,
        isLastRow: false,
      },
      getValue: (itemData, dateString) => getValueWrapper(() => {
        const productionData = itemData.production[dateString];

        // Check if productionData is an object with multiple production lines (like {2号: 120, 1号: 130})
        if (productionData && typeof productionData === 'object') {
          // Sum all the values in the productionData object
          return Object.values(productionData).reduce((sum, value) => sum + value, 0);
        }

        // Return 0 if there's no production data for the date
        return 0;
      }, 0)
    },
    {
      Title: "I: 在庫",
      itemCode: item.itemCode,
      cellBackgroundColor: "#ACE5D7",
      cellTextColor: (_, __, cellValue) => {
        if (cellValue <= 0) return "red"
        return "black"
      },
      cellTextWeight: (_, __, cellValue) => {
        if (cellValue <= 0) return "bold"
        return "normal"
      },
      lineColor: "#07C093",
      subRowPosition: {
        index: 2,
        total: 3,
        isFirstRow: false,
        isLastRow: true,
      },
      getValue: (itemData, dateString) => getValueWrapper(() => itemData.inventory[dateString], 0)
    },
  ]
  }).flat()
  let DataByRowCache = null
  async function RowDataFetcher(rowData) {
    if (DataByRowCache && $DataByRowCache[rowData.itemCode]) return $DataByRowCache[rowData.itemCode]
    const data = await fetchPSIItemData(projectId, rowData.itemCode)
    DataByRowCache.update(prev => ({...prev, [rowData.itemCode]: data}))
    return data
  }

  const itemColorsDict = new Map()
  function GetItemColor(itemCode) {
    if (itemColorsDict.has(itemCode)) return itemColorsDict.get(itemCode)
    const random = new Random(createRandomSeedFromString(itemCode))
    const h = random.nextInt(1, 360)
    const s = random.nextInt(0, 100)
    const l = random.nextInt(40, 100)
    const color = `hsl(${h}deg, ${s}%, ${l}%)`
    itemColorsDict.set(itemCode, color)
    return color
  }

  const store = initMPSChartStore({
    CurrentDisplayedStartDate: new Date(data.Dates[0]),
    DisplayedEndDate: new Date(data.Dates.at(-1)),
    DisplayedRowCount: 9,
    DisplayedSpanInSeconds: 604800 * 6,
    DisplayedStartDate: new Date(data.Dates[0]),
    GetItemColor,
    RowDataFetcher,
    Rows: rows,
    RowsDisplayedStart: 0,
    TableAreaColumnTitle: "品目",
    ValidStartDate: new Date(data.Dates[0]),
    ValidEndDate: new Date(data.Dates.at(-1)),
  })

  const {
    DisplayLoadNumbers,
    RowsCached,
  } = store

  DataByRowCache = RowsCached

</script>

<div class="root">
  <UpperPane
    store={store}
  >
  </UpperPane>
  <ChartContainer store={store}>
    <svelte:fragment slot="table-area">
      <TableArea store={store}>
        <MPSPSITableAreaItemRows slot="rows" store={store} />
      </TableArea>
      <TableArea
        headerTitleString="PSI"
        store={store}
        shouldDisplayControls={false}
      />
    </svelte:fragment>
    <ChartArea store={store} slot="chart-area">
      <MPSCalendarHeader store={store}/>
      <ChartContent store={store} let:props>
        <PSIGraphMPSChartContentRow
          slot="chart-content-row"
          store={store} 
          props={{
            ...props,
            dateStrings: data.Dates,
            dates: data.Dates.map(dateString => dayjs(dateString).toDate()),
        }} 
      />
      </ChartContent>
    </ChartArea>
  </ChartContainer>
</div>

<style>
  .root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
</style>