<script>
  // props
  import { getContext } from "svelte";
  import Loading from "../../../Loading.svelte";
  import { usePSIGenericDataQuery } from "../../../../../views/MPS/PSITable/usePSIGenericDataQuery.js";

  /**
   * @type {string}
   */
  export let projectId

  // error handlers
  const {handleFetchErrors} = getContext("fetchErrorHandler")

  // svelte-query
  // TODO: Write PSI Table data retrieval hooks (using LoadGraph data for now)
  const genericDataQuery = usePSIGenericDataQuery(projectId, handleFetchErrors)
</script>

{#if $genericDataQuery.data}
  <slot
    projectId={projectId}
    data={$genericDataQuery.data}
  />
{:else}
  <Loading />
{/if}