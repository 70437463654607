<script>
  import {useParams} from "svelte-navigator"
  import {getContext, onMount} from "svelte"
  import {FetchError, fetchResultData, fetchTransactionData} from "../../utils/getData.js"
  import Loading from "../../components/pages/Loading.svelte";
  import { usePageContext } from "../../components/pages/pageContext.js";

  const params = useParams();
  const projectId = $params.project;

  $: resultData = null;
  $: itemCodes = [];
  $: itemFlags = {};
  $: selectedDates = { begin: null, end: null };

  // Initialize result data
  function init() {
    resultData.Groups.forEach((groupData) => {
      groupData.Works.forEach((workData) => {
        const itemName = workData.Work_OperationOutMainItem;
        if (!itemCodes.includes(itemName)) {
          itemCodes.push(itemName);
          itemFlags[itemName] = true;
        }
      });
    });
    itemFlags[""] = true;
  }

  async function applyTransactions(transactions) {
    resultData.Groups.forEach((group)=>{
      group.Works.forEach((work, idx)=>{
        if (work.Work_Code in transactions) {
          group.Works[idx].Work_ResultEndTime = transactions[work.Work_Code].Work_ResultEndTime;
        }
      })
    })
  }

  const {handleFetchErrors} = getContext("fetchErrorHandler")
  onMount(async () => {
    try {
      const data = await fetchResultData(projectId)
      resultData = data
      const transactions = await fetchTransactionData(projectId, resultData.DownloadTimeStamp)
      await applyTransactions(transactions)
      init()
    } catch (e) {
      if (e instanceof FetchError) {
        handleFetchErrors(e.error)
      }
    }
  })

  const { setHeaderProps } = usePageContext();
  $: setHeaderProps({ title: "ダッシュボード" });
  // let uploadTimeStamp = undefined;
  // $: setHeaderProps({ uploadTime: uploadTimeStamp});

  // Get checkbox style depending on the selection flag
  $: getCheckboxStyle = (code) => {
    let res =
      "border-color: " + (itemFlags[code] ? "dodgerblue" : "#cdcdcd") + ";";
    if (itemFlags[code]) res += " background-color: dodgerblue;";
    return res;
  };

  // Toggle checkbox
  $: toggleCheckbox = (code) => {
    if (code === "") {
      itemCodes.forEach((el) => {
        if (itemFlags[el] === itemFlags[code]) itemFlags[el] = !itemFlags[el];
      });
    }
    itemFlags[code] = !itemFlags[code];
    updateAllCheckbox();
  };

  // Update all checkbox
  $: updateAllCheckbox = () => {
    let allFlag = true;
    itemCodes.forEach((code) => {
      if (!itemFlags[code]) allFlag = false;
    });
    itemFlags[""] = allFlag;
  };

  // Filter result data
  $: getDisplayData = () => {
    let displayData = [];
    resultData.Groups.forEach((groupData) => {
      let group = {
        GroupName: groupData.GroupName,
        actualNum: 0,
        expectedNum: 0,
      };
      groupData.Works.forEach((workData) => {
        const itemName = workData.Work_OperationOutMainItem;
        if (itemFlags[itemName]) {
          let beginDate = null;
          let endDate = null;

          if (selectedDates.begin ) {
            beginDate = new Date(selectedDates.begin);
          }
          if (selectedDates.end) {
            endDate = new Date(selectedDates.end);
          }
          const expectedDate = new Date(
              workData.WebViewerOnly_ScheduledEndTime
          );
          if ((!beginDate || expectedDate >= beginDate) && (!endDate || expectedDate <= endDate)) {
            group.expectedNum++;
            if (workData.Work_ResultEndTime != "") {
              group.actualNum++;
            }
            if (beginDate <= expectedDate && expectedDate <= endDate) {
              group.expectedNum++;
            }
          }
        }
      });
      displayData.push(group);
    });
    return displayData;
  };
</script>

{#if resultData}
  <div class="wrapper">
    <div class="params">
      <div>
        <p class="title">指定期間</p>
        <input type="date" class="date" bind:value={selectedDates.begin} />
        <div class="connect-line">|</div>
        <input type="date" class="date" bind:value={selectedDates.end} />
      </div>
      <div>
        <p class="title">機種</p>
        <div class="item">
          <button
            class="checkbox"
            style={getCheckboxStyle("")}
            on:click={() => toggleCheckbox("")}
          >
            {#if itemFlags[""]}
              ✔
            {/if}
          </button>
          <div class="name">すべて</div>
        </div>
        <div class="others">
          {#each itemCodes as code}
            <div class="item">
              <button
                class="checkbox"
                style={getCheckboxStyle(code)}
                on:click={() => toggleCheckbox(code)}
              >
                {#if itemFlags[code]}
                  ✔
                {/if}
              </button>
              <div class="name">{code}</div>
            </div>
          {/each}
        </div>
      </div>
    </div>
    <div class="content">
      <table>
        <tr>
          <th />
          <th>実績</th>
          <th>計画</th>
        </tr>
        {#each getDisplayData() as group}
          <tr>
            <td>{group.GroupName}</td>
            <td class="number">{group.actualNum}</td>
            <td class="number">{group.expectedNum}</td>
          </tr>
        {/each}
      </table>
    </div>
  </div>
{:else}
  <Loading/>
{/if}

<style type="text/css">
  @font-face {
    font-family: "BIZ UDGothic";

    src: local("../../fonts/BIZUDGothic-Regular.ttf");
  }
  @font-face {
    font-family: "BIZ UDPGothic";

    src: local("../../fonts/BIZUDPGothic-Regular.ttf");
  }

  .wrapper {
    display: flex;
    width: calc(100% - 50px);
    padding: 25px;
    color: #271c19;
    font-family: "BIZ UDPGothic", sans-serif;
  }

  .params {
    width: max-content;
    min-width: 10em;
    margin-right: 1em;
  }

  .params > div {
    margin-top: 1em;

    border-top: 1px solid #cdcdcd;
  }

  .params > div:first-child {
    margin-top: 0;

    border-top: none;
  }

  .params .title {
    font-weight: bold;
  }

  .date {
    padding: 0.3em 0.5em;
    border: 2px solid #cdcdcd;
    border-radius: 5px;
    font-size: 0.9em;
    font-family: "BIZ UDPGothic", sans-serif;
  }

  .connect-line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3em;
    font-size: 0.7em;
  }

  .item {
    display: flex;
    align-items: center;
    margin: 1em 0 1em 1em;
    font-family: "BIZ UDGothic", sans-serif;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 0.5em;
    border-width: 2px;
    border-style: solid;
    color: white;
    cursor: pointer;
    transition: 0.3s;
  }

  .checkbox {
    border-radius: 5px;
  }

  .others {
    width: 100%;
    max-height: calc(100vh - 440px);
    overflow-y: scroll;

    border-top: 2px dotted #cdcdcd;
  }

  .content {
    flex-grow: 1;
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    border: 3px solid #cdcdcd;
    border-radius: 7px;
    background-color: white;
    font-family: "BIZ UDGothic", sans-serif;
  }

  .content table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
  }

  .content tr {
    display: flex;
    height: 3em;
    font-size: 1.2em;
  }

  .content tr:first-child {
    border-bottom: 2px solid #cdcdcd;
  }

  .content tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  .content td,
  .content th {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    background-image: linear-gradient(
      to bottom,
      #cdcdcd,
      #cdcdcd 3px,
      transparent 3px,
      transparent 8px
    );
    background-position: right top;
    background-size: 2px 8px;
    background-repeat: repeat-y;
  }

  .number {
    font-weight: 700;
  }
</style>
