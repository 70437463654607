<script>

export let Connection;

$: Width = 2;

</script>

<line
  x1={Connection.X[0]}
  y1={Connection.Y[0]}
  x2={Connection.X[1]}
  y2={Connection.Y[1]}
  stroke={Connection.Color}
  stroke-width={Width}
  stroke-linecap="round"
  stroke-dasharray={(Connection.SubConnection) ? "2 6" : null}
  marker-end={Connection.Arrow === true ? `url(#map_link_${Connection.Color})` : ""}
/>

<style>


</style>
