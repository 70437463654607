<script>
  import TableAreaRow from "./TableAreaRow.svelte";

  export let store
  /**
   * @type {string}
   */
  export let headerTitleString = ""
  /**
   * Should the top and bottom navigation buttons be displayed?
   * @type {boolean}
   */
  export let shouldDisplayControls = true;
  /**
   * Function that returns the title to be displayed in each row
   * @type {(row: any) => string}
   */
  export let getTitle = (row) => row.Title ?? ""
  /**
   * Function that returns the color of the row
   * @type {(row: any) => string}
   */
  export let getColor = (any) => "#E9E9F0"

  const {
    DisplayedRowCount,
    RowsCountTotal,
    RowsCurrentlyDisplayed,
    RowsDisplayedStart,
    TableAreaColumnTitle,
  } = store

  const PageScroll = (pj) => {
    RowsDisplayedStart.update((n) => Math.max(0, Math.min($RowsCountTotal - 1, n + pj * $DisplayedRowCount))
    );
  };

  const PageUp = (e) => {
    e.preventDefault();
    PageScroll(-1);
  };

  const PageDown = (e) => {
    e.preventDefault();
   PageScroll(1);
  };

  const RowUp = (e) => {
    e.preventDefault();
    RowsDisplayedStart.update((n) => (n > 0 ? n - 1 : n));
  };

  const RowDown = (e) => {
    e.preventDefault();
    RowsDisplayedStart.update((n) =>
      n + 1 >= $RowsCountTotal ? n : n + 1
    );
  };

  const ScrollRows = (e) => {
    e.preventDefault();

    if (e.ctrlKey) {
      // Row nb scroll
      if (e.deltaY > 0)
        DisplayedRowCount.update((n) => (n + 1 >= 100 ? n : n + 1));
      else if (e.deltaY < 0)
        DisplayedRowCount.update((n) => (n > 1 ? n - 1 : n));
    } else {
      // Row idx scroll
      if (e.deltaY > 0) RowDown(e);
      else if (e.deltaY < 0) RowUp(e);
    }
  };
</script>

<div id="tableArea" style="--table-width: 100%" on:wheel={ScrollRows}>
  <div id="tableArea_headColumns">
    <span id="tableArea_header">{headerTitleString || $TableAreaColumnTitle}</span>
    {#if shouldDisplayControls}
      <div id="tableArea_headButtons">
        <button class="tableArea_headButton" on:click={RowUp}>
          <img src="/assets/rss_up.svg" alt="row up icon" />
        </button>
        <button class="tableArea_headButton" on:click={PageUp}>
          <img src="/assets/rss_pageup.svg" alt="page up icon" />
        </button>
      </div>
    {/if}
  </div>

  <div id="tableArea_bottomDiv">
    <svg id="tableArea_resourceSvg">
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1="30"
          y1="0"
          y2="0"
          id="truncateText"
        >
          <stop offset="90%" stop-opacity="1" />
          <stop offset="100%" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="truncateRowText"
          gradientTransform="translate(0)"
          xlink:href="#truncateText"
        />
      </defs>
      {#if $$slots.rows}
        <slot name="rows" />
      {:else}
        {#each $RowsCurrentlyDisplayed as row, index}
          <TableAreaRow
            Row={getTitle(row)}
            Color={getColor(row)}
            IsNull={row === null}
            Height={$DisplayedRowCount > 0 ? 100 / $DisplayedRowCount : 100}
            OriginY={($DisplayedRowCount > 0 ? 100 / $DisplayedRowCount : 100) * index}
          />
        {/each}
      {/if}
    </svg>
  </div>

  <div id="tableArea_footerButtons">
    {#if shouldDisplayControls}
      <button class="tableArea_footerButton" on:click={RowDown}>
        <img src="/assets/rss_down.svg" alt="row down icon" />
      </button>
      <button class="tableArea_footerButton" on:click={PageDown}>
        <img src="/assets/rss_pagedown.svg" alt="page down icon" />
      </button>
    {/if}
  </div>
</div>

<style>
  button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  div#tableArea_footerButtons {
    width: 100%;
    height: 40px;
    gap: 4%;
    line-height: 32px;
    color: white;
    background-color: white;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    text-align: center;
    border-top: 2px solid #888888;
  }

  button.tableArea_footerButton {
    background-color: #d2d0d2;
    width: 48%;
    height: 80%;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.tableArea_footerButton:hover {
    background-color: #e2e0e2;
    cursor: pointer;
  }

  button.tableArea_headButton {
    background-color: #d2d0d2;
    width: 48%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.tableArea_headButton:hover {
    background-color: #e2e0e2;
    cursor: pointer;
  }

  div#tableArea_headButtons {
    background-color: white;
    height: 40%;
    width: 100%;
    gap: 4%;
    line-height: 32px;
    color: white;
    display: flex;
    flex-direction: row;
  }

  span#tableArea_header {
    height: 60%;
    line-height: 48px;
  }

  div#tableArea {
    height: 100%;
    width: var(--table-width);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 2px solid #888888;
    background-color: white;
  }

  div#tableArea_headColumns {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    border-bottom: 2px solid #888888;
  }

  div#tableArea_bottomDiv {
    flex: 1;
    background-color: white;
  }

  svg#tableArea_resourceSvg {
    float: left;
    border-right: 2px solid #888888;
    width: 100%;
    height: 100%;
  }
</style>
