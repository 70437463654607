<script>
  import { onMount } from "svelte";
  import { writable } from "svelte/store";

  export let store
  export let props

  $: ({
    Row,
    RowHeightInPx,
    OriginYInPx,
  } = props)


  const {
    CurrentDisplayedDates,
    RowDataFetcher,
  } = store

  const RowData = writable(null)
  async function fetchRowData(Row) {
    if (!Row) return
    RowData.set(await $RowDataFetcher.apply(null, [Row]))
  }
  $: fetchRowData(Row)

  function getStylingValue(getter, defaultValue, getterArgs) {
    if (!getter) return defaultValue
    if (typeof getter === "string") return getter
    return getter(...getterArgs)
  }

  onMount(async () => {
    await fetchRowData()
  })

</script>

{#each $CurrentDisplayedDates as date}
  <rect
    x="{date.startXInPercentage}%"
    y="{OriginYInPx}px"
    height="{RowHeightInPx}px"
    width="{Math.max(date.endXInPercentage - date.startXInPercentage, 0)}%"
    fill={getStylingValue(Row.cellBackgroundColor, "white", [$RowData, date.dateString, Row.getValue($RowData, date.dateString)])}
  />
  {#if date.startXInPercentage < 100}
    <text
      x="{(date.startXInPercentage + date.endXInPercentage) / 2}%"
      y="{OriginYInPx + RowHeightInPx / 2}px"
      font-size="16px"
      font-weight={getStylingValue(Row.cellTextWeight, "normal", [$RowData, date.dateString, Row.getValue($RowData, date.dateString)])}
      text-anchor="middle"
      dominant-baseline="middle"
      fill={getStylingValue(Row.cellTextColor, "black", [$RowData, date.dateString, Row.getValue($RowData, date.dateString)])}
    >
      {Row.getValue($RowData, date.dateString)}
    </text>
  {/if}
  <line
    x1="{date.endXInPercentage}%"
    x2="{date.endXInPercentage}%"
    y1="{OriginYInPx}px"
    y2="{OriginYInPx + RowHeightInPx}px"
    stroke={date.date.getDay() === 0 ? "#888888" : "#C8C6C6"}
    stroke-width="2"
  />
{/each}
<!-- Line at the bottom of the row -->
<line
  x1="0"
  x2="100%"
  y1="{OriginYInPx + RowHeightInPx}px"
  y2="{OriginYInPx + RowHeightInPx}px"
  stroke={Row.subRowPosition.isLastRow ? "#888888" : "#C8C6C6"}
  stroke-width="2"
/>

<style>
  .overload {
    fill: red;
  }
</style>